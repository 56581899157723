import {
  ActionReducerMapBuilder,
  AsyncThunk,
  Reducer,
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import company from 'lib/api/company';
import watchlist from 'lib/api/watchlist';

import { thunks as tableThunks } from '../Table/slice';

// global types
import { Company } from '../../../../../@types/company';
// utils
import rootSelector from '../../selectors';

// local types
import { CompanySuggestionsState } from '../types';

// constants
const SLICE_NAME = 'table';

// initial state
const initialState: CompanySuggestionsState = {
  error: null,
  isLoading: false,
  suggestions: [],
};

// Reducer
type SliceReducers = {
  clearSearch: Reducer<CompanySuggestionsState>;
};

const reducers: SliceReducers = {
  clearSearch: (state) => {
    state.suggestions = [];

    return state;
  },
};

// thunk actions
type Thunks = {
  // auto suggestions
  fetchCompanySuggestions: AsyncThunk<
    { data: Array<Company> }, // return type
    { keyword: string, page: number, watchlist_id: number }, // payload
    {} // thunk api type
  >;
  handleSuggestionsSelected: AsyncThunk<
    { data?: any },
    { companyid: number; watchlistid: string },
    {}
  >;
};

export const thunks: Thunks = {
  fetchCompanySuggestions: createAsyncThunk(
    `${SLICE_NAME}/FETCH_COMPANY_SUGGESTIONS`,
    async ({ ...params }) => {
      const response = await company.getSearchCompany(params);

      if (!response.data) {
        throw new Error('Attempt to fetch suggestion company failed');
      }

      const { data, error } = response.data;

      if (error) {
        throw new Error(error);
      }

      return { data: data?.companies };
    },
  ),
  handleSuggestionsSelected: createAsyncThunk(
    `${SLICE_NAME}/ADD_SUGGESTIONS_TO_WATCHLIST`,
    async ({ companyid, watchlistid }, { dispatch }) => {
      const response = await watchlist.postFollowCompany(
        watchlistid,
        companyid,
      );

      if (!response.data) {
        throw new Error('Attempt to follow company failed');
      }

      const { error_type: error } = response.data;

      if (error) {
        throw new Error(error);
      }
      dispatch(tableThunks.fetchCompanyWatchlist({ watchlistid }));

      return {
        data: response.data.data,
      };
    },
  ),
};

// Thunk reducers
const extraReducers = (
  builder: ActionReducerMapBuilder<CompanySuggestionsState>,
) => {
  builder
    // handle save to company
    .addCase(thunks.handleSuggestionsSelected.pending, (draft) => {
      draft.isLoading = true;
    })
    .addCase(thunks.handleSuggestionsSelected.fulfilled, (draft) => {
      draft.isLoading = false;
    })
    .addCase(thunks.handleSuggestionsSelected.rejected, (draft, action) => {
      draft.isLoading = false;
      draft.error = action.error;
    })

    // Extra reducers for fetch suggestions
    .addCase(thunks.fetchCompanySuggestions.pending, (draft) => {
      draft.isLoading = true;
    })
    .addCase(thunks.fetchCompanySuggestions.fulfilled, (draft, action) => {
      draft.isLoading = false;
      draft.suggestions = action.payload.data;
    })
    .addCase(thunks.fetchCompanySuggestions.rejected, (draft, action) => {
      draft.isLoading = false;
      draft.error = action.error;
    });
};

// selectors
export const selectors = createSelector(rootSelector, (state) => ({
  ...state.watchlistTable.companySuggestions,
  activeWatchlistid: state.root.activeWatchlistId,
}));

export const suggestionSelector = createSelector(
  (state) => state.watchlist.watchlistTable.companySuggestions.suggestions,
  (state) => state,
);

const companySuggestionsSlice = createSlice({
  initialState,
  name: SLICE_NAME,
  reducers,
  extraReducers,
});

export default companySuggestionsSlice;
