export const RECONNECT_INTERVAL = 30000; // 30 seconds
export const HEARTBEAT_INTERVAL = 15000; // 15 seconds
export const HEARTBEAT_FACTOR = 1.5; // 1.5 times the heartbeat interval
export const MAX_RECONNECT_ATTEMPTS = 3;
export const MAX_POLLING_ATTEMPTS = 50;
export const UPTIME = 2000; // 2 seconds

export const SecuritiesMessageCase = {
  SUMMARY: 'portfolioSummary',
  PORTFOLIO_LIST: 'portfolioList',
  PORTFOLIO_DETAIL: 'portfolioDetail',
  PORTFOLIO_DETAIL_DAY_TRADE: 'portfolioDetailDayTrade',
  ORDER_LIST: 'orderList',
  ORDER_DETAIL: 'orderDetail',
};

export const DATA_ACTION = {
  UPSERT: 1,
  DELETE: 2,
};

export enum ERROR_CODE {
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  INTERNAL_SERVER_ERROR = 500,
}

export enum ReadyState {
  UNINSTANTIATED = -1,
  CONNECTING = 0,
  OPEN = 1,
  CLOSING = 2,
  CLOSED = 3,
}

export enum NetworkStatus {
  ONLINE = 'online',
  OFFLINE = 'offline',
}
