/* eslint-disable consistent-return */
import { HYDRATE } from 'next-redux-wrapper';
import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from '@reduxjs/toolkit';

// api
import streamApi from 'lib/api/stream';
import securitiesAPI from 'lib/api/securities';

// initial state
const initialState = {
  stream: {
    list: [],
    isLoading: false,
    error: null,
    message: '',
  },
  trendingUser: {
    list: [],
    isLoading: false,
    error: null,
    message: '',
  },
  registrationStatus: {
    data: {
      status: 0,
    },
    list: [],
    isLoading: false,
    error: null,
    message: '',
  },
};

// Selectors -------------------------------------------------------------------
export const selectors = createSelector(
  (state) => state.stream,
  (state) => ({
    stream: {
      list: state.stream.list,
      isLoading: state.stream.isLoading,
      error: state.stream.error,
      message: state.stream.message,
    },
    trendingUser: {
      list: state.trendingUser.list,
      isLoading: state.trendingUser.isLoading,
      error: state.trendingUser.error,
      message: state.trendingUser.message,
    },
    registrationStatus: {
      data: state.registrationStatus.data,
      isLoading: state.registrationStatus.isLoading,
      error: state.registrationStatus.error,
      message: state.registrationStatus.message,
    },
  }),
);

// Actions ---------------------------------------------------------------------
// types
const CONTEXT = '@features/stream';

const actionType = {
  GET_STREAM: `${CONTEXT}/stream/get`,
  GET_REGISTRATION_STATUS: `${CONTEXT}/registration_status/get`,
};

// effects
export const effects = {
  getStream: createAsyncThunk(actionType.GET_STREAM, async () => {
    try {
      const response = await streamApi.getStream(2, {
        category: 'all',
        clean: 1,
      });
      if (!response.data) {
        throw new Error('Attempt to fetch stream failed');
      }

      const { data, message, error } = response.data;

      if (error) {
        return { message, error };
      }

      return { data, message };
    } catch (error) {
      return { error: error.message };
    }
  }),

  // Left as it was because it should be checked after OA is fully integrated with stream page
  // But the widget itself has migrated to zustand
  getRegistrationStatus: createAsyncThunk(
    actionType.GET_REGISTRATION_STATUS,
    async () => {
      try {
        const response = await securitiesAPI.getOAStatus();
        if (!response.data) {
          throw new Error('Attempt to fetch oa status failed');
        }

        const { data, message, error } = response.data;

        if (error) {
          return { message, error };
        }

        return { data, message };
      } catch (error) {
        return { error };
      }
    },
  ),
};

// Extra Reducer ---------------------------------------------------------------
const extraReducers = {
  [HYDRATE]: (draft, action) => {
    draft.ihsg = action.payload.ihsg;
    draft.trendingStocks = action.payload.trendingStocks;
    draft.stream = action.payload.stream;
    draft.trendingUser = action.payload.trendingUser;
  },

  // Get Stream ----------------------------------------------------------------
  [effects.getStream.pending]: (state) => {
    state.stream.isLoading = true;
  },

  [effects.getStream.fulfilled]: (state, action) => {
    const { data, message, error } = action.payload;
    if (error) {
      state.stream.error = error;
    } else {
      state.stream.list = data;
    }
    state.stream.message = message;
    state.stream.isLoading = false;
  },

  [effects.getStream.rejected]: (state, action) => {
    state.stream.error = action.payload.error;
    state.stream.isLoading = false;
  },

  // Get get trending user -----------------------------------------------------
  [effects.getRegistrationStatus.pending]: (state) => {
    state.registrationStatus.isLoading = true;
  },

  [effects.getRegistrationStatus.fulfilled]: (state, action) => {
    const { data, message, error } = action.payload;
    if (error) {
      state.registrationStatus.error = error;
    } else {
      state.registrationStatus.data = data;
    }
    state.registrationStatus.message = message;
    state.registrationStatus.isLoading = false;
  },

  [effects.getRegistrationStatus.rejected]: (state, action) => {
    state.registrationStatus.error = action.payload.error;
    state.registrationStatus.isLoading = false;
  },
};

// Slice -----------------------------------------------------------------------
const streamSlice = createSlice({
  name: 'stream',
  initialState,
  extraReducers,
});

export default streamSlice;
