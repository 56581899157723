import { combineReducers } from 'redux';

import listWatchlistSlice from './ListWatchlist/slice';
import companyWatchlistSlice from './CompanyWatchlist/slice';
import orderbookSlice from './Orderbook/slice';

import rootSlice from './slice';

const sideWidgetReducer = combineReducers({
  orderbook: orderbookSlice.reducer,
  listWatchlist: listWatchlistSlice.reducer,
  company: companyWatchlistSlice.reducer,
  root: rootSlice.reducer,
});

export default sideWidgetReducer;
