/* eslint-disable camelcase */

/** @typedef {import('../../../@types/api-response').APIResponse} APIResponse */

import exodusInstance from 'lib/api/instances/exodus';

import { API_EXODUS_URL } from 'constants/api';

const requestExodus = exodusInstance.defaultInstance;
const requestExodusNoAlert = exodusInstance.noAlert;

// URLs aliases
const TIPPING_EXODUS_URL = `${API_EXODUS_URL}/tip`;

// EXODUS TIPPING URL
const EXODUS_TIPPING_URL = `${API_EXODUS_URL}/tip`;

/**
 * @return {APIResponse}
 */
const getTippingJar = () => requestExodusNoAlert.get(`${EXODUS_TIPPING_URL}/jar`);

/**
 * Send tipping from stream
 * @param {Object} tipData
 * @param {Object} tipData.tipable - Target tip
 * @param {number} tipData.tipable.id - Target tip id
 * @param {Object} tipData.tipable.type - Target tip
 * @param {number} tipData.amount - Amount given
 * @param {string=} tipData.message - Additional message
 * @param {string=} tipData.callback_url - Additional message
 * @return {APIResponse}
 */
const sendTippingStream = (tipData) =>
  requestExodus.post(TIPPING_EXODUS_URL, tipData);

/**
 * Claim tipping balance
 * @param {number} amount - Amount to be claimed
 * @return {APIResponse}
 */
const claimTippingBalance = (amount) =>
  requestExodus.post(`${TIPPING_EXODUS_URL}/claim`, { amount });

/**
 * Update gopay account by number for tipping
 * @param {string} account - Account number
 * @return {APIResponse}
 */
const updateGopayAccount = (account) =>
  requestExodus.patch(`${EXODUS_TIPPING_URL}/gopay-account`, {
    gopay_account: account,
  });

/**
 * Get detail tipping from notif
 * @param {string | number} tippingId
 * @return {APIResponse}
 */
const getTippingDetail = (tippingId) =>
  requestExodusNoAlert.get(`${EXODUS_TIPPING_URL}/detail/${tippingId}`);

const getTippingActivity = (tabActivity = 'TIP_TAB_TIPPING') => requestExodusNoAlert.get(`${EXODUS_TIPPING_URL}/activity`, {
  params: { tab: tabActivity },
});

/**
 * Send tipping
 * @param {Object} tipData
 * @param {Object} tipData.tipable - Target tip
 * @param {number} tipData.tipable.id - Target tip id
 * @param {Object} tipData.tipable.type - Target tip
 * @param {number} tipData.amount - Amount given
 * @param {string=} tipData.message - Additional message
 * @param {string=} tipData.callback_url - Additional message
 * @return {APIResponse}
 */
const sendTipping = (tipData) =>
  requestExodus.post(TIPPING_EXODUS_URL, tipData);

export default {
  getTippingJar,
  getTippingDetail,
  sendTippingStream,
  claimTippingBalance,
  updateGopayAccount,
  getTippingActivity,
  sendTipping,
};
