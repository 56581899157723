import {
  createSlice,
  createSelector,
  createAsyncThunk,
  ActionReducerMapBuilder,
  SliceCaseReducers,
  SerializedError,
} from '@reduxjs/toolkit';

import allwatchlistSlice from '../slice';

import watchlist from 'lib/api/watchlist';
import { Watchlist } from '../../../../../../@types/watchlist';

// constants
const CONTEXT = '@sidewidget/listWatchlist';

interface State {
  saved: Watchlist[];
  isLoading: boolean;
  isCreateModalVisible: boolean;
  isDeleteModalVisible: boolean;
  watchlistTobeDeleted: string;
  error: SerializedError | Error;
}

// initial state
export const initialState: State = {
  saved: [],
  isLoading: false,
  isCreateModalVisible: false,
  isDeleteModalVisible: false,
  watchlistTobeDeleted: null,
  error: null,
};

// Selectors
const selectSelf = (state: any): State =>
  state.mainLayout.watchlist.listWatchlist;

export const selectors = createSelector(selectSelf, (state) => state);

// Async Actions / Side Effects
export const effects = {
  getSavedWatchlist: createAsyncThunk<{ data: Watchlist[] }>(
    `${CONTEXT}/GET_SAVED_WATCHLIST`,
    async () => {
      const response = await watchlist.getAllWatchlist();
      if (!response.data) {
        throw new Error('Attempt to fetch stream failed');
      }

      const { data, error } = response.data;

      if (error) {
        throw new Error(error);
      }
      return { data };
    },
  ),

  // create new watchlist
  createNewWatchlist: createAsyncThunk<
    { data: Watchlist }, // return value
    { name: string; description?: string } // params
  >(
    `${CONTEXT}/CREATE_NEW_WATCHLIST`,
    async ({ name, description }, { dispatch }) => {
      if (!name) {
        throw new Error('Watchlist name should be provided');
      }

      const response = await watchlist.createNewWatchlist(name, description);

      if (!response.data) {
        throw new Error('Attempt to fetch stream failed');
      }

      const { data, error_type: error } = response.data;

      if (error) {
        throw new Error(error);
      }

      dispatch(
        allwatchlistSlice.actions.setCurrentwatchlistId({
          watchlistId: data.watchlist_id,
        }),
      );

      dispatch(
        allwatchlistSlice.actions.toggleViewType({ listType: 'company' }),
      );

      return { data };
    },
  ),

  // delete selected watchlist
  deleteSelectedWatchlist: createAsyncThunk<
    { data: Watchlist }, // return value
    { watchlistid: string; activeWatchlistId: string } // params
  >(
    `${CONTEXT}/DELETE_SELECTED_WATCHLIST`,
    async ({ watchlistid = null, activeWatchlistId }, { dispatch }) => {
      if (!watchlistid) {
        throw new Error('Watchlist id should be provided');
      }

      const response = await watchlist.deleteWatchlistById(watchlistid);

      if (!response.data) {
        throw new Error('Attempt to delete company watchlist failed');
      }

      const { data, error_type: error } = response.data;

      if (error) {
        throw new Error(error);
      }

      if (activeWatchlistId === watchlistid) {
        dispatch(
          allwatchlistSlice.actions.setCurrentwatchlistId({
            watchlistId: null,
          }),
        );
      }

      return { data };
    },
  ),
};

// Reducer
const reducers: SliceCaseReducers<State> = {
  toggleCreateModal: (draft) => {
    draft.isCreateModalVisible = !draft.isCreateModalVisible;
  },
  toggleDeleteModal: (draft) => {
    draft.isDeleteModalVisible = !draft.isDeleteModalVisible;
  },
  setDeleteId: (draft, action) => {
    draft.watchlistTobeDeleted = action.payload.id;
    draft.isDeleteModalVisible = true;
  },
};

// Extra Reducer for async actions
const extraReducers = (builder: ActionReducerMapBuilder<State>) => {
  builder
    // add new watchlist
    .addCase(effects.createNewWatchlist.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(effects.createNewWatchlist.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.data) state.saved.push(action.payload.data);
    })
    .addCase(effects.createNewWatchlist.rejected, (state, action) => {
      state.error = action.error;
    })

    // get saved watchlists
    .addCase(effects.getSavedWatchlist.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(effects.getSavedWatchlist.fulfilled, (state, action) => {
      state.isLoading = false;
      state.saved = action.payload.data;
    })
    .addCase(effects.getSavedWatchlist.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    })

    // delete watchlist id
    .addCase(effects.deleteSelectedWatchlist.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(effects.deleteSelectedWatchlist.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isDeleteModalVisible = false;
      state.watchlistTobeDeleted = null;
      if (action.payload.data) {
        state.saved = state.saved.filter(
          (x) => x.watchlist_id !== action.payload.data.watchlist_id,
        );
      }
    })
    .addCase(effects.deleteSelectedWatchlist.rejected, (state) => {
      state.isLoading = false;
      state.isDeleteModalVisible = false;
      state.watchlistTobeDeleted = null;
    });
};

// Slice
const listWatchlistSlice = createSlice({
  name: 'listWatchlist',
  initialState,
  reducers,
  extraReducers,
});

export default listWatchlistSlice;
