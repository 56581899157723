/* eslint-disable no-underscore-dangle */
import { configureStore, ThunkDispatch, Action } from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';
// import reducers
import rootReducer from './rootReducer';

// devtools config definitions
const devToolsConfig = {
  name: 'Stockbit Web-App',
};
const withDevtools =
  process.env.NODE_ENV === 'development' ? devToolsConfig : false;

// Config store
export const makeStore = () =>
  configureStore({
    reducer: rootReducer,
    devTools: withDevtools,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          // Ignore these action types
          ignoredActions: [
            '@redux/websocket/INIT_CONFIG',
            '@redux/websocket/UPDATE_CONFIG',
            '@redux/websocket/ADD_SOCKET_DATA',
          ],
        },
      }),
  });

export const store = makeStore();

export const wrapper = createWrapper(makeStore, { debug: true });

export type RootState = ReturnType<typeof store.getState>;

export type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>;

export default store;
