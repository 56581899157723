import { createContext, useContext } from 'react';

import { SecuritiesContextValue } from './SecuritiesProvider.types';

const SecuritiesContext = createContext<SecuritiesContextValue>({
  isAuthorized: false,
  token: undefined,
  socket: undefined,
  setChannel: () => {},
  setAction: () => {},
  removeAction: () => {},
});

const useSecuritiesContext = () => {
  const context = useContext(SecuritiesContext);

  if (context === undefined) {
    throw new Error(
      'useSecuritiesContext must be used within a SecuritiesProvider',
    );
  }
  return context;
};

export { SecuritiesContext, useSecuritiesContext };
