import {
  ActionReducerMapBuilder,
  AsyncThunk,
  createAsyncThunk,
  createSelector,
  createSlice,
  SerializedError,
  SliceCaseReducers,
} from '@reduxjs/toolkit';
import numeral from 'numeral';
import router from 'next/router';
import handleErrorMessageAPI from 'global/AlertErrorMessage';
import SUCCESS_MESSAGE from 'constants/successMessage';

// lib
import shopApi from 'lib/api/shop';
// types
import { createMonthlyStatements } from 'features/shop-revamp/utils';
import {
  Gateway,
  MonthlyStatementDict,
  OrderDetail,
  OrderSubscription,
  Product,
  Promos,
  Purchase,
  Totals,
} from '../../../@types/shop';

export interface ShopState {
  products: {
    list: Product[];
    isLoading: boolean;
    error?: SerializedError;
    message?: string;
  };
  cart: {
    isLoading: boolean;
    error?: SerializedError;
    products?: Product[];
    promos?: Promos;
    totals?: Totals;
    applyingVoucher?: boolean;
  };
  purchase: {
    isLoading: boolean;
    error?: SerializedError;
    purchases?: Purchase[];
  };
  payment: {
    isLoading: boolean;
    error?: SerializedError;
    gateway: Gateway;
    paymentURL?: string;
    paymentType: 'popup' | 'redirect';
    snapToken?: string;
    clientKey?: string;
    orderId?: string;
  };
  activateFreeTrial: {
    isLoading: boolean;
    error?: SerializedError;
  };
  history: {
    isLoading: boolean;
    orders: MonthlyStatementDict;
    // orders: Order[];
    subscription: OrderSubscription;
  };
}

export type ShopThunks = {
  fetchProducts: AsyncThunk<
    { message?: string; products: Product[] },
    undefined,
    Record<string, any>
  >;
  addItems: AsyncThunk<{ data?: Product[] }, { selected: Product }, undefined>;
  continueSubscribe: AsyncThunk<
    { data?: Product[]; cart?: any },
    { selected: Product },
    undefined
  >;
  fetchCart: AsyncThunk<
    { products: Product[]; promos: Promos; totals: Totals },
    undefined,
    undefined
  >;
  checkout: AsyncThunk<
    {
      gateway?: Gateway;
      url?: string;
      paymentType: 'popup' | 'redirect';
      snapToken?: string;
      clientKey?: string;
      orderId?: string;
    },
    Gateway,
    {}
  >;
  applyCoupon: AsyncThunk<
    {
      data: any;
    },
    { coupon: string },
    undefined
  >;
  removeCoupon: AsyncThunk<
    {
      data: any;
    },
    undefined,
    undefined
  >;
  applyVoucher: AsyncThunk<
    {
      data: any;
    },
    undefined,
    undefined
  >;
  removePromo: AsyncThunk<
    {
      data: any;
    },
    undefined,
    undefined
  >;
  removeItem: AsyncThunk<
    {
      message: string;
    },
    undefined,
    undefined
  >;
  getPurchase: AsyncThunk<{ purchases: Purchase[] }, undefined, undefined>;
  activateFreeTrial: AsyncThunk<
    {
      data: any;
    },
    undefined,
    undefined
  >;
  fetchPurchaseHistory: AsyncThunk<{ data: OrderDetail }, undefined, undefined>;
};

// constants
const CONTEXT = '@entity/shop';

const actionType = {
  GET_PRODUCTS: `${CONTEXT}/GET_PRODUCTS`,
  CART_ADD_ITEM: `${CONTEXT}/CART_ADD_ITEM`,
  CONTINUE_SUBSCRIBE: `${CONTEXT}/CONTINUE_SUBSCRIBE`,
  CART_ADD_RECENT_ITEM: `${CONTEXT}/CART_ADD_RECENT_ITEM`,
  CART_REMOVE_ITEM: `${CONTEXT}/CART_REMOVE_ITEM`,
  CART_FETCH: `${CONTEXT}/CART_FETCH`,
  PAYMENT_CHECKOUT: `${CONTEXT}/PAYMENT_CHECKOUT`,
  APPLY_COUPON: `${CONTEXT}/APPLY_COUPON`,
  APPLY_VOUCHER: `${CONTEXT}/APPLY_VOUCHER`,
  REMOVE_PROMO: `${CONTEXT}/REMOVE_PROMO`,
  REMOVE_COUPON: `${CONTEXT}/REMOVE_COUPON`,
  GET_PURCHASE: `${CONTEXT}/GET_PURCHASE`,
  ACTIVATE_FREE: `${CONTEXT}/ACTIVATE_FREE`,
  GET_PURCHASE_HISTORY: `${CONTEXT}/GET_PURCHASE_HISTORY`,
};

const initialState: ShopState = {
  products: {
    isLoading: false,
    list: [],
    error: null,
    message: null,
  },
  cart: {
    isLoading: false,
    error: null,
    products: [],
    promos: null,
    totals: null,
  },
  payment: {
    isLoading: false,
    error: null,
    gateway: 'bca_va',
    paymentURL: null,
    paymentType: 'popup',
    orderId: '',
  },
  purchase: {
    isLoading: true,
    error: null,
    purchases: [],
  },
  activateFreeTrial: {
    isLoading: true,
    error: null,
  },
  history: {
    isLoading: false,
    orders: {},
    subscription: null,
  },
};

// reducers
const reducers: SliceCaseReducers<ShopState> = {
  setGateway: (draft, actions) => {
    draft.payment.gateway = actions.payload;
  },
};

// thunks
export const thunks: ShopThunks = {
  // -- Fetch products
  fetchProducts: createAsyncThunk(actionType.GET_PRODUCTS, async () => {
    const response = await shopApi.getProducts();
    const { error, data, message } = response.data;

    if (error) {
      throw new Error(error);
    }

    return { products: data, message };
  }),

  // -- Add items
  addItems: createAsyncThunk(
    actionType.CART_ADD_ITEM,
    async ({ selected }, { rejectWithValue }) => {
      const { code, id } = selected;

      const quantity = parseInt(code.substring(5, 7), 10);

      if (!quantity) {
        rejectWithValue(quantity);
        throw new Error('Invalid quantity format!');
      }

      const response = await shopApi.addItems(id, quantity);

      const { error } = response.data;

      if (error) {
        throw new Error(error);
      }

      return { data: [response.data.data] };
    },
  ),

  continueSubscribe: createAsyncThunk(
    actionType.CONTINUE_SUBSCRIBE,
    async ({ selected }, { rejectWithValue }) => {
      const { code, id } = selected;

      const quantity = parseInt(code.substring(5, 7), 10);

      if (!quantity) {
        rejectWithValue(quantity);
        throw new Error('Invalid quantity format!');
      }

      const response = await shopApi.addItems(id, quantity);
      const responseCart = await shopApi.getCart();

      const { error } = response.data;

      if (error) {
        throw new Error(error);
      }

      return { data: [response.data.data], cart: responseCart.data.data };
    },
  ),

  // -- Add items
  removeItem: createAsyncThunk(actionType.CART_REMOVE_ITEM, async () => {
    const response = await shopApi.removeItem();

    const { data } = response;

    const { error } = data;

    if (error) {
      throw new Error(error);
    }

    return { message: 'success' };
  }),

  // -- Fetch Cart
  fetchCart: createAsyncThunk(actionType.CART_FETCH, async () => {
    const response = await shopApi.getCart();

    const { data, error } = response.data;

    if (error) {
      throw new Error(error);
    }

    return {
      products: data.products,
      promos: data.promos,
      totals: data.totals,
    };
  }),

  // -- Checkout
  checkout: createAsyncThunk(
    actionType.PAYMENT_CHECKOUT,
    // @ts-ignore
    async (gateway) => {
      const response = await shopApi.checkout(gateway);

      const { data, error } = response.data;

      if (error) {
        throw new Error(error);
      }

      if (data?.action) {
        return {
          paymentType: 'popup',
          snapToken: data.action.snap_token,
          clientKey: data.action.client_key,
          orderId: data.action.order_id,
        };
      }

      const apiURL = new URL(data.url);
      const paymentType = gateway === 'veritrans' ? 'redirect' : 'popup';

      return {
        gateway: data.gateway,
        url: data.url,
        paymentType,
        snapToken: apiURL.searchParams.get('snap_token'),
        clientKey: apiURL.searchParams.get('client_key'),
      };
    },
  ),

  // -- Apply Coupon
  applyCoupon: createAsyncThunk(actionType.APPLY_COUPON, async (coupon) => {
    const response = await shopApi.applyCoupon(coupon);

    const { data, error } = response.data;

    if (error) {
      throw new Error(error);
    }

    return {
      data,
    };
  }),

  // -- Apply Coupon
  applyVoucher: createAsyncThunk(actionType.APPLY_VOUCHER, async (coupon) => {
    const response = await shopApi.applyVoucher(coupon);

    const { data, error } = response.data;

    if (error) {
      throw new Error(error);
    }

    return {
      data,
    };
  }),

  // -- Apply Coupon
  removePromo: createAsyncThunk(actionType.REMOVE_PROMO, async (type) => {
    const response = await shopApi.removePromo(type);

    const { data, error } = response.data;

    if (error) {
      throw new Error(error);
    }

    return {
      data,
    };
  }),

  // -- Remove Coupon
  removeCoupon: createAsyncThunk(actionType.REMOVE_COUPON, async () => {
    const response = await shopApi.removeCoupon();

    const { data, error } = response.data;

    if (error) {
      throw new Error(error);
    }

    return {
      data,
    };
  }),

  // -- Fetch products
  getPurchase: createAsyncThunk(actionType.GET_PURCHASE, async () => {
    const response = await shopApi.getSubscriptions();
    const { error, data, message } = response.data;

    if (error) {
      throw new Error(error);
    }

    return { purchases: data };
  }),

  // -- Fetch products
  activateFreeTrial: createAsyncThunk(actionType.ACTIVATE_FREE, async () => {
    const response = await shopApi.activateFreeTrial();
    const { error, data } = response.data;
    const { message, error_type } = data;

    if (error || error_type) {
      throw new Error(error);
    }

    handleErrorMessageAPI(message, SUCCESS_MESSAGE.ALERT_GREEN);

    return { data };
  }),

  fetchPurchaseHistory: createAsyncThunk(
    actionType.GET_PURCHASE_HISTORY,
    async () => {
      const params = {
        last_id: 0,
        limit: 10,
      };
      const response = await shopApi.getPurchaseHistory(params);
      const { error, data } = response.data;

      if (error) {
        throw new Error(error);
      }

      return { data };
    },
  ),
};

const extraReducers = (builder: ActionReducerMapBuilder<ShopState>) =>
  builder
    // Fetch products
    .addCase(thunks.fetchProducts.pending, (draft) => {
      draft.products.isLoading = true;
    })
    .addCase(thunks.fetchProducts.fulfilled, (draft, actions) => {
      draft.products.isLoading = false;
      draft.products.list = actions.payload.products;
      draft.products.message = actions.payload.message;
      draft.products.error = null;
    })
    .addCase(thunks.fetchProducts.rejected, (draft, actions) => {
      draft.products.isLoading = false;
      draft.products.error = actions.error;
    })

    // -- Add to cart
    .addCase(thunks.addItems.pending, (draft) => {
      draft.cart.isLoading = true;
    })
    .addCase(thunks.addItems.fulfilled, (draft, actions) => {
      draft.cart.error = null;
      draft.cart.isLoading = false;
      draft.cart.products = actions.payload.data;
      router.push('/shop/cart');
    })
    .addCase(thunks.addItems.rejected, (draft, actions) => {
      draft.cart.isLoading = false;
      draft.cart.error = actions.error;
    })

    // Continue Subscribe
    .addCase(thunks.continueSubscribe.pending, (draft) => {
      draft.cart.isLoading = true;
    })
    .addCase(thunks.continueSubscribe.fulfilled, (draft, actions) => {
      draft.cart.error = null;
      draft.cart.isLoading = false;
      draft.cart.products = actions.payload.cart.products;
      router.push('/shop/cart');
    })
    .addCase(thunks.continueSubscribe.rejected, (draft, actions) => {
      draft.cart.isLoading = false;
      draft.cart.error = actions.error;
    })

    // -- Fetch cart
    .addCase(thunks.fetchCart.pending, (draft) => {
      draft.cart.isLoading = true;
    })

    .addCase(thunks.fetchCart.fulfilled, (draft, actions) => {
      draft.cart = {
        isLoading: false,
        error: null,
        products: actions.payload.products,
        promos: actions.payload.promos,
        totals: actions.payload.totals,
        applyingVoucher: false,
      };
    })

    .addCase(thunks.fetchCart.rejected, (draft, actions) => {
      draft.cart.isLoading = false;
      draft.cart.error = actions.error;
    })

    // -- Checkout
    .addCase(thunks.checkout.pending, (draft) => {
      draft.payment.isLoading = true;
    })
    .addCase(thunks.checkout.fulfilled, (draft, actions) => {
      draft.payment.error = null;
      draft.payment.isLoading = false;
      draft.payment.paymentType = actions.payload.paymentType;

      draft.payment.gateway = actions.payload.gateway;
      draft.payment.paymentURL = actions.payload.url;
      draft.payment.snapToken = actions.payload.snapToken;
      draft.payment.clientKey = actions.payload.clientKey;
    })
    .addCase(thunks.checkout.rejected, (draft, actions) => {
      draft.payment.isLoading = false;
      draft.payment.error = actions.error;
    })
    .addCase(thunks.removeItem.fulfilled, () => {
      router.push('/shop');
    })
    .addCase(thunks.applyCoupon.fulfilled, (draft) => {
      draft.cart.applyingVoucher = true;
    })
    .addCase(thunks.applyVoucher.fulfilled, (draft) => {
      draft.cart.applyingVoucher = true;
    })
    .addCase(thunks.removePromo.fulfilled, (draft) => {
      draft.cart.applyingVoucher = true;
    })

    // Fetch Purchases
    .addCase(thunks.getPurchase.pending, (draft) => {
      draft.purchase.isLoading = true;
    })
    .addCase(thunks.getPurchase.fulfilled, (draft, actions) => {
      draft.purchase.isLoading = false;
      draft.purchase.purchases = actions.payload.purchases;
    })
    .addCase(thunks.getPurchase.rejected, (draft, actions) => {
      draft.purchase.isLoading = false;
      draft.purchase.error = actions.error;
    })

    // Fetch Purchases
    .addCase(thunks.activateFreeTrial.pending, (draft) => {
      draft.activateFreeTrial.isLoading = true;
    })
    .addCase(thunks.activateFreeTrial.fulfilled, (draft, actions) => {
      draft.activateFreeTrial.isLoading = false;
    })
    .addCase(thunks.activateFreeTrial.rejected, (draft, actions) => {
      draft.activateFreeTrial.isLoading = false;
    })

    // Fetch Purchase History
    .addCase(thunks.fetchPurchaseHistory.pending, (draft) => {
      draft.history.isLoading = true;
    })
    .addCase(thunks.fetchPurchaseHistory.fulfilled, (draft, actions) => {
      draft.history.isLoading = false;
      draft.history.orders = createMonthlyStatements(
        actions.payload.data.orders,
      );
      draft.history.subscription = actions.payload.data.subscription;
    })
    .addCase(thunks.fetchPurchaseHistory.rejected, (draft, actions) => {
      draft.history.isLoading = false;
    });
// selectors
const shopEntitySelector = (state): ShopState => state.entities.shop;
const selectProductList = (state: ShopState) =>
  state.products.list.map(({ description, keyword, price }) => ({
    duration: description,
    price: (numeral(price).value() / 1000).toString(),
    description: keyword,
    features: 'Full Features',
  }));

export const selectors = createSelector(shopEntitySelector, (state) => ({
  ...state,
  products: {
    ...state.products,
    availableProducts: selectProductList(state),
  },
}));

// slice

const shopSlice = createSlice({
  initialState,
  name: 'shop',
  reducers,
  extraReducers,
});

export default shopSlice;
