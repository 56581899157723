/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
import numeral from 'numeral';
import { WatchlistCompanyItem } from '../../../../../@types/watchlist';
import {
  ChangeDirection,
  CompanyDataSource,
  CustomColumn,
  CustomHeader,
} from '../types';

function getChangeDirection(change: number): ChangeDirection {
  if (change > 0) {
    return 'up';
  }

  if (change < 0) {
    return 'down';
  }

  return 'neutral';
}

function mapCustomHeader(customHeaders: Array<any>): CustomHeader[] {
  return customHeaders.map((header) => ({
    key: header.item_id,
    name: header.value,
    isLoading: false,
    value: {
      itemid: parseInt(header.item_id, 10),
      itemname: header.value,
      results: null,
    },
  }));
}

const mapCustomColumnFromHeader = (header: CustomHeader): CustomColumn => ({
  dataIndex: header.key,
  key: header.key,
  title: header.name,
  className: 'table-dragable-column',
});

const mapCompanyDataSource = (
  item: WatchlistCompanyItem,
): CompanyDataSource => ({
  key: item.id,
  price: {
    id: item.id,
    change: numeral(Number(item.change)).format('0,0.00'),
    changeDirection: getChangeDirection(item.change),
    percentage: numeral(Number(item.percent)).format('0,0.00'),
    price: numeral(Number(item.last)).format('0,0[.]00'),
    last: item.last,
    formatted_price: item.formatted_price,
  },
  symbol: {
    id: item.id,
    name: item.name,
    symbol: item.symbol,
    uma: item.uma,
    notation: item.notation,
    corp_action: item.corp_action,
    tradeable: item.tradeable,
    status: item.status,
    type: item.type,
  },
  notation: item.notation,
  uma: item.uma,
});

const customHeaderObjectReducer = (prev: any, current: CustomHeader): any => ({
  ...prev,
  [current.key]: null,
});

function generateSymbols(
  companyDataSources: CompanyDataSource[],
  customHeaderObject: any,
) {
  const symbols = companyDataSources.reduce(
    (prev, current: CompanyDataSource) => ({
      ...prev,
      [current.symbol.symbol]: {
        ...current,
        ...customHeaderObject,
      },
    }),
    {},
  );

  return symbols;
}

function tableSorter(ax, bx, type = 'numeric') {
  if (type == 'string') {
    if (ax < bx) return -1;
    if (ax > bx) return 1;
    return 0;
  }
  ax = String(ax);
  bx = String(bx);
  if (ax == '-' || ax == 'n/a') return -1;
  if (bx == '-' || bx == 'n/a') return 1;

  ax = ax.replace(/,/g, '');
  bx = bx.replace(/,/g, '');
  ax = ax.replace('%', '');
  bx = bx.replace('%', '');
  ax = ax.replace('+', '');
  bx = bx.replace('+', '');

  if (ax.indexOf('(') > -1) {
    ax = ax.replace('(', '-');
    ax = ax.replace(')', '');
  }

  if (bx.indexOf('(') > -1) {
    bx = bx.replace('(', '-');
    bx = bx.replace(')', '');
  }

  if (ax.indexOf('b') > -1 || ax.indexOf('B') > -1) {
    ax = parseFloat(ax) * 1000000000;
  } else if (ax.indexOf('m') > -1 || ax.indexOf('M') > -1) {
    ax = parseFloat(ax) * 1000000;
  } else if (ax.indexOf('k') > -1 || ax.indexOf('K') > -1) {
    ax = parseFloat(ax) * 1000;
  } else {
    ax = parseFloat(ax);
  }

  if (bx.indexOf('b') > -1 || bx.indexOf('B') > -1) {
    bx = parseFloat(bx) * 1000000000;
  } else if (bx.indexOf('m') > -1 || bx.indexOf('M') > -1) {
    bx = parseFloat(bx) * 1000000;
  } else if (bx.indexOf('k') > -1 || bx.indexOf('K') > -1) {
    bx = parseFloat(bx) * 1000;
  } else {
    bx = parseFloat(bx);
  }

  if (ax < bx) return -1;
  if (ax > bx) return 1;
  return 0;
}

export default {
  getChangeDirection,
  mapCustomHeader,
  mapCustomColumnFromHeader,
  mapCompanyDataSource,
  customHeaderObjectReducer,
  generateSymbols,
  tableSorter,
};
