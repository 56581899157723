const SOCKET_URL = process.env.NEXT_PUBLIC_GENERAL_SOCKET;

function initPrimus(server = SOCKET_URL) {
  // @ts-ignore
  if (typeof window === 'undefined' || typeof window.Primus === 'undefined') {
    return null;
  }

  // @ts-ignore
  const primus = window.Primus(server, { strategy: false });

  // @ts-ignore
  window.primus = primus;
  return primus;
}

export default initPrimus;
