const schema = {
  count: {
    company: 0,
    stream: 0,
    other: 0,
  },
  content: {
    company: [],
    stream: [],
    other: [],
    tipping: [],
  },
};

export default schema;
