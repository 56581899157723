import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

// APIs
import userApi from 'lib/api/user';

// alert error message
import handleErrorMessageAPI from 'global/AlertErrorMessage';

// constant
import ERROR_MESSAGE from 'constants/errorMessage';

// Initial state
const initialState = {
  uploadImage: {
    isLoading: false,
    error: null,
  },
  uploadToken: {
    data: {
      bucketUrl: '',
      key: '',
      xAmzCredential: '',
      xAmzAlgorithm: '',
      xAmzDate: '',
      xAmzSecurityToken: '',
      policy: '',
      xAmzSignature: '',
    },
    isLoading: false,
    error: null,
    message: '',
  },
};

// Selectors
export const selectors = createSelector(
  (state) => ({
    uploadImageAvatar: state.onboarding.uploadImageAvatar.uploadImage,
    uploadToken: state.onboarding.uploadImageAvatar.uploadToken,
  }),
  (state) => state,
);

// Action types
const CONTEXT = '@onboarding/uploadImageAvatar';

const actionType = {
  UPLOAD_IMAGE_AVATAR: `${CONTEXT}/UPLOAD_IMAGE_AVATAR`,
  RETRIEVE_UPLOAD_TOKEN: `${CONTEXT}/RETRIEVE_UPLOAD_TOKEN`,
};

export const effects = {
  retrieveUploadToken: createAsyncThunk(
    actionType.RETRIEVE_UPLOAD_TOKEN,
    // @ts-ignore
    async (fileName) => {
      try {
        const response = await userApi.retrieveUploadToken(fileName);
        if (response.data) {
          const { data, message, error } = response.data;

          if (error) {
            handleErrorMessageAPI(
              ERROR_MESSAGE.UPLOAD_FAILED,
              ERROR_MESSAGE.ALERT_RED,
            );
            return { message, error };
          }
          return { data, message };
        }
      } catch (error) {
        return { error };
      }
    },
  ),
  // uploadImageAvatar -------------------------------------------------------------------
  uploadImageAvatar: createAsyncThunk(
    actionType.UPLOAD_IMAGE_AVATAR,
    async (dataFile, { dispatch, getState }) => {
      const state = getState() as any;

      // @ts-ignore
      const { file } = dataFile;
      try {
        // append form data for request body
        const fd = new FormData();
        let formerFile = null;
        /* eslint no-underscore-dangle: 0 */
        if (file._file) formerFile = file._file;

        const {
          key,
          xAmzCredential,
          xAmzAlgorithm,
          xAmzDate,
          xAmzSecurityToken,
          policy,
          xAmzSignature,
        } = state.onboarding.uploadImageAvatar.uploadToken.data;

        fd.append('key', key);
        fd.append('content-type', 'image/jpeg');
        fd.append('x-amz-credential', xAmzCredential);
        fd.append('x-amz-algorithm', xAmzAlgorithm);
        fd.append('x-amz-date', xAmzDate);
        fd.append('x-amz-security-token', xAmzSecurityToken);
        fd.append('policy', policy);
        fd.append('x-amz-signature', xAmzSignature);

        if (!formerFile) {
          fd.append('file', file);
        } else {
          fd.append('file', formerFile);
        }

        return fetch(
          state.onboarding.uploadImageAvatar.uploadToken.data.bucketUrl,
          {
            method: 'POST',
            cache: 'default',
            body: fd,
            mode: 'cors',
          },
        ).then((response) => {
          if (response.status === 204) {
            return { message: 'ok' };
          }
          handleErrorMessageAPI(
            ERROR_MESSAGE.UPLOAD_FAILED,
            ERROR_MESSAGE.ALERT_RED,
          );
          throw new Error('error while trying to upload image');
        });
      } catch (error) {
        return { error };
      }
    },
  ),
};

// reducers
const reducers = {};

const extraReducers = (builder) => {
  builder
    .addCase(effects.retrieveUploadToken.pending, (state: any, action: any) => {
      state.uploadToken.isLoading = true;
    })
    .addCase(
      effects.retrieveUploadToken.fulfilled,
      (state: any, action: any) => {
        const { data, message, error } = action.payload;
        const { url, fields } = data;

        if (error) {
          state.uploadToken.error = error;
        } else {
          state.uploadToken.data.bucketUrl = url;
          state.uploadToken.data.key = fields[0].value;
          state.uploadToken.data.xAmzCredential = fields[2].value;
          state.uploadToken.data.xAmzAlgorithm = fields[3].value;
          state.uploadToken.data.xAmzDate = fields[4].value;
          state.uploadToken.data.xAmzSecurityToken = fields[5].value;
          state.uploadToken.data.policy = fields[6].value;
          state.uploadToken.data.xAmzSignature = fields[7].value;
        }
        state.uploadToken.message = message;
        state.uploadToken.isLoading = false;
      },
    )
    .addCase(
      effects.retrieveUploadToken.rejected,
      (state: any, action: any) => {
        state.uploadToken.error = action.payload.error;
        state.uploadToken.isLoading = false;
      },
    )

    .addCase(effects.uploadImageAvatar.pending, (state: any, action: any) => {
      state.uploadImage.isLoading = true;
    })
    .addCase(effects.uploadImageAvatar.fulfilled, (state: any, action: any) => {
      const { error } = action.payload;
      if (error) {
        state.uploadImage.error = error;
      }
      state.uploadImage.isLoading = false;
    })
    .addCase(effects.uploadImageAvatar.rejected, (state: any, action: any) => {
      state.uploadImage.error = action.payload.error;
      state.uploadImage.isLoading = false;
    });
};

// Create slice
const uploadAvatarSlice = createSlice({
  name: 'uploadImageAvatar',
  initialState,
  reducers,
  extraReducers,
});

export default uploadAvatarSlice;
