import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import ERROR_MESSAGE from 'constants/errorMessage';
import handleErrorMessageAPI from 'global/AlertErrorMessage';

// APIs
import catalogApi from 'lib/api/catalog';
import { convertToSlug } from 'utils/general';
import { CommonPayload } from '../../../@types/action-payload';

// Initial state
const initialState = {
  list: {},
  subsectors: [],
  sectors: [],
  pageTitle: null,
  error: null,
  isLoading: false,
  message: '',
  companies: [],
};

type CatalogState = typeof initialState;

// Selectors
const catalog = (state) => state.catalog;
export const selectors = createSelector(catalog, (cat: CatalogState) => ({
  ...cat,
}));

// Action type
const CONTEXT = '@feature/catalog';

const actionType = {
  GET_COMPANY_LIST: `${CONTEXT}/GET_COMPANY_LIST`,
  GET_SECTOR_LIST: `${CONTEXT}/GET_SECTOR_LIST`,
};

interface getCompanyParams {
  sector: string;
  subsector: string;
  isCatalog?: boolean;
}

interface getCompanyPayload extends CommonPayload {
  sector?: string;
  sectors?: Array<any>;
  subsector?: string;
  subsectors?: Array<any>;
  companies?: any[];
}

// Side effects
export const effects = {
  getSectorList: createAsyncThunk<CommonPayload>(
    actionType.GET_SECTOR_LIST,
    async () => {
      const res = await catalogApi.getSectorList();

      if (!res.data) {
        throw new Error('Failed to fetch sector list');
      }

      const { data, error_type, error, message } = res.data;

      if (error_type || error) {
        throw new Error(message);
      }

      return {
        data,
        error,
        message,
      };
    },
  ),
  getCompanyList: createAsyncThunk<getCompanyPayload, getCompanyParams>(
    actionType.GET_COMPANY_LIST,
    async ({ sector, subsector, isCatalog = false }, { getState }) => {
      try {
        let companies = [];
        let companyListPayload = {};
        let getSectors;

        if (!sector) {
          throw new Error('Sector is required');
        }

        const {
          catalog: { sectors },
        } = getState() as any;

        if (sectors.length === 0) {
          // Get sector list first
          getSectors = await catalogApi.getSectorList();

          if (!getSectors.data) {
            throw new Error('Attempt to get sector list failed');
          }
        }

        const getSectorData =
          sectors.length > 0 ? { data: sectors } : getSectors?.data;
        if (getSectorData.error) {
          return { error: 'Error sector', message: getSectorData.message };
        }

        const sectorData = getSectorData.data.find(
          (sc) => convertToSlug(sc.alias1) === convertToSlug(sector),
        );

        if (!sectorData) {
          throw new Error('Sector not found');
        }

        // Get subsector list
        const getSubsector = await catalogApi.getSubsectorList(sectorData.id);

        if (!getSubsector.data) {
          throw new Error('Attempt to get sub sector data failed');
        }

        const getSubsectorData = getSubsector.data;
        if (getSubsectorData.error) {
          return {
            error: 'Error subsector',
            message: getSubsectorData.message,
          };
        }

        let subsectorData;
        let companyResponse: any = {
          data: {
            data: null,
          },
        };

        if (subsector) {
          const isSyariah =
            sector.toLowerCase() === 'indeks' &&
            subsector.toLowerCase() === 'syariah';
          const newSubsector = isSyariah ? 'ISSI' : subsector;

          // if subsector available in url path, we will
          // call company list by subsector id
          subsectorData = getSubsectorData.data.find(
            (sb) => convertToSlug(sb.alias1) === convertToSlug(newSubsector),
          );

          if (!subsectorData) {
            companyListPayload = {
              error: 'Error subsector',
              message: 'Subsector not found',
            };
          } else {
            // Get company list based on subsector
            companyResponse = await catalogApi.getCompanyList(
              sectorData.id,
              subsectorData.id,
            );

            if (!companyResponse.data) {
              throw new Error('Attempt to get company list failed');
            }

            companies = companyResponse.data.data;
          }
        } else if (isCatalog) {
          const getCompanyInSector = await catalogApi.getCompanyInSector(sectorData.id);

          const getCompanyInSectorData = getCompanyInSector.data;

          companies = getCompanyInSectorData.data;
        } else {
          // if subsector not available in path, it means user open
          // sector/[sector], so we will call every company from
          // subsector data

          const companyRequest = await getSubsectorData.data.map(
            async (subsector) => {
              const company = await catalogApi.getCompanyList(
                sectorData.id,
                subsector.id,
              );
              return {
                company,
                subsectorName: subsector.alias1,
              };
            },
          );

          const listCompanyResponse = await Promise.all(companyRequest);
          companyResponse = { data: { data: [] } };
          listCompanyResponse.map((response) => {
            companyResponse.data.data.push({
              alias: response.subsectorName,
              data: [...response.company.data.data],
            });
          });
        }

        const { error, data, message } = companyResponse.data;

        if (error) {
          handleErrorMessageAPI(
            message || error?.message,
            ERROR_MESSAGE.ALERT_RED,
          );
          return { error, message };
        }

        return {
          data,
          message,
          sector,
          sectors: getSectorData.data,
          subsector,
          subsectors: getSubsectorData.data,
          companies,
          ...companyListPayload,
        };
      } catch (error) {
        handleErrorMessageAPI(error?.message, ERROR_MESSAGE.ALERT_RED);
        return { error };
      }
    },
  ),
};

// Reducers
const reducers = {
  updateList: (state, action) => {
    const newList = {};
    const { index: symbol, data } = action.payload;
    Object.keys(state.list).map((key1) => {
      newList[key1] = {};
      Object.keys(state.list[key1]).map((key2) => {
        newList[key1][key2] = [];
        const values = state.list[key1][key2];
        values.map((value) => {
          if (value.symbol === symbol) {
            newList[key1][key2].push({
              ...value,
              last: data.lastprice,
              change: data.change,
              percent: data.percentage_change,
            });
          } else {
            newList[key1][key2].push(value);
          }
        });
      });
    });
    state.list = newList;
  },
};

const extraReducers = (builder) => {
  builder
    .addCase(
      effects.getCompanyList.pending,
      (
        state: CatalogState,
        action: PayloadAction<getCompanyPayload, string, any>,
      ) => {
        state.error = null;
        state.isLoading = true;

        const { sector } = action.meta.arg;
        if (!state.list[sector]) {
          state.list[sector] = {};
        }
      },
    )
    .addCase(
      effects.getCompanyList.fulfilled,
      (state: CatalogState, action: PayloadAction<getCompanyPayload>) => {
        const { data, message, error, sector, sectors, subsector, subsectors, companies } =
          action.payload;

        if (error) {
          state.error = error;
        }

        if (sectors && sector && !subsector) {
          state.sectors = sectors;
          const activeSector = sectors.find(
            (item) => convertToSlug(item?.alias1) === convertToSlug(sector),
          );
          state.pageTitle = activeSector?.name || null;

          if (data) {
            data.map((item) => {
              state.list[sector][item.alias] = item.data;
            });
          }
        }

        if (subsectors) {
          state.subsectors = subsectors;
          if (subsector) {
            state.list[sector][subsector] = data;
          }
        }

        state.companies = companies;
        state.message = message;
        state.isLoading = false;
      },
    )
    .addCase(
      effects.getCompanyList.rejected,
      (state: CatalogState, action: PayloadAction<getCompanyPayload>) => {
        state.error = action.payload.error;
        state.isLoading = false;
      },
    )

    // Get sector list
    .addCase(effects.getSectorList.pending, (state: CatalogState) => {
      state.isLoading = true;
      state.error = false;
    })
    .addCase(
      effects.getSectorList.fulfilled,
      (state: CatalogState, action: PayloadAction<CommonPayload>) => {
        const { error, message, data } = action.payload;

        if (error) {
          state.error = error;
        } else {
          state.error = null;
          state.sectors = data;
        }

        state.isLoading = false;
        state.message = message;
      },
    )
    .addCase(
      effects.getSectorList.rejected,
      (state: CatalogState, action: PayloadAction<CommonPayload>) => {
        state.error = action.payload;
        state.isLoading = false;
      },
    );
};

const catalogSlice = createSlice({
  name: 'catalog',
  initialState,
  reducers,
  extraReducers,
});

export default catalogSlice;
