import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
  createSelector,
  PayloadAction,
} from '@reduxjs/toolkit';

// Auth Related Modules
import generateUsername from 'utils/generateUsername';
import auth from 'lib/api/auth';

import rootSlice from '../slice';
import { handleRegistrationError } from '../utils';
import { CommonPayload } from '../../../../@types/action-payload';
import { SocialIdentities, FacebookPayload } from '../types';
import {
  formTypes,
  registerTypes,
  newRegisterTypes,
} from '../constants';

// Actions
const CONTEXT = '@feature/register/facebook';
const actionType = {
  FB_GENERATE_USERNAME: `${CONTEXT}/generateUsername`,
};

// Initial state
interface State {
  isLoading: boolean;
  error: null | Error;
  message: string;
}

const initialState: State = {
  isLoading: false,
  error: null,
  message: '',
};

// Selectors
export const selectors = createSelector(
  (state) => ({
    isLoading: state.register.facebookOauth.isLoading,
    error: state.register.facebookOauth.error,
    message: state.register.facebookOauth.message,
  }),
  (state: State) => state,
);

// Effects (thunks / async operations that needs side effects)
export const effects = {
  generateUsername: createAsyncThunk<SocialIdentities, FacebookPayload>(
    actionType.FB_GENERATE_USERNAME,
    async (
      {
        accessToken: accountkey,
        id: accountid,
        error: fbError,
        email,
        name: fullname,
      },
      { dispatch },
    ) => {
      try {
        if (fbError || !accountkey || !accountid) {
          throw new Error(fbError);
        }

        const { data } = await auth.validateSocialMedia(
          accountid,
          accountkey,
          email,
          newRegisterTypes.FACEBOOK,
        );

        let username = generateUsername.normalUsername(fullname);
        await auth
          .validateRegisterForm('username', username, data.data.key)
          .catch((_) => {
            username = generateUsername.randomUsername(fullname);
          });

        dispatch(
          rootSlice.actions.storeEmailVerifData({
            tempKey: data.data.key,
            emailStatus: data.data.status,
          }),
        );

        dispatch(
          rootSlice.actions.storeSocialIdentities({
            accountid,
            accountkey,
            email,
            fullname,
            username,
            type: registerTypes.FACEBOOK,
          }),
        );

        dispatch(rootSlice.actions.changeForm({ target: formTypes.EMAIL }));

        return {
          accountid,
          accountkey,
          email,
          fullname,
          username,
        };
      } catch (error) {
        const responseMessage = error.response?.data?.message || error.message;
        handleRegistrationError(responseMessage, email);
        return Promise.reject(responseMessage);
      }
    },
  ),
};

// Extra reducers (handle actions that are not defined in this slice)
const extraReducers = (builder: ActionReducerMapBuilder<State>) => {
  // generate username - pending
  builder.addCase(effects.generateUsername.pending, (state: State) => {
    state.isLoading = true;
  });

  // generate username - fulfilled
  builder.addCase(effects.generateUsername.fulfilled, (state: State) => {
    state.isLoading = false;
    state.error = null;
  });

  // generate username - rejected
  builder.addCase(
    effects.generateUsername.rejected,
    (state: State, action: PayloadAction<CommonPayload, string, any, any>) => {
      state.isLoading = false;
      state.error = action.error;
    },
  );
};

const loginSlice = createSlice({
  name: 'facebook',
  initialState,
  reducers: {},
  extraReducers,
});

export default loginSlice;
