import { DiscoverFilter, TypeFilter } from './types';

const DISCOVER_FILTERS: DiscoverFilter[] = [
  { label: 'Trending', value: 'TRENDING' },
  { label: 'Suggestions', value: 'SUGGEST' },
  { label: 'Friends', value: 'CONTACT' },
];

const EMPTY_MESSAGE = {
  contacts: 'You have no suggested people on your contact',
  facebook: 'You have no facebook friends',
  all: 'You have no suggested people',
  official: 'You have no suggested official contact',
};

const USER_TYPES = {
  OFFICIAL_USER: 'OFFICIAL',
  TOP: 'TOP',
};

const SUGGESTIONS_PERPAGE = 30;
const LIMIT_SEARCH_USER = 20;
const LIMIT_TRENDING_USER = 100;

const QUERY_KEY_DATA: Record<TypeFilter, string> = {
  SUGGEST: 'getDataFriendSuggestionsList',
  CONTACT: 'getDataContactList',
  SEARCH: 'getSearchUserList',
  TRENDING: 'getTrendingUserList',
};

export {
  DISCOVER_FILTERS,
  SUGGESTIONS_PERPAGE,
  LIMIT_SEARCH_USER,
  LIMIT_TRENDING_USER,
  USER_TYPES,
  EMPTY_MESSAGE,
  QUERY_KEY_DATA,
};
