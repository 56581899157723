/* eslint-disable no-undef */
import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import streamApi from 'lib/api/stream';
import { effects as streamWidgetEffects } from 'global/StreamWidget/slice';
import { effects as streamEffects } from 'lib/entities/stream';
import { transformStreamV3 } from 'utils/stream';
import { StreamPostData } from '../../../@types/stream';

// APIs
import { CommonPayload } from '../../../@types/action-payload';

export interface PostDetailState {
  post: Record<string | number, StreamPostData>;
  isLoading: boolean;
  error: any;
  message: string;
}

const STATUS_FOLLOWING = 1;
const STATUS_UNFOLLOWING = 0;

const STATUS_SAVED = 1;
const STATUS_UNSAVED = 0;

const STATUS_LIKED = 1;
const STATUS_UNLIKED = 0;

const initialState = {
  post: {},
  isLoading: true,
  error: null,
  message: '',
};

// selectors
const postDetail = (state) => state.postDetail;
export const selectors = createSelector(
  postDetail,
  (detail: PostDetailState) => ({ ...detail }),
);

// Action types
const CONTEXT = '@features/post-detail';

const actionType = {
  GET_POST: `${CONTEXT}/GET_POST`,
};

// Side effects
export interface getStreamPostPayload extends CommonPayload<StreamPostData> {
  postid?: string | number;
}

interface getPostProps {
  postId: string | number,
}

export const effects = {
  getPostDetail: createAsyncThunk<getStreamPostPayload, getPostProps>(
    actionType.GET_POST,
    async ({ postId }) => {
      try {
        const response = await streamApi.getStreamDetail(postId);

        if (!response.data) {
          throw new Error('Attempt to get stream post detail failed');
        }

        const { error_type: error, message, data: postData } = response.data;

        if (error) {
          return { error, message };
        }

        const data = transformStreamV3(postData);
        return { data, message, postid: postId };
      } catch (error) {
        return { error };
      }
    },
  ),
};

const reducers = {};

const extraReducers = (builder) => {
  builder
    .addCase(effects.getPostDetail.pending, (state: PostDetailState) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(
      effects.getPostDetail.fulfilled,
      (state: PostDetailState, action: PayloadAction<getStreamPostPayload>) => {
        const { error, message, data, postid } = action.payload;

        if (error) {
          state.error = error;
        } else {
          state.post[postid] = data;
        }

        state.isLoading = false;
        state.message = message;
      },
    )
    .addCase(
      streamWidgetEffects.likePost.fulfilled,
      (state: PostDetailState, action: PayloadAction<getStreamPostPayload>) => {
        const { error, data, postid } = action.payload;

        if (!error && state.post[postid]) {
          state.post[postid].liked = STATUS_LIKED;
          state.post[postid].likes = data.likes;
        }
      },
    )
    .addCase(
      streamWidgetEffects.unlikePost.fulfilled,
      (state: PostDetailState, action: PayloadAction<getStreamPostPayload>) => {
        const { error, data, postid } = action.payload;

        if (!error && state.post[postid]) {
          state.post[postid].liked = STATUS_UNLIKED;
          state.post[postid].likes = data.likes;
        }
      },
    )
    .addCase(
      streamWidgetEffects.followPost.fulfilled,
      (state: PostDetailState, action: PayloadAction<getStreamPostPayload>) => {
        const { error, postid } = action.payload;

        if (!error && state.post[postid]) {
          state.post[postid].follow = STATUS_FOLLOWING;
        }
      },
    )
    .addCase(
      streamWidgetEffects.unfollowPost.fulfilled,
      (state: PostDetailState, action: PayloadAction<getStreamPostPayload>) => {
        const { error, postid } = action.payload;

        if (!error && state.post[postid]) {
          state.post[postid].follow = STATUS_UNFOLLOWING;
        }
      },
    )
    .addCase(
      streamWidgetEffects.savePost.fulfilled,
      (state: PostDetailState, action: PayloadAction<getStreamPostPayload>) => {
        const { error, postid } = action.payload;

        if (!error && state.post[postid]) {
          state.post[postid].saved = STATUS_SAVED;
        }
      },
    )
    .addCase(
      streamWidgetEffects.unsavePost.fulfilled,
      (state: PostDetailState, action: PayloadAction<getStreamPostPayload>) => {
        const { error, postid } = action.payload;

        if (!error && state.post[postid]) {
          state.post[postid].saved = STATUS_UNSAVED;
        }
      },
    )
    .addCase(
      streamWidgetEffects.postVotePolling.fulfilled,
      (state: PostDetailState, action: PayloadAction<CommonPayload, any, any>) => {
        const { data, error } = action.payload;
        const { postid } = action.meta.arg;

        if (!error && state.post[postid]) {
          state.post[postid].polling = [data];
        }
      },
    )
    .addCase(
      streamWidgetEffects.postVoteTargetPrice.fulfilled,
      (state: PostDetailState, action: PayloadAction<CommonPayload, any, any>) => {
        const { data, error } = action.payload;
        const { postid } = action.meta.arg;
        if (!error && state.post[postid]) {
          state.post[postid].target_price = data?.target_price;
        }
      },
    )
    // Update likes count after get stream likers
    .addCase(
      streamWidgetEffects.getStreamLikers.fulfilled,
      (state: PostDetailState, action: PayloadAction<any>) => {
        const { data, postid, error } = action.payload;

        if (!error && state.post[postid]) {
          state.post[postid].likes = data?.likers?.length;
        }
      },
    )

    // Update stream if updated
    .addCase(
      streamEffects.updatePostStream.fulfilled,
      (state: PostDetailState, action: PayloadAction<any, any, any>) => {
        const { data, error } = action.payload;
        const currentPost = state.post[data?.postid];
        // @ts-ignore
        if (data && !error && currentPost) {
          state.post[data?.postid] = {
            ...currentPost,
            ...data,
          };
        }
      },
    );
};

const postDetailSlice = createSlice({
  name: 'postDetail',
  initialState,
  reducers,
  extraReducers,
});

export default postDetailSlice;
