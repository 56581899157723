import { combineReducers } from 'redux';
import bandarDetectorSlice from './SideWidget/BandarDetector/slice';

import hotlistSlice from './SideWidget/Hotlist/slice';
import priceAlertSlice from './SideWidget/PriceAlert/slice';
import valuationSlice from './SideWidget/Valuation/slice';
import watchlistReducer from './SideWidget/Watchlist/reducer';

import rootSlice from './slice';

const sideWidgetReducer = combineReducers({
  hotlist: hotlistSlice.reducer,
  priceAlert: priceAlertSlice.reducer,
  valuation: valuationSlice.reducer,
  root: rootSlice.reducer,
  bandarDetector: bandarDetectorSlice.reducer,
  watchlist: watchlistReducer,
});

export default sideWidgetReducer;
