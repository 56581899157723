import { FLIPT_NAMESPACE } from './constants/env';
import { EvaluationPayload } from './types/api';

export const createEntityID = (userId: string) => `web-${userId}`;

export const createEvaluationPayload = (
  userId: string,
  flagKey: string,
  namespaceKey?: string,
): EvaluationPayload => ({
  context: {
    user_id: userId,
  },
  entityId: createEntityID(userId),
  flagKey,
  namespaceKey: namespaceKey ?? FLIPT_NAMESPACE,
});
