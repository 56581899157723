import create from 'zustand';
import useUIConfigStore from 'stores/useUIConfigStore';
import { FeatureFlagKeys } from '../constants/flagKeys';
import { evaluateBoolean, evaluateVariant } from '../endpoints';

interface State {
  userId?: string;
}

interface Actions {
  setUserId: (userId: number | string) => void;
  loadBooleanEvaluation(
    flagKey: FeatureFlagKeys,
    namespaceKey?: string,
  ): Promise<boolean>;
  loadVariantEvaluation(flagKey: FeatureFlagKeys, namespaceKey: string);
}

const useFliptStore = create<Actions & State>((set, get) => ({
  setUserId(userId) {
    set({ userId: String(userId) });
  },

  async loadBooleanEvaluation(flagKey, namespaceKey) {
    try {
      const { userId } = get();
      if (!userId) {
        // Return a rejected promise if user id is not found
        // to prevent the function from continuing
        Promise.reject(new Error('user id is not found'));
        return false;
      }
      const { getUIValue, setUIValue } = useUIConfigStore.getState();

      const featureFlags = getUIValue('featureFlags');
      // immidiate return if feature flag value is already exists
      if (featureFlags && featureFlags[flagKey]) {
        return featureFlags[flagKey].isEnabled;
      }
      const namespace = namespaceKey ?? 'transaction';
      const response = await evaluateBoolean(userId, flagKey, namespace);
      setUIValue('featureFlags', (draft) => {
        draft[flagKey] = {
          isEnabled: response.data.enabled,
        };
      });

      return response.data.enabled;
    } catch (error) {
      Promise.reject(error);
      // default behavior
      return false;
    }
  },

  async loadVariantEvaluation(flagKey, namespaceKey) {
    try {
      const { userId } = get();
      if (!userId) {
        // Return a rejected promise if user id is not found
        // to prevent the function from continuing
        Promise.reject(new Error('user id is not found'));
        return false;
      }
      const { getUIValue, setUIValue } = useUIConfigStore.getState();
      // immidiate return if feature flag value is already exists
      const response = await evaluateVariant(userId, flagKey, namespaceKey);
      setUIValue('featureFlags', (draft) => {
        draft[flagKey] = {
          variance: response.data,
        };
      });
      return response.data;
    } catch (error) {
      Promise.reject(error);
      // default behavior
      return false;
    }
  },
}));

export default useFliptStore;
