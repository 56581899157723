/* eslint-disable @typescript-eslint/no-use-before-define */
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import produce from 'immer';
import debounce from 'lodash.debounce';

import {
  WebsocketRequest,
  WebsocketResponse,
} from '@stockbitgroup/protos/platform/websocket/wsevent/v1/websocket_pb';

import securitiesLocalStorage from 'utils/securitiesLocalStorage';
import { isClient } from 'constants/app';

import { useSecuritiesStore } from './store/securities.store';
import { ChannelPayload } from './store/securities.store.types';

import { SecuritiesContext } from './SecuritiesContext';
import useUnauthorized from './hooks/useUnauthorized';

import {
  RECONNECT_INTERVAL,
  HEARTBEAT_INTERVAL,
  MAX_RECONNECT_ATTEMPTS,
  MAX_POLLING_ATTEMPTS,
  HEARTBEAT_FACTOR,
  UPTIME,
  ReadyState,
  NetworkStatus,
} from './constants';

import { createPingPayload, generateWSCookie } from './utils';

import {
  SecuritiesContextValue,
  SecuritiesProviderProps,
  SetSecuritiesAction,
  RemoveSecuritiesAction,
} from './SecuritiesProvider.types';

const WS_URL = process.env.NEXT_PUBLIC_GEN_WEBSOCKET;

const SecuritiesProvider: FC<SecuritiesProviderProps> = (props) => {
  const {
    children,
    isLoggedIn,
    heartbeatInterval = HEARTBEAT_INTERVAL,
  } = props;

  const socketRef = useRef<WebSocket>();
  const lastPing = useRef<number>(Date.now());
  const lastPong = useRef<number>(Date.now());
  const actionsRef = useRef({});
  const isLoggedInRef = useRef<boolean>(false);
  const wsIsConnectingRef = useRef<boolean>(false);
  const reconnectCounterRef = useRef<number>(1);
  const unauthorizedCounterRef = useRef<number>(1);
  const reconnectTimeoutRef = useRef<NodeJS.Timeout>();
  const pingTimeoutRef = useRef<NodeJS.Timeout>();
  const healthCheckInterval = useRef<NodeJS.Timeout>();

  const [authToken, setAuthToken] = useState<string>();
  const [readyState, setReadyState] = useState<ReadyState>(
    ReadyState.UNINSTANTIATED,
  );
  const [networkStatus, setNetworkStatus] = useState<NetworkStatus>(
    NetworkStatus.ONLINE,
  );

  const {
    channel,
    isReconnectionFailed,
    setChannel,
    resetChannel,
    setIsReconnectionFailed,
    setIsSocketOnline,
  } = useSecuritiesStore((state) => ({
    channel: state.channel,
    isReconnectionFailed: state.isReconnectionFailed,
    setChannel: state.setChannel,
    resetChannel: state.resetChannel,
    setIsReconnectionFailed: state.setIsReconnectionFailed,
    setIsSocketOnline: state.setIsSocketOnline,
  }));

  const { checkUnauthorized } = useUnauthorized();

  const setCookie = useCallback((value) => {
    if (isClient()) {
      document.cookie = generateWSCookie(value);
    }
  }, []);

  const setAction: SetSecuritiesAction = (channelKey, callbackFn) => {
    actionsRef.current = produce(actionsRef.current, (draft) => {
      draft[channelKey] = callbackFn;
    });
  };

  const removeAction: RemoveSecuritiesAction = (channelKey) => {
    actionsRef.current = produce(actionsRef.current, (draft) => {
      if (draft?.[channelKey]) {
        delete draft[channelKey];
      }
    });
  };

  const isWebsocketNotReady = useCallback(() => {
    const socket = socketRef.current;

    // If no socket instance or WebSocket isn't open or still connecting
    return !socket || socket.readyState !== WebSocket.OPEN;
  }, [socketRef.current?.readyState]);

  const getSecuritiesAccessToken = useCallback(() => {
    if (isClient()) {
      const securitiesToken = securitiesLocalStorage.getSecuritiesToken();

      return securitiesToken.securitiesAccessToken;
    }
    return undefined;
  }, []);

  const sendPingHeartbeat = useCallback(() => {
    if (isWebsocketNotReady()) return;

    const pingRequest = createPingPayload();
    const bufferPing = new WebsocketRequest({
      requests: [pingRequest],
    }).toBinary();

    socketRef.current.send(bufferPing);
    lastPing.current = Date.now();
  }, []);

  const combinedChannel: ChannelPayload[] = useMemo(() => {
    const resultArray = Object.values(channel);

    return resultArray;
  }, [channel]);

  const sendMessageChannel = useCallback((channelRequests) => {
    if (isWebsocketNotReady() || channelRequests.length === 0) {
      return;
    }

    const buffer = new WebsocketRequest({
      requests: channelRequests,
    }).toBinary();

    socketRef.current.send(buffer);

    // remove channel that has been unsubscribed
    const isUnsubscribed = channelRequests.some(
      (channelRequest: ChannelPayload) =>
        channelRequest?.action?.case === 'unsubscribe',
    );

    if (isUnsubscribed) {
      resetChannel();
    }
  }, []);

  const handleSendChannel: typeof sendMessageChannel = useCallback(
    debounce(sendMessageChannel, 100),
    [],
  );

  const handleMessage = useCallback((message: MessageEvent<ArrayBuffer>) => {
    const dataRaw = WebsocketResponse.fromBinary(new Uint8Array(message.data));

    const { response } = dataRaw;
    const { case: messageCase, value } = response;

    if (messageCase === 'ping') {
      lastPong.current = Date.now();
    }

    const channelAction = actionsRef.current[messageCase];
    if (channelAction) {
      channelAction(value);
    }
  }, []);

  const handleHeartbeatMessage = () => {
    pingTimeoutRef.current = setTimeout(
      () => sendPingHeartbeat(),
      heartbeatInterval,
    );
  };

  const handleOpen = () => {
    if (!socketRef.current) return;

    setAction('ping', handleHeartbeatMessage);
    sendPingHeartbeat();
    setIsSocketOnline(true);
    setReadyState(ReadyState.OPEN);

    socketRef.current.addEventListener('message', handleMessage);

    // Reset all reconnection state
    reconnectCounterRef.current = 1;
    setIsReconnectionFailed(false);
    if (reconnectTimeoutRef.current) {
      clearTimeout(reconnectTimeoutRef.current);
      reconnectTimeoutRef.current = undefined;
    }
  };

  const handleReconnectionFailed = useCallback(() => {
    if (
      networkStatus === NetworkStatus.OFFLINE ||
      reconnectCounterRef.current > MAX_POLLING_ATTEMPTS
    ) {
      return;
    }

    reconnectTimeoutRef.current = setTimeout(() => {
      restartSocket();
    }, RECONNECT_INTERVAL);
  }, [networkStatus]);

  const handleClose = () => {
    setIsSocketOnline(false);
    setReadyState(ReadyState.CLOSED);

    if (!isLoggedInRef.current) return;

    if (reconnectCounterRef.current <= MAX_RECONNECT_ATTEMPTS) {
      restartSocket();
      reconnectCounterRef.current += 1;
      return;
    }

    setIsReconnectionFailed(true);
    handleReconnectionFailed();
    reconnectCounterRef.current += 1;
  };

  const handleError = async () => {
    setIsSocketOnline(false);
    setReadyState(ReadyState.CLOSED);

    const token = getSecuritiesAccessToken();
    let isUnauthorized = false;

    if (
      token &&
      networkStatus === NetworkStatus.ONLINE &&
      unauthorizedCounterRef.current <= MAX_RECONNECT_ATTEMPTS
    ) {
      isUnauthorized = await checkUnauthorized(WS_URL, token);
      unauthorizedCounterRef.current += 1;
    }

    if (isUnauthorized) {
      resetSocket();
      resetState();
      isLoggedInRef.current = false;
    }
  };

  const initSocket = useCallback(() => {
    if (
      !isLoggedInRef.current ||
      networkStatus === NetworkStatus.OFFLINE ||
      readyState === ReadyState.OPEN ||
      (wsIsConnectingRef.current && isReconnectionFailed)
    ) {
      return;
    }

    setAuthToken(undefined);

    const key = getSecuritiesAccessToken();

    if (!key || !WS_URL) return;

    setAuthToken(key);
    setCookie(key);

    wsIsConnectingRef.current = true;
    socketRef.current = new WebSocket(WS_URL);
    socketRef.current.binaryType = 'arraybuffer';
    // @ts-ignore
    window.securitiesSocket = socketRef.current;

    socketRef.current.addEventListener('open', handleOpen, { once: true });
    socketRef.current.addEventListener('close', handleClose, { once: true });
    socketRef.current.addEventListener('error', handleError, { once: true });

    setTimeout(() => {
      wsIsConnectingRef.current = false;
    }, UPTIME);
  }, [
    networkStatus,
    readyState,
    wsIsConnectingRef.current,
    isReconnectionFailed,
    handleOpen,
    handleClose,
    handleError,
  ]);

  const resetTimeout = useCallback(() => {
    if (pingTimeoutRef.current) {
      clearTimeout(pingTimeoutRef.current);
      pingTimeoutRef.current = undefined;
    }

    if (reconnectTimeoutRef.current) {
      clearTimeout(reconnectTimeoutRef.current);
      reconnectTimeoutRef.current = undefined;
    }

    if (healthCheckInterval.current) {
      clearInterval(healthCheckInterval.current);
      healthCheckInterval.current = undefined;
    }
  }, [pingTimeoutRef, reconnectTimeoutRef, healthCheckInterval]);

  const resetSocket = useCallback(() => {
    if (!socketRef.current) return;

    if (socketRef.current?.readyState === WebSocket.OPEN) {
      socketRef.current.close();
    }

    socketRef.current = undefined;

    setAuthToken(undefined);

    removeAction('ping');
    resetTimeout();
  }, [setAuthToken, removeAction, resetTimeout]);

  const restartSocket = useCallback(() => {
    if (!isLoggedInRef.current) return;

    resetSocket();
    initSocket();
  }, [resetSocket, initSocket]);

  const resetState = useCallback(() => {
    setIsReconnectionFailed(false);
    setAuthToken(undefined);
    setCookie(undefined);
    setReadyState(ReadyState.UNINSTANTIATED);

    if (isLoggedInRef.current) {
      resetChannel();
      isLoggedInRef.current = false;
    }

    socketRef.current = undefined;
    reconnectCounterRef.current = 1;
    unauthorizedCounterRef.current = 1;

    resetTimeout();
  }, [
    setIsReconnectionFailed,
    setAuthToken,
    setCookie,
    isLoggedInRef,
    reconnectCounterRef,
    unauthorizedCounterRef,
    resetTimeout,
  ]);

  const handleSocket = useCallback(
    (isLogin: boolean) => {
      if (!socketRef.current && isLogin) {
        restartSocket();
      } else if (socketRef.current && !isLogin) {
        resetSocket();
      }

      if (!isLogin) {
        resetState();
      }
    },
    [restartSocket, resetSocket, resetState],
  );

  // Handle Send Channel
  useEffect(() => {
    if (authToken && combinedChannel) {
      handleSendChannel(combinedChannel);
    }
  }, [authToken, combinedChannel, handleSendChannel]);

  // Handle Socket Connection
  useEffect(() => {
    isLoggedInRef.current = isLoggedIn;

    const timeout = setTimeout(() => {
      handleSocket(isLoggedIn);
    }, 300);

    return () => clearTimeout(timeout);
  }, [isLoggedIn, handleSocket]);

  // Handle Online Offline Connection
  useEffect(() => {
    const handleOnline = () => {
      setNetworkStatus(NetworkStatus.ONLINE);

      if (
        socketRef.current?.readyState === ReadyState.CLOSED ||
        readyState === ReadyState.CLOSED
      ) {
        restartSocket();
      }
    };

    const handleOffline = () => {
      setNetworkStatus(NetworkStatus.OFFLINE);

      if (socketRef.current?.readyState === ReadyState.OPEN) {
        resetSocket();
      }
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  // Handle Reconnect Interval
  useEffect(() => {
    if (readyState !== ReadyState.OPEN) return undefined;

    healthCheckInterval.current = setInterval(() => {
      const intervalPingPong = lastPing.current - lastPong.current;

      // Check if connection is unhealthy
      if (
        networkStatus === NetworkStatus.ONLINE &&
        intervalPingPong > heartbeatInterval * HEARTBEAT_FACTOR
      ) {
        restartSocket();
      }
    }, heartbeatInterval / 2);

    return () => {
      clearInterval(healthCheckInterval.current);
    };
  }, [restartSocket]);

  const contextValue: SecuritiesContextValue = useMemo(
    () => ({
      isAuthorized: !!authToken,
      token: authToken,
      socket: socketRef.current,
      setChannel,
      setAction,
      removeAction,
    }),
    [authToken, socketRef, setChannel, setAction, removeAction],
  );

  return (
    <SecuritiesContext.Provider value={contextValue}>
      {children}
    </SecuritiesContext.Provider>
  );
};

export default SecuritiesProvider;
