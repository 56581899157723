import color from '@deprecated-ui/_style/color';
import { css } from 'styled-components';

const alertStyles = css`
  .sysmsg-alert {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;

    .anticon {
      display: none;
    }

    & > div {
      background: transparent;
      padding: 0;
    }

    & > div > div > span > {
      .message-success {
        background: #e1f6e5;
        color: ${color.black};
      }

      .message-error {
        background: ${color.alertRed};
        color: ${color.black};
      }

      .message-warning {
        background: ${color.alertOrange};
        color: ${color.black};
      }

      .message-info {
        background: ${color.alertBlue};
        color: ${color.black};
      }

      .alert-default {
        border-radius: 5px;
        padding: 10px 15px;
      }

      .alert-snackbar {
        font-family: var(--font-bold);
        font-weight: 600;
        text-align: left;
        line-height: 1.15;
        font-size: 12px;
        border-radius: 4px;
        padding: 15px 20px;
        max-width: 317px;

        span {
          margin: 0 12px;
        }
      }

      .alert-plain,
      .alert-plain-default {
        font-family: var(--font-regular);
        font-size: 14px;
        border-radius: 2px;
        padding: 8px 10px;
        line-height: 16.1px;
        max-width: 300px;
      }

      .alert-plain.message-success {
        background: ${color.primary};
        color: ${color.light};
      }

      .alert-plain.message-error {
        background: ${color.valencia};
        color: ${color.light};
      }

      .alert-plain.message-success {
        background: ${color.primary};
        color: ${color.light};
      }

      .alert-plain.message-error {
        background: ${color.valencia};
        color: ${color.light};
      }

      .alert-snackbar-dark {
        font-family: var(--font-bold);
        font-weight: 600;
        text-align: left;
        line-height: 1.15;
        font-size: 12px;
        border-radius: 2px;
        padding: 13px 16px;
        max-width: 317px;

        span {
          margin: 0 12px;
        }

        background: #3B3B3B;
        color: ${color.light};
      }
    }
  }
`;

export default alertStyles;
