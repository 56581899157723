import {
  createSlice,
  createSelector,
  createAsyncThunk,
  SerializedError,
  ActionReducerMapBuilder,
  CaseReducer,
  PayloadAction,
} from '@reduxjs/toolkit';

import watchlist from 'lib/api/watchlist';
import company from 'lib/api/company';
import auth from 'utils/auth';
import { Company } from '../../../../../@types/company';

import { effects as companyWatchlistEffects } from './CompanyWatchlist/slice';

type listType = 'company' | 'saved';

interface SliceState {
  error: SerializedError;
  isLoading: boolean;
  showList: listType;
  suggestions: Array<Company>;
  watchlistId: string;
}

// initial state
export const initialState: SliceState = {
  error: null,
  isLoading: false,
  showList: 'company',
  suggestions: [],
  watchlistId: null,
};

// constants
const CONTEXT = '@sidewidget/Allwatchlist';

const actionType = {
  GET_COMPANY_SUGGESTION: `${CONTEXT}/GET_COMPANY_SUGGESTION`,
  SAVE_COMPANY_TO_WATCHLIST: `${CONTEXT}/SAVE_COMPANY_TO_WATCHLIST`,
};

// Selectors
const selectSelf = (state: any): SliceState => state.mainLayout.watchlist.root;
export const selectors = createSelector(selectSelf, (state) => state);

export const wlIdSelector = createSelector(
  (state) => state.mainLayout.watchlist.root.watchlistId,
  (state) => state,
);

// Async Actions / Side Effects

export const effects = {
  getCompanySuggestions: createAsyncThunk<
    { data: Array<Company> }, // return type
    { keyword: string, page: number, watchlist_id: number }, // payload
    {} // thunk api type
  >(actionType.GET_COMPANY_SUGGESTION, async ({ ...params }) => {
    const response = await company.getSearchCompany(params);

    if (!response.data) {
      throw new Error('Attempt to fetch suggestion company failed');
    }

    const { data, error } = response.data;

    if (error) {
      throw new Error(error);
    }

    return { data: data.companies };
  }),

  saveCompanyToWatchlist: createAsyncThunk<
    {}, // return type
    { watchlistId: string; companyid: string | number | null }, // payload
    {} // thunk api type
  >(
    actionType.SAVE_COMPANY_TO_WATCHLIST,
    async ({ watchlistId, companyid }, { dispatch }) => {
      // @ts-ignore
      const { watchlist_id: watchlistIdCookie } = auth.getUserAccess();
      const targetWathclistId = watchlistId || watchlistIdCookie;

      if (!companyid) {
        throw new Error('Company id is not provided');
      }

      const response = await watchlist.postFollowCompany(
        targetWathclistId,
        companyid,
      );

      if (!response.data) {
        throw new Error('Attempt to follow company failed');
      }

      const { data, error_type: error } = response.data;

      if (error) {
        throw new Error(error);
      }

      // re-fetch selected watchlist contents
      dispatch(companyWatchlistEffects.getCompanyWatchlist({ watchlistId }));

      return { data };
    },
  ),
};

// Reducer
type SliceReducers = {
  toggleViewType: CaseReducer<
    SliceState,
    PayloadAction<{ listType: listType }>
  >;
  setCurrentwatchlistId: CaseReducer<
    SliceState,
    PayloadAction<{ watchlistId: string }>
  >;
};

const reducers: SliceReducers = {
  toggleViewType: (draft, action) => {
    draft.showList = action.payload.listType;
  },

  setCurrentwatchlistId: (draft, action) => {
    draft.watchlistId = action.payload.watchlistId;
  },
};

// Extra Reducer for async actions
const extraReducers = (builder: ActionReducerMapBuilder<SliceState>) => {
  builder
    // Extra reducers for fetch suggestions
    .addCase(effects.getCompanySuggestions.pending, (draft) => {
      draft.isLoading = true;
    })
    .addCase(effects.getCompanySuggestions.fulfilled, (draft, action) => {
      draft.isLoading = false;
      draft.suggestions = action.payload.data;
    })
    .addCase(effects.getCompanySuggestions.rejected, (draft, action) => {
      draft.isLoading = false;
      draft.error = action.error;
    })
    // Extra reducers for save company to watchlist
    .addCase(effects.saveCompanyToWatchlist.pending, (draft) => {
      draft.isLoading = true;
    })
    .addCase(effects.saveCompanyToWatchlist.fulfilled, (draft) => {
      draft.isLoading = false;
    })
    .addCase(effects.saveCompanyToWatchlist.rejected, (draft, action) => {
      draft.isLoading = false;
      draft.error = action.error;
    });
};

// Slice
const allWatchlistSlice = createSlice({
  name: 'watchlist',
  initialState,
  reducers,
  extraReducers,
});

export default allWatchlistSlice;
