import { createSlice, createSelector } from '@reduxjs/toolkit';
import { ORDER_TYPE } from 'constants/trading';

// initial state
const initialState = {
  visible: false,
  symbol: null,
  price: null,
  orderType: ORDER_TYPE.BUY,
};

const reducers = {
  showModal: (draft, action) => {
    draft.visible = true;
    draft.symbol = action.payload.symbol;
    draft.price = action.payload.price;
    draft.orderType = action.payload.type;
  },
  resetModal: (draft) => {
    draft.visible = false;
    draft.symbol = null;
    draft.orderType = ORDER_TYPE.BUY;
  },
  setOrderType: (draft, action) => {
    draft.orderType = action.payload;
  },
};

// Selectors
export const selectors = createSelector(
  (state) => ({
    visible: state.orderbookModal.visible,
    symbol: state.orderbookModal.symbol,
    price: state.orderbookModal.price,
    orderType: state.orderbookModal.orderType,
  }),
  (state) => state,
);

// Slice
const rootSlice = createSlice({
  name: '@orderbook/trading-modal',
  initialState,
  reducers,
  extraReducers: {},
});

export default rootSlice;
