import {
  ActionReducerMapBuilder,
  AsyncThunk,
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import alertMessage from 'global/AlertMessage';
import watchlist from 'lib/api/watchlist';

// global types
import { Watchlist } from '../../../../@types/watchlist';

// utils
import rootSelector from '../selectors';

// local types
import { CollectionSliceState, ModifyModalType, SliceReducer } from '../types';

// constants
const SLICE_NAME = 'collection';

// initial state
const initialState: CollectionSliceState = {
  available: [],
  isDropdownOpen: false,
  isFormOpen: false,
  isCollectionLoading: false,
  error: null,
  needRefresh: false,
  selectedForModify: null,
  modalVisible: 'none',
};

// Reducer
type SliceReducers = {
  handleSetDropdown: SliceReducer<CollectionSliceState, { isOpen: boolean }>;
  handleSetFormOpen: SliceReducer<CollectionSliceState, { isOpen: boolean }>;
  handleSelectForModify: SliceReducer<
    CollectionSliceState,
    { selectedIndex: number; modalType: ModifyModalType }
  >;
};

const reducers: SliceReducers = {
  handleSetDropdown: (draft, action) => {
    draft.isDropdownOpen = action.payload.isOpen;
  },
  handleSetFormOpen: (draft, action) => {
    draft.isFormOpen = action.payload.isOpen;
  },
  handleSelectForModify: (draft, action) => {
    draft.selectedForModify = draft.available[action.payload.selectedIndex];
    draft.modalVisible = action.payload.modalType;
    draft.isDropdownOpen = false;
  },
};

// thunk actions
type Thunks = {
  createCompanyWatchlist: AsyncThunk<
    { data: Watchlist }, // return types
    { name: string; desc?: string }, // payload
    {} // thunk api
  >;
  fetchSavedCompanyWatchlist: AsyncThunk<{ data: Watchlist[] }, {}, {}>;
  editCompanyWatchlist: AsyncThunk<
    { data: Watchlist }, // return types
    { name: string; desc?: string; id: string }, // payload
    {} // thunk api
  >;
  deleteCompanyWatchlist: AsyncThunk<
    { data: Watchlist; id: string }, // return types
    { id: string }, // payload
    {} // thunk api
  >;
};

export const thunks: Thunks = {
  createCompanyWatchlist: createAsyncThunk(
    `${SLICE_NAME}/CREATE_NEW_COMPANY_WATCHLIST`,
    async ({ name, desc }) => {
      const response = await watchlist.createNewWatchlist(name, desc);

      if (!response.data) {
        throw new Error('Attempt to add watchlist failed!');
      }

      const { error_type: error, data } = response.data;

      if (error) {
        throw new Error(error);
      }

      alertMessage({
        messageType: 'success',
        content: 'New watchlist group is added',
        alertType: 'plain',
      });

      return { data };
    },
  ),
  fetchSavedCompanyWatchlist: createAsyncThunk(
    `${SLICE_NAME}/FETCH_SAVED_COMPANY_WATCHLIST`,
    async () => {
      const response = await watchlist.getAllWatchlist();

      if (!response.data) {
        throw new Error('Attempt to get saved watchlist failed!');
      }

      const { data, error_type: error } = response.data;
      if (error) {
        throw new Error(error);
      }

      return { data };
    },
  ),
  editCompanyWatchlist: createAsyncThunk(
    `${SLICE_NAME}/EDIT_SELECTED_WATCHLIST`,
    async ({ name, desc, id }) => {
      const response = await watchlist.updateWatchlist(id, name, desc);

      if (!response.data) {
        throw new Error('Attempt to update saved watchlist failed!');
      }

      const { error_type: error, data } = response.data;

      if (error) {
        throw new Error(error);
      }

      alertMessage({
        messageType: 'success',
        content: 'Your stock watchlist is updated',
        alertType: 'plain',
      });

      return { data };
    },
  ),
  deleteCompanyWatchlist: createAsyncThunk(
    `${SLICE_NAME}/DELETE_SELECTED_WATCHLIST`,
    async ({ id }) => {
      const response = await watchlist.deleteWatchlistById(id);

      if (!response.data) {
        throw new Error('Attempt to delete saved watchlist failed!');
      }

      const { error_type: error, data, message } = response.data;

      if (error) {
        throw new Error(error);
      }

      alertMessage({
        messageType: 'success',
        content: message,
        alertType: 'plain',
      });

      return { data, id };
    },
  ),
};

// Thunk reducers
const extraReducers = (
  builder: ActionReducerMapBuilder<CollectionSliceState>,
) => {
  // fetchSavedCompanyWatchlist
  builder
    .addCase(thunks.fetchSavedCompanyWatchlist.pending, (draft) => {
      draft.isCollectionLoading = true;
      draft.needRefresh = false;
    })
    .addCase(thunks.fetchSavedCompanyWatchlist.fulfilled, (draft, action) => {
      draft.isCollectionLoading = false;
      draft.available = action.payload.data;
    })
    .addCase(thunks.fetchSavedCompanyWatchlist.rejected, (draft, action) => {
      draft.isCollectionLoading = false;
      draft.error = action.error;
    })
    // createCompanyWatchlist
    .addCase(thunks.createCompanyWatchlist.pending, (draft) => {
      draft.isCollectionLoading = true;
    })
    .addCase(thunks.createCompanyWatchlist.fulfilled, (draft, action) => {
      const newWatchlist = action.payload.data;

      if (newWatchlist) {
        draft.available.push(newWatchlist);
      }

      draft.isCollectionLoading = false;
      draft.needRefresh = false;
      draft.isFormOpen = false;
    })
    .addCase(thunks.createCompanyWatchlist.rejected, (draft, action) => {
      draft.isCollectionLoading = false;
      draft.error = action.error;
    })
    // editCompanyWatchlist
    .addCase(thunks.editCompanyWatchlist.pending, (draft) => {
      draft.isCollectionLoading = true;
    })
    .addCase(thunks.editCompanyWatchlist.fulfilled, (draft) => {
      draft.isCollectionLoading = false;
      draft.selectedForModify = null;
      draft.modalVisible = 'none';
      draft.needRefresh = true;
    })
    .addCase(thunks.editCompanyWatchlist.rejected, (draft, action) => {
      draft.isCollectionLoading = false;
      draft.error = action.error;
    })
    // deleteCompanyWatchlist
    .addCase(thunks.deleteCompanyWatchlist.pending, (draft) => {
      draft.isCollectionLoading = true;
    })
    .addCase(thunks.deleteCompanyWatchlist.fulfilled, (draft, action) => {
      const { id } = action.payload;

      const newList = [...draft.available];
      const idx = newList.findIndex(
        (item) => Number(item.watchlist_id) === Number(id),
      );

      if (idx > -1) {
        newList.splice(idx, 1);
        draft.available = newList;
      }

      draft.isCollectionLoading = false;
      draft.selectedForModify = null;
      draft.modalVisible = 'none';
      // draft.needRefresh = true;
    })
    .addCase(thunks.deleteCompanyWatchlist.rejected, (draft, action) => {
      draft.isCollectionLoading = false;
      draft.error = action.error;
    });
};

// selectors
export const selectors = createSelector(rootSelector, (state) => ({
  ...state.collection,
  activeWatchlistId: state.root.activeWatchlistId,
  activeWatchlistName: state.root.activeWatchlistName,
}));

const collectionSlice = createSlice({
  initialState,
  name: SLICE_NAME,
  reducers,
  extraReducers,
});

export default collectionSlice;
