import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

// APIs
import chartbitApi from 'lib/api/chartbit';
import noticeError from 'utils/logger';
import { CommonPayload } from '../../../@types/action-payload';
import {
  DeleteTemplatePayload,
  LoadTemplatePayload,
} from './slice.types';

// Initial state
const initialState = {
  templates: [],
  loadedTemplate: {},
  initialData: {},
  isLoading: false,
  error: null,
  message: '',
};

type ChartbitState = typeof initialState;

// selectors
const chartbitSelector = (state) => state.chartbit;
export const selectors = createSelector(
  chartbitSelector,
  (chartbit: ChartbitState) => ({
    ...chartbit,
  }),
);

// Action types
const CONTEXT = '@feature/chartbit';

const actionType = {
  GET_TEMPLATES: `${CONTEXT}/GET_TEMPLATE`,
  SAVE_TEMPLATE: `${CONTEXT}/SAVE_TEMPLATE`,
  DELETE_TEMPLATE: `${CONTEXT}/DELETE_TEMPLATE`,
  LOAD_TEMPLATE: `${CONTEXT}/LOAD_TEMPLATE`,
  GET_INITIAL_DATA: `${CONTEXT}/GET_INITIAL_DATA`,
};

// Side effects
export const effects = {
  getTemplates: createAsyncThunk<CommonPayload>(
    actionType.GET_TEMPLATES,
    async () => {
      try {
        const response = await chartbitApi.getChartTemplate();

        if (!response.data) {
          throw new Error('Attempt to get chart template failed');
        }

        const { data, message, error } = response.data;

        if (error) {
          return { error, message };
        }

        return { data, message };
      } catch (error) {
        noticeError(error, { event: actionType.GET_TEMPLATES, place: 'chartbit/slice' });
        return { error };
      }
    },
  ),
  saveTemplate: createAsyncThunk<CommonPayload, { name: string; content: any }>(
    actionType.SAVE_TEMPLATE,
    async ({ name, content }) => {
      try {
        const response = await chartbitApi.saveChartTemplate(name, content);

        if (!response.data) {
          throw new Error('Attempt to save chart template failed');
        }

        const { error, message, data } = response.data;

        if (error) {
          return { error, message };
        }

        return { data, message };
      } catch (error) {
        noticeError(error, { event: actionType.SAVE_TEMPLATE, place: 'chartbit/slice' });
        return { error };
      }
    },
  ),
  deleteTemplate: createAsyncThunk<DeleteTemplatePayload, string>(
    actionType.DELETE_TEMPLATE,
    async (templateId) => {
      try {
        const response = await chartbitApi.deleteChartTemplate(templateId);

        if (!response.data) {
          throw new Error('Attempt to delete chart template failed');
        }

        const { error, message, data } = response.data;

        if (error) {
          return { error, message };
        }

        return { data, message, templateId };
      } catch (error) {
        noticeError(error, { event: actionType.DELETE_TEMPLATE, place: 'chartbit/slice' });
        return { error };
      }
    },
  ),
  loadTemplate: createAsyncThunk<
    LoadTemplatePayload,
    { symbol: string; templateId: string }
  >(actionType.LOAD_TEMPLATE, async ({ symbol, templateId }) => {
    try {
      const response = await chartbitApi.loadChartTemplate(templateId, symbol);

      if (!response.data) {
        throw new Error('Attempt to load template failed');
      }

      const { error, message, data } = response.data;

      if (error) {
        return { error, message };
      }

      return { data, message, templateId };
    } catch (error) {
      noticeError(error, { event: actionType.LOAD_TEMPLATE, place: 'chartbit/slice' });
      return { error };
    }
  }),
};

// Reducers
const reducers = {};

const extraReducers = (builder) => {
  builder
    .addCase(effects.getTemplates.pending, (state: ChartbitState) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(
      effects.getTemplates.fulfilled,
      (state: ChartbitState, action: PayloadAction<CommonPayload>) => {
        const { data, error, message } = action.payload;

        if (error) {
          state.error = error;
        } else {
          state.templates = data;
          state.error = null;
        }
        state.isLoading = false;
        state.message = message;
      },
    )
    // Save templates doesn't affects reducer, thus we don't need to add the case here
    .addCase(
      effects.deleteTemplate.fulfilled,
      (state: ChartbitState, action: PayloadAction<DeleteTemplatePayload>) => {
        const { error, message, templateId } = action.payload;

        if (error) {
          state.error = error;
        } else {
          const newTemplates = [...state.templates];
          const templateIdx = newTemplates.findIndex(
            ({ id }) => id === templateId,
          );

          if (templateIdx > -1) {
            newTemplates.splice(templateIdx, 1);
            state.templates = newTemplates;
          }
        }

        state.message = message;
      },
    )
    .addCase(
      effects.loadTemplate.fulfilled,
      (state: ChartbitState, action: PayloadAction<LoadTemplatePayload>) => {
        const { error, message, templateId, data } = action.payload;

        if (error) {
          state.error = error;
        } else {
          state.loadedTemplate[templateId] = data;
        }

        state.message = message;
      },
    );
};

const chartbitSlice = createSlice({
  name: 'chartbit',
  initialState,
  reducers,
  extraReducers,
});

export default chartbitSlice;
