import { useState, useEffect } from 'react';
import { initWebsocketSocialV2 } from 'global/WebSocketV2';
import { useDispatch } from 'react-redux';

import { effects as wsV2Effects, getWskey } from 'global/WebSocketV2/slice';

import { socketV2Key } from 'features/chat/constant';

function useWebSocketSocialV2({ isLoggedIn }: { isLoggedIn: boolean }) {
  const [webSocketSocialV2, setWebSocketSocialV2Connection] = useState();
  const dispatch = useDispatch();

  const createSocialSocket = () =>
    getWskey().then((wskey) => {
      if (wskey !== null) {
        setWebSocketSocialV2Connection(
          initWebsocketSocialV2(socketV2Key.social, wskey),
        );
      }
    });

  useEffect(() => {
    if (!isLoggedIn) return;

    if (!webSocketSocialV2) {
      createSocialSocket();
    }

    dispatch(
      wsV2Effects.initWebsocketSocialV2({
        webSocketSocialV2,
        createSocialSocket,
      }),
    );
  }, [webSocketSocialV2, isLoggedIn]);

  return webSocketSocialV2;
}

export default useWebSocketSocialV2;
