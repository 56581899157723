import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import {
  ICompaniesSuggest,
  IEarnings,
  IScreenUniverse,
} from 'features/earnings/types';
import getEarnings from 'lib/api/earnings';
import ScreenerApi from 'lib/api/screener';
import searchApi from 'lib/api/search';

const CONTEXT = '@feature/earnings';

const actionType = {
  GET_SCREENER_UNIVERSE: `${CONTEXT}/GET_SCREENER_UNIVERSE`,
  GET_EARNINGS: `${CONTEXT}/GET_EARNINGS`,
  GET_SUGGESTED_COMPANY: `${CONTEXT}/GET_SUGGESTED_COMPANY`,
};

interface IEarningsState {
  screenerUniverse: IScreenUniverse;
  earings: IEarnings;
  suggestedCompanies: ICompaniesSuggest;
}

interface IParamsGetEarnings {
  page: string;
  order?: string;
  sortcol?: string;
  year?: string;
  quarter?: string;
  companySymbol?: string;
  filter?: string;
}

interface IParamsGetSuggestedCompanies {
  query: string;
}

const initialState: IEarningsState = {
  screenerUniverse: {
    data: {},
    message: undefined,
    error: undefined,
    isLoading: undefined,
  },
  earings: {
    data: {},
    message: undefined,
    error: undefined,
    isLoading: undefined,
  },
  suggestedCompanies: {
    data: {},
    error: undefined,
    isLoading: undefined,
    message: undefined,
  },
};

const earningsState = (state) => state.earnings;
export const earningsSelectors = createSelector(
  earningsState,
  (calendar: IEarningsState) => ({
    screenerUniverse: calendar.screenerUniverse,
    earnings: calendar.earings,
    suggestedCompanies: calendar.suggestedCompanies,
  }),
);

const reducers = {
  clearSuggestionCompany: (state: IEarningsState) => {
    state.suggestedCompanies.data.company = [];
  },
};

export const earningsEffects = {
  getScreenerUniverse: createAsyncThunk<any>(
    actionType.GET_SCREENER_UNIVERSE,
    async () => {
      try {
        const response = await ScreenerApi.getScreenerUniverse();
        if (!response.data) {
          throw new Error('Attempt get screener universe is failed');
        }
        const { data } = response;
        return data;
      } catch (error) {
        return { error };
      }
    },
  ),
  getEarnings: createAsyncThunk<any, IParamsGetEarnings>(
    actionType.GET_EARNINGS,
    async ({ page, order, sortcol, year, quarter, companySymbol, filter }) => {
      try {
        const response = await getEarnings({
          page,
          order,
          sortcol,
          year,
          quarter,
          companySymbol,
          filter,
        });

        if (!response.data) {
          throw new Error('Attempt get earnings data is failed');
        }
        const { data } = response;

        return { ...data, filter };
      } catch (error) {
        return { error };
      }
    },
  ),
  getsuggestedCompanies: createAsyncThunk<any, IParamsGetSuggestedCompanies>(
    actionType.GET_SUGGESTED_COMPANY,
    async ({ query }) => {
      try {
        const response = await searchApi.getSearchCompany(query);

        if (!response.data) {
          throw new Error('Attempt get suggested companies is failed');
        }
        const { data } = response;

        return data;
      } catch (error) {
        return { error };
      }
    },
  ),
};

const extraReducer = (builder) => {
  builder
    .addCase(
      earningsEffects.getScreenerUniverse.fulfilled,
      (state: IEarningsState, action: PayloadAction<IScreenUniverse>) => {
        const { message, data, error } = action.payload;

        if (error) {
          state.screenerUniverse.error = error;
        } else {
          state.screenerUniverse.data = data;
          state.screenerUniverse.message = message;
        }

        state.screenerUniverse.isLoading = false;
      },
    )
    .addCase(
      earningsEffects.getScreenerUniverse.pending,
      (state: IEarningsState) => {
        state.screenerUniverse.isLoading = true;
      },
    )
    .addCase(
      earningsEffects.getScreenerUniverse.rejected,
      (state: IEarningsState, action: PayloadAction<IScreenUniverse>) => {
        state.screenerUniverse.error = action.payload.error;
        state.screenerUniverse.isLoading = false;
      },
    )
    .addCase(
      earningsEffects.getEarnings.fulfilled,
      (state: IEarningsState, action: PayloadAction<IEarnings>) => {
        const { message, data, error, filter } = action.payload;

        if (error) {
          state.earings.error = error;
        } else {
          state.earings.data = { ...data, filter };
          state.earings.message = message;
        }

        state.earings.isLoading = false;
      },
    )
    .addCase(earningsEffects.getEarnings.pending, (state: IEarningsState) => {
      state.earings.isLoading = true;
    })
    .addCase(
      earningsEffects.getEarnings.rejected,
      (state: IEarningsState, action: PayloadAction<IEarnings>) => {
        state.earings.error = action.payload.error;
        state.earings.isLoading = false;
      },
    )
    .addCase(
      earningsEffects.getsuggestedCompanies.fulfilled,
      (state: IEarningsState, action: PayloadAction<ICompaniesSuggest>) => {
        const { message, data, error } = action.payload;

        if (error) {
          state.suggestedCompanies.error = error;
        } else {
          state.suggestedCompanies.data = data;
          state.suggestedCompanies.message = message;
        }

        state.suggestedCompanies.isLoading = false;
      },
    )
    .addCase(
      earningsEffects.getsuggestedCompanies.pending,
      (state: IEarningsState) => {
        state.suggestedCompanies.isLoading = true;
      },
    )
    .addCase(
      earningsEffects.getsuggestedCompanies.rejected,
      (state: IEarningsState, action: PayloadAction<ICompaniesSuggest>) => {
        state.suggestedCompanies.error = action.payload.error;
        state.suggestedCompanies.isLoading = false;
      },
    );
};

const earningsSlice = createSlice({
  name: 'earnings',
  initialState,
  // for non-async reducer logic
  reducers,
  // for async reducer logic
  extraReducers: extraReducer,
});

export default earningsSlice;
