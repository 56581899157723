/* eslint-disable no-undef */
import exodusInstance from 'lib/api/instances/exodus';

import { API_EXODUS_URL } from 'constants/api';
import { IItemOrderbookListItem } from 'features/orderbook/types';
import { normalizeToSeriesId } from 'features/chartbit/TradingView/utils';
import { APIPromiseResponse } from '../../../@types/api-response';
import {
  ChartbitLayoutData,
  ChartbitPriceRes,
  DailyPriceData,
  EventData,
  ITokenData,
  InitialTradingViewData,
  IntradayPriceData,
  SearchCompanyData,
} from '../../../@types/chartbit';

const requestExodus = exodusInstance.noAlert;

const CHARTBIT_URL = `${API_EXODUS_URL}/chartbit`;
const ORDERBOOK_URL = `${API_EXODUS_URL}/orderbook`;

const getSearchCompany = (
  query: string,
): APIPromiseResponse<SearchCompanyData> =>
  requestExodus.get(`${API_EXODUS_URL}/search`, {
    params: { keyword: query, type: 'company', page: 0 },
  });

const getInitialData = (
  symbol: string,
): APIPromiseResponse<InitialTradingViewData> =>
  requestExodus.get(`${CHARTBIT_URL}/initial/${symbol}`);

interface getPriceDataParams {
  symbol: string;
  from?: string;
  to?: string;
  minutes_multiplier?: number;
  type?: 'daily' | 'intraday';
}

const getDailyPriceData = ({
  symbol,
  from,
  to,
  ...props
}: getPriceDataParams): APIPromiseResponse<
  ChartbitPriceRes<DailyPriceData>
> => {
  let params: Record<string, any> = {};
  if (from && to) {
    params = {
      from,
      to,
      limit: 0,
    };
  }

  params = {
    ...params,
    ...props,
  };

  return requestExodus.get(`${CHARTBIT_URL}/${symbol}/price/daily`, {
    params,
  });
};

const getIntradayPriceData = ({
  symbol,
  from,
  to,
  ...props
}: getPriceDataParams): APIPromiseResponse<
  ChartbitPriceRes<IntradayPriceData>
> => {
  let params: Record<string, any> = {};
  if (from && to) {
    params = {
      from,
      to,
      limit: 0,
    };
  }

  params = {
    ...params,
    ...props,
  };

  return requestExodus.get(`${CHARTBIT_URL}/${symbol}/price/intraday`, {
    params,
  });
};

const getEvents = ({
  symbol,
  from,
  to,
}: getPriceDataParams): APIPromiseResponse<EventData> =>
  requestExodus.get(`${CHARTBIT_URL}/chart/corpaction`, {
    params: {
      from,
      to,
      symbol,
    },
  });

const getLayout = (symbol: string): APIPromiseResponse<ChartbitLayoutData> =>
  requestExodus.get(`${CHARTBIT_URL}/${symbol}/layout`);

const saveLayout = (
  symbol: string,
  drawing: Record<string, any>,
): APIPromiseResponse => {
  const normalizedDrawing = normalizeToSeriesId(JSON.stringify(drawing));
  return requestExodus.post(`${CHARTBIT_URL}/${symbol}/layout`, {
    content: normalizedDrawing,
  });
};

const getChartTemplate = (): APIPromiseResponse =>
  requestExodus.get(`${CHARTBIT_URL}/template`);

const loadChartTemplate = (
  id: number | string,
  symbol: string,
): APIPromiseResponse =>
  requestExodus.get(`${CHARTBIT_URL}/${symbol}/template/${id}`);

const saveChartTemplate = (
  name: string,
  content: Record<string, any>,
): APIPromiseResponse =>
  requestExodus.post(`${CHARTBIT_URL}/template`, {
    name,
    content: JSON.stringify(content),
  });

const deleteChartTemplate = (id: number | string): APIPromiseResponse =>
  requestExodus.delete(`${CHARTBIT_URL}/template/${id}`);

const getCustomIndicator = (params: any): APIPromiseResponse =>
  requestExodus.get(`${CHARTBIT_URL}/custom/indicator`, { params });

const getMobileTokenData = (
  token: string | string[],
): APIPromiseResponse<ITokenData> =>
  requestExodus.get(`${CHARTBIT_URL}/token/${token}/mobile`);

const getQuote = (symbol: string): APIPromiseResponse<IItemOrderbookListItem> =>
  requestExodus.get(`${ORDERBOOK_URL}/companies/${symbol}`);

const getVersion = (): APIPromiseResponse<{ is_new: boolean }> =>
  requestExodus.get(`${CHARTBIT_URL}/version`);

const getUserSettings = (): APIPromiseResponse =>
  requestExodus.get('/user-setting/configurations', {
    params: {
      user_setting_type: '1',
    },
  });

const saveUserSettings = ({
  content,
}: {
  content: string;
}): APIPromiseResponse =>
  requestExodus.post('/user-setting/configurations', {
    content,
    user_setting_type: 1,
  });

export default {
  getSearchCompany,
  getDailyPriceData,
  getIntradayPriceData,
  getInitialData,
  getEvents,
  getLayout,
  saveLayout,
  getChartTemplate,
  loadChartTemplate,
  saveChartTemplate,
  deleteChartTemplate,
  getCustomIndicator,
  getMobileTokenData,
  getQuote,
  getVersion,
  getUserSettings,
  saveUserSettings,
};
