import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from '@reduxjs/toolkit';

// APIs
import generalApi from 'lib/api/general';

// Initial State
const initialState = {
  searchGif: {
    data: [],
    totalCount: 0,
    isLoading: false,
    error: null,
    message: '',
  },
  trendingGif: {
    data: [],
    totalCount: 0,
    isLoading: false,
    error: null,
    message: '',
  },
};

// Selectors
export const selectors = createSelector(
  (state) => state.entities.general,
  (general) => ({
    searchGif: general.searchGif,
    trendingGif: general.trendingGif,
  }),
);

// Action type
const CONTEXT = '@redux/general';

const actionType = {
  GET_SEARCH_GIF: `${CONTEXT}/GET_SEARCH_GIF`,
  GET_TRENDING_GIF: `${CONTEXT}/GET_TRENDING_GIF`,
  LOAD_MORE_SEARCH_GIF: `${CONTEXT}/LOAD_MORE_SEARCH_GIF`,
  LOAD_MORE_TRENDING_GIF: `${CONTEXT}/LOAD_MORE_TRENDING_GIF`,
};

export const effects = {
  // search gif
  getSearchGif: createAsyncThunk(
    actionType.GET_SEARCH_GIF,
    async (keyword, offset = 0) => {
      try {
        const response = await generalApi.getSearchGif(keyword, offset);

        if (response.status >= 400) {
          throw new Error('Bad response from server');
        }

        const {
          data,
          meta: { msg: message, error_code: error },
          pagination: { total_count: totalCount },
        } = response.data;

        if (error) {
          return { message, error };
        }

        return { data, message, totalCount };
      } catch (error) {
        return { error: error.message };
      }
    },
  ),
  loadMoreSearchGif: createAsyncThunk(
    actionType.LOAD_MORE_SEARCH_GIF,
    async (keyword, offset = 0) => {
      try {
        const response = await generalApi.getSearchGif(keyword, offset);

        if (response.status >= 400) {
          throw new Error('Bad response from server');
        }

        const {
          data,
          meta: { msg: message, error_code: error },
        } = response.data;

        if (error) {
          return { message, error };
        }

        return { data, message };
      } catch (error) {
        return { error: error.message };
      }
    },
  ),

  // trending gif
  getTrendingGif: createAsyncThunk(
    actionType.GET_TRENDING_GIF,
    async (offset = 0) => {
      try {
        const response = await generalApi.getTrendingGif(offset);

        if (response.status >= 400) {
          throw new Error('Bad response from server');
        }

        const {
          data,
          meta: { msg: message, error_code: error },
          pagination: { total_count: totalCount },
        } = response.data;

        if (error) {
          return { message, error };
        }

        return { data, message, totalCount };
      } catch (error) {
        return { error: error.message };
      }
    },
  ),
  loadMoreTrendingGif: createAsyncThunk(
    actionType.LOAD_MORE_TRENDING_GIF,
    async (offset = 0) => {
      try {
        const response = await generalApi.getTrendingGif(offset);

        if (response.status >= 400) {
          throw new Error('Bad response from server');
        }

        const {
          data,
          meta: { msg: message, error_code: error },
        } = response.data;

        if (error) {
          return { message, error };
        }

        return { data, message };
      } catch (error) {
        return { error: error.message };
      }
    },
  ),
};

// Reducers
const reducers = {
  clearSearchGif: (state) => {
    state.searchGif = initialState.searchGif;
  },
};

// Extra reducers
const extraReducers = {
  // search gif
  [effects.getSearchGif.pending]: (state) => {
    state.searchGif.isLoading = true;
  },
  [effects.getSearchGif.fulfilled]: (state, action) => {
    const { data, message, error, totalCount } = action.payload;

    if (error) {
      state.searchGif.error = error;
    } else {
      state.searchGif.data = data;
    }

    state.searchGif.totalCount = totalCount;
    state.searchGif.message = message;
    state.searchGif.isLoading = false;
  },
  [effects.getSearchGif.rejected]: (state, action) => {
    state.searchGif.error = action.payload.error;
    state.searchGif.isLoading = false;
  },
  // load more search gif
  [effects.loadMoreSearchGif.pending]: (state) => {
    state.searchGif.isLoading = true;
  },
  [effects.loadMoreSearchGif.fulfilled]: (state, action) => {
    const { data, message, error } = action.payload;

    if (error) {
      state.searchGif.error = error;
    } else {
      const currentData = state.searchGif.data.map((i) => ({ ...i }));
      state.searchGif.data = [...currentData, ...data];
    }

    state.searchGif.message = message;
    state.searchGif.isLoading = false;
  },
  [effects.loadMoreSearchGif.rejected]: (state, action) => {
    state.searchGif.error = action.payload.error;
    state.searchGif.isLoading = false;
  },

  // trending gif
  [effects.getTrendingGif.pending]: (state) => {
    state.trendingGif.isLoading = true;
  },
  [effects.getTrendingGif.fulfilled]: (state, action) => {
    const { data, message, error, totalCount } = action.payload;

    if (error) {
      state.trendingGif.error = error;
    } else {
      state.trendingGif.data = data;
    }

    state.trendingGif.totalCount = totalCount;
    state.trendingGif.message = message;
    state.trendingGif.isLoading = false;
  },
  [effects.getTrendingGif.rejected]: (state, action) => {
    state.trendingGif.error = action.payload.error;
    state.trendingGif.isLoading = false;
  },
  // load more trending gif
  [effects.loadMoreTrendingGif.pending]: (state) => {
    state.trendingGif.isLoading = true;
  },
  [effects.loadMoreTrendingGif.fulfilled]: (state, action) => {
    const { data, message, error } = action.payload;

    if (error) {
      state.trendingGif.error = error;
    } else {
      const currentData = state.trendingGif.data.map((i) => ({ ...i }));
      state.trendingGif.data = [...currentData, ...data];
    }

    state.trendingGif.message = message;
    state.trendingGif.isLoading = false;
  },
  [effects.loadMoreTrendingGif.rejected]: (state, action) => {
    state.trendingGif.error = action.payload.error;
    state.trendingGif.isLoading = false;
  },
};

const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers,
  extraReducers,
});

export default generalSlice;
