import { number, string } from 'prop-types';

const schema = {
  change: 0,
  companyid: '',
  followed: 0,
  followers: '',
  percent: 0,
  price: '',
  symbol: '',
};

const propTypes = {
  change: number,
  companyid: string,
  followed: number,
  followers: string,
  percent: number,
  price: string,
  symbol: string,
};

export default { schema, propTypes };
