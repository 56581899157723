const theme = {};

enum Theme {
  dark = 'dark',
  light = 'light',
}

enum Page {
  privacyDark = 'privacy-page-dark',
  privacyLight = 'privacy-page-light',
}

type PageTheme = {
    page: Page;
    textPrimary: string;
    textSecondary: string;
    backgroundPrimary: string;
    backgroundSecondary: string;
    borderPrimary: string;
};

type PrivacyPageTheme = {
  [key in Theme] : PageTheme
}

const privacyPageTheme: PrivacyPageTheme = {
  dark: {
    page: Page.privacyDark,
    textPrimary: '#cbcbcb',
    textSecondary: '#858585',
    backgroundPrimary: '#121212',
    backgroundSecondary: '#121212',
    borderPrimary: '#121212',
  },
  light: {
    page: Page.privacyLight,
    textPrimary: '#333333',
    textSecondary: '#858585',
    backgroundPrimary: '#ffffff',
    backgroundSecondary: '#f2f2f2',
    borderPrimary: '#dadada',
  },
};

export { privacyPageTheme, Theme, Page, type PageTheme };
export default theme;
