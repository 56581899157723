import { string, number } from 'prop-types';

const schema = {
  followers: '',
  id: '',
  isFollowed: 0,
  official: 0,
  reputation: '',
  tren_controversial: '',
  tren_trending: '',
  user_avatar: '',
  user_avatar_medium: '',
  user_avatar_thumb: '',
  user_features: '',
  username: '',
};

const propTypes = {
  followers: string,
  id: string,
  isFollowed: number,
  official: number,
  reputation: string,
  tren_controversial: string,
  tren_trending: string,
  user_avatar: string,
  user_avatar_medium: string,
  user_avatar_thumb: string,
  user_features: string,
  username: string,
};

export default { schema, propTypes };
