import { WatchlistState } from './types';

const rootSelector = (state: any): WatchlistState => state.watchlist;

export const collectionSelector = (state: any): WatchlistState['collection'] =>
  state.watchlist.collection;

export const tableSelector = (
  state: any,
): WatchlistState['watchlistTable']['table'] =>
  state.watchlist.watchlistTable.table;

export const activeWlSelector = (
  state: any,
): WatchlistState['root']['activeWatchlistId'] =>
  state.watchlist.root.activeWatchlistId;

export const activeWlNameSelector = (
  state: any,
): WatchlistState['root']['activeWatchlistName'] =>
  state.watchlist.root.activeWatchlistName;

export default rootSelector;
