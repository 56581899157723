import { combineReducers } from 'redux';

import credentials from './credentials';
import profile from './profile';
import trending from './trendingUser';
import onboarding from './onboarding';
import company from './company';
import general from './general';
import user from './user';
import stream from './stream';
import moderation from './moderation';
import tipping from './tipping';
import watchlist from './watchlist';
import comparison from './comparison';
import fundachart from './fundachart';
import chat from './chat/slice';
import shop from './shop';

const entitiesReducer = combineReducers({
  chat: chat.reducer,
  credentials: credentials.reducer,
  profile: profile.reducer,
  trending: trending.reducer,
  onboarding: onboarding.reducer,
  company: company.reducer,
  general: general.reducer,
  user: user.reducer,
  stream: stream.reducer,
  moderation: moderation.reducer,
  tipping: tipping.reducer,
  watchlist: watchlist.reducer,
  comparison: comparison.reducer,
  fundachart: fundachart.reducer,
  shop: shop.reducer,
});

export default entitiesReducer;
