import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from '@reduxjs/toolkit';

import friendsAPI from 'lib/api/friends';
import { effects as watchlistEffects } from 'lib/entities/watchlist';
import { AVATAR_URL } from 'constants/api';

// api
import userAPI from 'lib/api/user';

// feature constants
import { FETCH_STATUS, FILTER_CHOICE, LIST_CHOICE } from './constants';

// initial state
const initialState = {
  fetchStatus: FETCH_STATUS.IDLE,
  list: [],
  message: '',
  timeRange: FILTER_CHOICE[0],
  listChoice: LIST_CHOICE.TRENDING,
};

// Selectors -------------------------------------------------------------------
export const selectors = createSelector(
  (state) => state.trendingPeople,
  (state) => ({
    fetchStatus: state.fetchStatus,
    list: state.list,
    timeRange: state.timeRange,
    listChoice: state.listChoice,

    // following states
    getUserByIndex: (index) => state.list[index] || null,
  }),
);

// Actions ---------------------------------------------------------------------
// types
const CONTEXT = '@global/trendingPeople';

const actionType = {
  FOLLOW_USER: `${CONTEXT}/FOLLOW_USER`,
  GET_TRENDING_PEOPLE: `${CONTEXT}/GET_TRENDING_PEOPLE`,
  GET_SUGGESTED_PEOPLE: `${CONTEXT}/GET_SUGGESTED_PEOPLE`,
};

// effects
export const effects = {
  followUser: createAsyncThunk(
    actionType.FOLLOW_USER,
    async ({ userid, type = 'FOLLOW' }, { dispatch }) => {
      try {
        if (!userid) {
          throw new Error('User id is empty!');
        }

        const followUser = await dispatch(
          watchlistEffects.followUser({
            userid,
            type,
          }),
        );

        const { error, message, data } = followUser.payload;

        if (error) {
          throw new Error(error);
        }

        return { data, message, userid };
      } catch (error) {
        return error;
      }
    },
  ),

  getTrendingPeople: createAsyncThunk(
    actionType.GET_TRENDING_PEOPLE,
    async ({ timeRange = initialState.timeRange }) => {
      try {
        const response = await userAPI.getTrendingUser(timeRange.value);

        if (!response || (response.data && response.data.error)) {
          throw new Error(response.data.error);
        }

        const { data, message } = response.data;

        // compose user data
        const composedData = data.map((user) => ({
          avatar: `${AVATAR_URL}/${user.avatar.thumb}`,
          followers: parseInt(user.statistics.follower_count, 10),
          id: user.id,
          isFollowed: user.is_followed,
          official: user.is_verified,
          username: user.username,
          // for interactions
          followingStatus: FETCH_STATUS.IDLE,
        }));

        return { data: composedData, message };
      } catch (error) {
        return error;
      }
    },
  ),

  getSuggestedPeople: createAsyncThunk(
    actionType.GET_SUGGESTED_PEOPLE,
    async () => {
      try {
        const response = await friendsAPI.getSuggestFriends({
          page: 1,
          limit: 5,
          filter: 'FILTER_STREAM',
        });

        if (!response.data || response?.data?.error) {
          throw new Error('Attempt to get suggested people failed!');
        }

        const { data, message } = response.data;

        const composedData = data.map((user) => ({
          avatar: `${AVATAR_URL}/${user.user.user_avatar}`,
          id: user.user.id,
          isFollowed: user.followed === 1,
          official: user.type === 'OFFICIAL',
          username: user.user.username,
          followingStatus: FETCH_STATUS.IDLE,

          // key to differ list suggested people and trending
          reason: user.reason,
        }));

        return { data: composedData, message };
      } catch (error) {
        return { error };
      }
    },
  ),
};

// state management
const reducers = {
  changeTimeRange: (draft, action) => {
    draft.timeRange = action.payload.timeRange;
  },
  changeListChoice: (state, action) => {
    state.listChoice = action.payload.listChoice;
  },
};

// Async / Extra Reducer
const extraReducers = {
  // follow user
  [effects.followUser.pending]: (draft, action) => {
    const { index } = action.meta.arg;
    draft.list[index].followingStatus = FETCH_STATUS.PENDING;
    draft.message = null;
  },

  [effects.followUser.fulfilled]: (draft, action) => {
    const { index, type } = action.meta.arg;

    draft.list[index].followingStatus = FETCH_STATUS.RESOLVED;
    draft.list[index].isFollowed = type === 'FOLLOW';
    draft.message = action.payload.message;
  },

  [effects.followUser.rejected]: (draft, action) => {
    const { index } = action.meta.arg;

    draft.list[index].followingStatus = FETCH_STATUS.REJECTED;
    draft.error = action.error;
  },

  // trending people
  [effects.getTrendingPeople.pending]: (draft, action) => {
    draft.fetchStatus = FETCH_STATUS.PENDING;
    draft.timeRange = action.meta.arg.timeRange;
    draft.message = null;
  },

  [effects.getTrendingPeople.fulfilled]: (draft, action) => {
    draft.list = action.payload.data;
    draft.message = action.payload.message;
    draft.fetchStatus = FETCH_STATUS.RESOLVED;
  },

  [effects.getTrendingPeople.rejected]: (draft, action) => {
    draft.fetchStatus = FETCH_STATUS.REJECTED;
    draft.message = action.error;
  },

  [effects.getSuggestedPeople.pending]: (state) => {
    state.fetchStatus = FETCH_STATUS.PENDING;
    state.message = null;
  },
  [effects.getSuggestedPeople.fulfilled]: (state, action) => {
    const { error, message, data } = action.payload;

    if (error) {
      state.fetchStatus = FETCH_STATUS.REJECTED;
    } else {
      state.list = data;
      state.message = message;
      state.fetchStatus = FETCH_STATUS.RESOLVED;
    }
  },
  [effects.getSuggestedPeople.rejected]: (draft, action) => {
    draft.fetchStatus = FETCH_STATUS.REJECTED;
    draft.message = action.error;
  },
};

// Slice
const trendingPeopleSlice = createSlice({
  name: 'trendingPeople',
  initialState,
  reducers,
  extraReducers,
});

export default trendingPeopleSlice;
