import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import searchAPI from 'lib/api/search';

// Action type
const CONTEXT = '@global/searchCompany';
const actionType = {
  GET_SEARCH_RESULT: `${CONTEXT}/GET_SEARCH_RESULT`,
  GET_SEARCH_RECENT_RESULT: `${CONTEXT}/GET_SEARCH_RECENT_RESULT`,
};

// Initial state
export const initialState = {
  error: null,
  isLoading: false,
  keyword: '',
  result: [],
};

type SearchCompanyState = typeof initialState;
const searchCompanyState = (state) => state.searchCompany;

export const selectors = createSelector(
  searchCompanyState,
  (state: SearchCompanyState) => ({
    error: state.error,
    isLoading: state.isLoading,
    keyword: state.keyword,
    result: state.result,
  }),
  (state) => state,
);

// Effects
export const effects = {
  getSearchResult: createAsyncThunk<any, string>(
    actionType.GET_SEARCH_RESULT,
    async (keyword) => {
      try {
        const response = await searchAPI.getSearch(keyword);
        return response.data;
      } catch (error) {
        return error.message;
      }
    },
  ),
};

const reducers = {
  resetResult: (state) => {
    state.result = [];
  },
};

const extraReducers = (builder) => {
  builder
    .addCase(effects.getSearchResult.pending, (state, action) => {
      state.isLoading = true;
    })
    .addCase(effects.getSearchResult.fulfilled, (state, action) => {
      state.result = action?.payload?.data?.company;
      state.isLoading = false;
    })
    .addCase(effects.getSearchResult.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
};

// Slice
const searchSlice = createSlice({
  name: 'searchCompany',
  initialState,
  reducers,
  extraReducers,
});

export default searchSlice;
