import securitiesLocalStorage from 'utils/securitiesLocalStorage';

import { PayloadCase } from './store/securities.store.types';

export const getAccountNumber = (): string | null => {
  const { accountNumber } = securitiesLocalStorage.getSecuritiesAccountInfo();

  return accountNumber ?? null;
};

export const createUint8Array = (value: string): Uint8Array => {
  const textEncoder = new TextEncoder();
  return textEncoder.encode(value);
};

export const createPingPayload = () => ({
  action: {
    case: PayloadCase.COMMAND,
    value: 1,
  },
  value: new Uint8Array(),
});

export const createSubscribePayload = (accountNumber: string) => ({
  action: {
    case: PayloadCase.SUBSCRIBE,
    value: 1,
  },
  value: createUint8Array(accountNumber),
});

export const createUnsubscribePayload = (accountNumber: string) => ({
  action: {
    case: PayloadCase.UNSUBSCRIBE,
    value: 1,
  },
  value: createUint8Array(accountNumber),
});

export const generateWSCookie = (value: string) => `gen-websocket=${value}; domain=stockbit.com; path=/v1; Secure;`;

export default {
  createPingPayload,
  createSubscribePayload,
  createUnsubscribePayload,
  generateWSCookie,
};
