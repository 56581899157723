/* eslint-disable no-undef */
import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import SUCCESS_MESSAGE from 'constants/successMessage';
import handleErrorMessageAPI from 'global/AlertErrorMessage';

import screenerAPI from 'lib/api/screener';

import {
  IAddFavoriteScreener,
  IRemoveFavoriteScreener,
  IScreenerFavorites,
  IScreenerFinancial,
  IScreenerHeader,
  IScreenerModal,
  IScreenerPreset,
  IScreenerUniverse,
  IScreenerSaveOrRunResult,
  IScreenerSaveOrRunPayload,
  IScreenerCustom,
  IScreenerLoad,
  IScreenerRemoveCustomScreenerResult,
  ScreenerTemplateType,
} from './types';

// action types
const CONTEXT = '@feature/screener';

const actionType = {
  GET_SCREENER_FAVORITES: `${CONTEXT}/GET_SCREENER_FAVORITES`,
  GET_SCREENER_PRESET: `${CONTEXT}/GET_SCREENER_PRESET`,
  ADD_FAVORITE_SCREENER: `${CONTEXT}/ADD_FAVORITE_SCREENER`,
  REMOVE_FAVORITE_SCREENER: `${CONTEXT}/REMOVE_FAVORITE_SCREENER`,
  GET_SCREENER_UNIVERSE: `${CONTEXT}/GET_SCREENER_UNIVERSE`,
  GET_SCREENER_FINANCIAL: `${CONTEXT}/GET_SCREENER_FINANCIAL`,
  SAVE_OR_RUN_SCREENER: `${CONTEXT}/SAVE_OR_RUN_SCREENER`,
  GET_SCREENER: `${CONTEXT}/GET_SCREENER`,
  GET_SCREENER_CUSTOM: `${CONTEXT}/GET_SCREENER_CUSTOM`,
  REMOVE_SCREENER_CUSTOM: `${CONTEXT}/REMOVE_SCREENER_CUSTOM`,
};

interface IScreenerState {
  screenerUtils: IScreenerHeader;
  screenerFavorites: IScreenerFavorites;
  screenerPreset: IScreenerPreset;
  addFavoriteScreenerResult: IAddFavoriteScreener;
  removeFavoriteScreenerResult: IRemoveFavoriteScreener;
  activeScreener: number;
  screenerUniverse: IScreenerUniverse;
  screenerFinancialMetric: IScreenerFinancial;
  screenerModal: IScreenerModal;
  screenerResult: IScreenerLoad;
  screenerCustom: IScreenerCustom;
  removeCustomScreenerResult: IScreenerRemoveCustomScreenerResult;
  screenerPostResult: IScreenerLoad;
}

const initialState: IScreenerState = {
  screenerUtils: {
    isFormActive: false,
    isFormEditMode: false,
    isFormCreateMode: false,
    isModalPresetScreenerActive: false,
    isModalFinancialMetricActive: false,
    isModalAddColumnActive: false,
    screenerAction: null,
    isEmptyResult: false,
  },
  screenerFavorites: {
    data: [],
    message: '',
    isError: false,
    isLoading: false,
  },
  screenerPreset: {
    message: '',
    data: [],
    isError: false,
    isLoading: false,
  },
  activeScreener: 0,
  addFavoriteScreenerResult: {
    isError: false,
    isLoading: false,
    message: '',
  },
  removeFavoriteScreenerResult: {
    isError: false,
    isLoading: false,
    message: '',
  },
  screenerUniverse: {
    data: {},
    message: '',
    isError: false,
    isLoading: false,
  },
  screenerFinancialMetric: {
    data: [],
    message: '',
    isError: false,
    isLoading: false,
  },
  screenerResult: {
    data: {},
    message: '',
    isError: false,
    isLoading: false,
  },
  screenerModal: {
    financialModal: {
      id: null,
      name: null,
    },
    presetModal: {
      id: null,
      name: null,
    },
    addColumnModal: {
      id: null,
      name: null,
    },
  },
  screenerCustom: {
    message: '',
    data: [],
    isError: false,
    isLoading: false,
  },
  removeCustomScreenerResult: {
    data: [],
    message: '',
    isError: false,
    isLoading: false,
  },
  screenerPostResult: {
    data: {},
    message: '',
    isError: false,
    isLoading: false,
  },
};

const screenerState = (state) => state.screener;
export const screenerSelectors = createSelector(
  screenerState,
  (screener: IScreenerState) => ({
    screenerUtils: screener.screenerUtils,
    screenerPreset: screener.screenerPreset,
    screenerFavorites: screener.screenerFavorites,
    addFavoriteScreenerResult: screener.addFavoriteScreenerResult,
    removeFavoriteScreenerResult: screener.removeFavoriteScreenerResult,
    screenerUniverse: screener.screenerUniverse,
    screenerFinancialMetric: screener.screenerFinancialMetric,
    screenerModal: screener.screenerModal,
    screenerResult: screener.screenerResult,
    screenerCustom: screener.screenerCustom,
    removeScreenerCustomResult: screener.removeCustomScreenerResult,
    screenerPostResult: screener.screenerPostResult,
  }),
);

export const screenerEffects = {
  getScreenerFavorites: createAsyncThunk<any, void>(
    actionType.GET_SCREENER_FAVORITES,
    async () => {
      try {
        const response = await screenerAPI.getScreenerFavorites();

        if (!response.data) {
          throw new Error('Attempt to get list of favorites screener failed');
        }
        const { data } = response;
        return data;
      } catch (error) {
        return { error };
      }
    },
  ),
  getScreenerPreset: createAsyncThunk<any, void>(
    actionType.GET_SCREENER_PRESET,
    async () => {
      try {
        const response = await screenerAPI.getScreenerMetrics();

        if (!response.data) {
          throw new Error('Attempt to get list of screener preset failed');
        }
        const { data } = response;
        return data;
      } catch (error) {
        return { error };
      }
    },
  ),
  addFavorite: createAsyncThunk<
    any,
    { screenerID: number; screenerType: ScreenerTemplateType }
  >(actionType.ADD_FAVORITE_SCREENER, async ({ screenerID, screenerType }) => {
    try {
      const response = await screenerAPI.addScreenerFavorite(
        screenerID,
        screenerType,
      );

      if (!response.data) {
        throw new Error('Attempt to add screener favorite failed');
      }
      const { data } = response;
      return data;
    } catch (error) {
      return { error };
    }
  }),
  removeFavorite: createAsyncThunk<
    any,
    { screenerID: number; screenerType: ScreenerTemplateType }
  >(
    actionType.REMOVE_FAVORITE_SCREENER,
    async ({ screenerID, screenerType }) => {
      try {
        const response = await screenerAPI.removeScreenerFavorite(
          screenerID,
          screenerType,
        );

        if (!response.data) {
          throw new Error('Attempt to remove screener favorite failed');
        }

        const { data } = response;
        const removedID = {
          ...data,
          data: screenerID,
        };
        return removedID;
      } catch (error) {
        return { error };
      }
    },
  ),
  getScreenerUniverse: createAsyncThunk<any>(
    actionType.GET_SCREENER_UNIVERSE,
    async () => {
      try {
        const response = await screenerAPI.getScreenerUniverse();
        if (!response.data) {
          throw new Error('Attempt get screener universe is failed');
        }
        const { data } = response;
        return data;
      } catch (error) {
        return { error };
      }
    },
  ),
  getScreenerFinancialMetric: createAsyncThunk<any>(
    actionType.GET_SCREENER_FINANCIAL,
    async () => {
      try {
        const response = await screenerAPI.getScreenerFinancial();
        if (!response.data) {
          throw new Error('Attempt get financial metric is failed');
        }
        const { data } = response;
        return data;
      } catch (error) {
        return { error };
      }
    },
  ),
  postScreener: createAsyncThunk<
    any,
    IScreenerSaveOrRunPayload & { fromForm?: boolean }
  >(actionType.SAVE_OR_RUN_SCREENER, async ({ fromForm, ...payload }) => {
    try {
      const response = await screenerAPI.postScreenerListResult(payload);
      if (!response.data) {
        throw new Error('Attempt save or run screener is failed');
      }

      const { data } = response;
      return { ...data, fromForm };
    } catch (error) {
      return { error };
    }
  }),
  getScreener: createAsyncThunk<
    any,
    { screenerID: number; type: ScreenerTemplateType }
  >(actionType.GET_SCREENER, async ({ screenerID, type }) => {
    try {
      const response = await screenerAPI.getLoadPresetScreener(
        screenerID,
        type,
      );
      if (!response.data) {
        throw new Error('Attempt to get screener data is failed');
      }

      const { data } = response;
      return data;
    } catch (error) {
      return { error };
    }
  }),
  getScreenerCustom: createAsyncThunk<any>(
    actionType.GET_SCREENER_CUSTOM,
    async () => {
      try {
        const response = await screenerAPI.getScreenerCustom();
        if (!response.data) {
          throw new Error('Attempt get custom screener is failed');
        }
        const { data } = response;
        return data;
      } catch (error) {
        return { error };
      }
    },
  ),
  removeScreenerCustom: createAsyncThunk<any, { id: number }>(
    actionType.REMOVE_SCREENER_CUSTOM,
    async ({ id }) => {
      try {
        const response = await screenerAPI.removeScreenerCustom(id);
        if (!response.data) {
          throw new Error('Attempt to remove custom screener is failed');
        }
        const { data } = response;
        handleErrorMessageAPI(
          SUCCESS_MESSAGE.SCREENER_TEMPLATE_DELETED,
          SUCCESS_MESSAGE.ALERT_GREEN,
        );
        return data;
      } catch (error) {
        return { error };
      }
    },
  ),
};

const reducers = {
  setIsFormActive: (state: IScreenerState, action: PayloadAction<boolean>) => {
    state.screenerUtils.isFormActive = action.payload;
  },
  setIsFormEditMode: (
    state: IScreenerState,
    action: PayloadAction<boolean>,
  ) => {
    state.screenerUtils.isFormEditMode = action.payload;
  },
  setIsFormCreateMode: (
    state: IScreenerState,
    action: PayloadAction<boolean>,
  ) => {
    state.screenerUtils.isFormCreateMode = action.payload;
  },
  setIsModalPresetScreenerActive: (
    state: IScreenerState,
    action: PayloadAction<boolean>,
  ) => {
    state.screenerUtils.isModalPresetScreenerActive = action.payload;
  },
  setIsModalFinancialMetricActive: (
    state: IScreenerState,
    action: PayloadAction<boolean>,
  ) => {
    state.screenerUtils.isModalFinancialMetricActive = action.payload;
  },
  setIsModalAddColumnActive: (
    state: IScreenerState,
    action: PayloadAction<boolean>,
  ) => {
    state.screenerUtils.isModalAddColumnActive = action.payload;
  },
  setActiveScreener: (state: IScreenerState, action: PayloadAction<number>) => {
    state.activeScreener = action.payload;
  },
  setScreenerModal: (
    state: IScreenerState,
    action: PayloadAction<{ screenerType: string; id: string; name: string }>,
  ) => {
    const { screenerType, id, name } = action.payload;
    if (screenerType === 'preset') {
      state.screenerModal.presetModal = {
        ...state.screenerModal.presetModal,
        id,
        name,
      };
      state.screenerModal.presetModal.name = name;
    } else if (screenerType === 'financial') {
      state.screenerModal.financialModal = {
        ...state.screenerModal.financialModal,
        id,
        name,
      };
      state.screenerModal.financialModal.name = name;
    } else if (screenerType === 'column') {
      state.screenerModal.addColumnModal = {
        ...state.screenerModal.addColumnModal,
        id,
        name,
      };
    }
  },
  resetFormState: (state: IScreenerState) => {
    state.screenerUtils.isFormActive = false;
    state.screenerUtils.isFormEditMode = false;
    state.screenerUtils.isFormCreateMode = false;
    state.screenerResult.data = {};
    state.screenerPostResult.data = {};
    state.screenerUtils.screenerAction = null;
    state.screenerUtils.isEmptyResult = false;
  },
  addNewColumn: (state: IScreenerState) => {
    state.screenerUtils.isFormActive = false;
    state.screenerUtils.isFormEditMode = false;
    state.screenerUtils.isFormCreateMode = false;
  },
  updateScreenerResult: (
    state: IScreenerState,
    action: PayloadAction<{
      screenerAction: IScreenerHeader['screenerAction'];
      screener: Partial<IScreenerLoad['data']>;
    }>,
  ) => {
    const { screenerAction, screener } = action.payload;
    if (screenerAction === 'get') {
      state.screenerResult.data = {
        ...state.screenerResult.data,
        ...screener,
      };
    }
    if (screenerAction === 'post') {
      state.screenerPostResult.data = {
        ...state.screenerPostResult.data,
        ...screener,
      };
    }
  },
  resetAddColumnModal: (state: IScreenerState) => {
    state.screenerModal.addColumnModal = { id: null, name: null };
  },
};

const extraReducer = (builder) => {
  builder
    .addCase(
      screenerEffects.getScreenerFavorites.fulfilled,
      (state: IScreenerState, action: PayloadAction<IScreenerFavorites>) => {
        const { message, data } = action.payload;
        if (!data) {
          state.screenerFavorites.isError = true;
        } else {
          state.screenerFavorites.data = data;
          state.screenerFavorites.message = message;
        }
        state.screenerFavorites.isLoading = false;
      },
    )
    .addCase(
      screenerEffects.getScreenerFavorites.pending,
      (state: IScreenerState) => {
        state.screenerFavorites.isLoading = true;
      },
    )
    .addCase(
      screenerEffects.getScreenerFavorites.rejected,
      (state: IScreenerState, action: PayloadAction<IScreenerFavorites>) => {
        state.screenerFavorites.isError = action.payload.isError;
        state.screenerFavorites.isLoading = false;
      },
    )
    .addCase(
      screenerEffects.getScreenerPreset.fulfilled,
      (state: IScreenerState, action: PayloadAction<IScreenerPreset>) => {
        const { message, data } = action.payload;
        if (!data) {
          state.screenerPreset.isError = true;
        } else {
          state.screenerPreset.data = data;
          state.screenerPreset.message = message;
        }
        state.screenerPreset.isLoading = false;
      },
    )
    .addCase(
      screenerEffects.getScreenerPreset.pending,
      (state: IScreenerState) => {
        state.screenerPreset.isLoading = true;
      },
    )
    .addCase(
      screenerEffects.getScreenerPreset.rejected,
      (state: IScreenerState, action: PayloadAction<IScreenerPreset>) => {
        state.screenerPreset.isError = action.payload.isError;
        state.screenerPreset.isLoading = false;
      },
    )
    .addCase(
      screenerEffects.addFavorite.fulfilled,
      (state: IScreenerState, action: PayloadAction<IAddFavoriteScreener>) => {
        const { message, data } = action.payload;
        const { data: baseFavorite } = state.screenerFavorites;
        if (!data) {
          state.addFavoriteScreenerResult.isError = true;
        } else {
          const result = [...baseFavorite, data];
          state.screenerFavorites.data = result;
          state.addFavoriteScreenerResult.message = message;
        }
        state.addFavoriteScreenerResult.isLoading = false;
      },
    )
    .addCase(screenerEffects.addFavorite.pending, (state: IScreenerState) => {
      state.addFavoriteScreenerResult.isLoading = true;
    })
    .addCase(
      screenerEffects.addFavorite.rejected,
      (state: IScreenerState, action: PayloadAction<IAddFavoriteScreener>) => {
        state.addFavoriteScreenerResult.isError = action.payload.isError;
        state.addFavoriteScreenerResult.isLoading = false;
      },
    )
    .addCase(
      screenerEffects.removeFavorite.fulfilled,
      (
        state: IScreenerState,
        action: PayloadAction<IRemoveFavoriteScreener>,
      ) => {
        const { message, data: obsoleteData } = action.payload;
        const { data: baseFavorite } = state.screenerFavorites;
        if (!obsoleteData) {
          state.removeFavoriteScreenerResult.isError = true;
        } else {
          const IDRemove: number = obsoleteData;
          const result = [...baseFavorite].filter(
            (favoritesScreener) => favoritesScreener.id !== IDRemove,
          );
          state.screenerFavorites.data = result;
          state.removeFavoriteScreenerResult.message = message;
        }
        state.removeFavoriteScreenerResult.isLoading = false;
      },
    )
    .addCase(
      screenerEffects.removeFavorite.pending,
      (state: IScreenerState) => {
        state.removeFavoriteScreenerResult.isLoading = true;
      },
    )
    .addCase(
      screenerEffects.removeFavorite.rejected,
      (
        state: IScreenerState,
        action: PayloadAction<IRemoveFavoriteScreener>,
      ) => {
        state.removeFavoriteScreenerResult.isError = action.payload.isError;
        state.removeFavoriteScreenerResult.isLoading = false;
      },
    )
    .addCase(
      screenerEffects.getScreenerUniverse.fulfilled,
      (state: IScreenerState, action: PayloadAction<IScreenerUniverse>) => {
        const { message, data } = action.payload;

        if (!data) {
          state.screenerUniverse.isError = true;
        } else {
          state.screenerUniverse.data = data;
          state.screenerUniverse.message = message;
        }

        state.screenerUniverse.isLoading = false;
      },
    )
    .addCase(
      screenerEffects.getScreenerUniverse.pending,
      (state: IScreenerState) => {
        state.screenerUniverse.isLoading = true;
      },
    )
    .addCase(
      screenerEffects.getScreenerUniverse.rejected,
      (state: IScreenerState, action: PayloadAction<IScreenerUniverse>) => {
        state.screenerUniverse.isError = action.payload.isError;
        state.screenerUniverse.isLoading = false;
      },
    )
    .addCase(
      screenerEffects.getScreenerFinancialMetric.fulfilled,
      (state: IScreenerState, action: PayloadAction<IScreenerFinancial>) => {
        const { message, data } = action.payload;

        if (!data) {
          state.screenerFinancialMetric.isError = true;
        } else {
          state.screenerFinancialMetric.data = data;
          state.screenerFinancialMetric.message = message;
        }

        state.screenerFinancialMetric.isLoading = false;
      },
    )
    .addCase(
      screenerEffects.getScreenerFinancialMetric.pending,
      (state: IScreenerState) => {
        state.screenerFinancialMetric.isLoading = true;
      },
    )
    .addCase(
      screenerEffects.getScreenerFinancialMetric.rejected,
      (state: IScreenerState, action: PayloadAction<IScreenerFinancial>) => {
        state.screenerFinancialMetric.isError = action.payload.isError;
        state.screenerFinancialMetric.isLoading = false;
      },
    )
    .addCase(
      screenerEffects.postScreener.fulfilled,
      (
        state: IScreenerState,
        action: PayloadAction<
          IScreenerSaveOrRunResult & { fromForm?: boolean },
          string,
          { arg: IScreenerSaveOrRunPayload & { fromForm?: boolean } }
        >,
      ) => {
        const { message, data, fromForm } = action.payload;
        const { arg } = action.meta;

        if (!data) {
          state.screenerPostResult.isError = true;
        } else {
          state.screenerPostResult.data = {
            ...state.screenerPostResult.data,
            ...data,
          };

          // Sync ScreenerForm state after submitting form
          // use case for `rules`, etc.
          if (fromForm) {
            const { screenerid, ...rest } = data;
            state.screenerResult.data = {
              ...state.screenerResult.data,
              ...rest,
              screenerid:
                arg.save === '1'
                  ? screenerid
                  : state.screenerResult.data.screenerid,
            };
          }

          state.screenerPostResult.message = message;
          state.screenerUtils.isFormCreateMode = false;
        }

        state.screenerPostResult.isLoading = false;
      },
    )
    .addCase(screenerEffects.postScreener.pending, (state: IScreenerState) => {
      state.screenerPostResult.isLoading = true;
      state.screenerUtils.screenerAction = 'post';
      state.screenerUtils.isEmptyResult = false;
    })
    .addCase(
      screenerEffects.postScreener.rejected,
      (
        state: IScreenerState,
        action: PayloadAction<IScreenerSaveOrRunResult>,
      ) => {
        state.screenerPostResult.isError = action.payload.isError;
        state.screenerPostResult.isLoading = false;
        state.screenerUtils.isEmptyResult = true;
      },
    )
    .addCase(
      screenerEffects.getScreener.fulfilled,
      (state: IScreenerState, action: PayloadAction<IScreenerLoad>) => {
        const { message, data } = action.payload;

        if (!data) {
          state.screenerResult.isError = true;
        } else {
          const { screenerid, ...rest } = data;

          state.screenerResult.data = {
            ...state.screenerResult.data,
            ...rest,
            screenerid: rest.isguru ? 0 : screenerid,
          };
          state.screenerPostResult.data = {
            ...state.screenerPostResult.data,
            ...rest,
            screenerid,
          };
          state.screenerResult.message = message;
        }

        state.screenerResult.isLoading = false;
      },
    )
    .addCase(screenerEffects.getScreener.pending, (state: IScreenerState) => {
      state.screenerResult.isLoading = true;
      state.screenerPostResult.data = {};
      state.screenerUtils.screenerAction = 'get';
      state.screenerUtils.isEmptyResult = false;
    })
    .addCase(
      screenerEffects.getScreener.rejected,
      (state: IScreenerState, action: PayloadAction<IScreenerLoad>) => {
        state.screenerResult.isError = action.payload.isError;
        state.screenerResult.isLoading = false;
        state.screenerUtils.isEmptyResult = true;
      },
    )
    .addCase(
      screenerEffects.getScreenerCustom.fulfilled,
      (state: IScreenerState, action: PayloadAction<IScreenerCustom>) => {
        const { message, data } = action.payload;

        if (!data) {
          state.screenerCustom.isError = true;
        } else {
          state.screenerCustom.data = data;
          state.screenerCustom.message = message;
        }

        state.screenerCustom.isLoading = false;
      },
    )
    .addCase(
      screenerEffects.getScreenerCustom.pending,
      (state: IScreenerState) => {
        state.screenerCustom.isLoading = true;
      },
    )
    .addCase(
      screenerEffects.getScreenerCustom.rejected,
      (state: IScreenerState, action: PayloadAction<IScreenerCustom>) => {
        state.screenerCustom.isError = action.payload.isError;
        state.screenerCustom.isLoading = false;
        state.screenerUtils.isEmptyResult = true;
      },
    )
    .addCase(
      screenerEffects.removeScreenerCustom.fulfilled,
      (state: IScreenerState, action: PayloadAction<IScreenerCustom>) => {
        const { message, data } = action.payload;

        if (!data) {
          state.removeCustomScreenerResult.isError = true;
        } else {
          state.removeCustomScreenerResult.data = data;
          state.removeCustomScreenerResult.message = message;
        }

        state.removeCustomScreenerResult.isLoading = false;
      },
    )
    .addCase(
      screenerEffects.removeScreenerCustom.pending,
      (state: IScreenerState) => {
        state.removeCustomScreenerResult.isLoading = true;
      },
    )
    .addCase(
      screenerEffects.removeScreenerCustom.rejected,
      (state: IScreenerState, action: PayloadAction<IScreenerCustom>) => {
        state.removeCustomScreenerResult.isError = action.payload.isError;
        state.removeCustomScreenerResult.isLoading = false;
      },
    );
};

const screenerSlice = createSlice({
  name: 'screener',
  initialState,
  // for non-async reducer logic
  reducers,
  // for async reducer logic
  extraReducers: extraReducer,
});

export default screenerSlice;
