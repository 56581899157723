/* eslint-disable eqeqeq */
/* eslint-disable no-undef */
import { useEffect, useRef, useCallback } from 'react';
import { CLEVERTAP_ID } from 'constants/app';
import { clevertapInitProfileQueue } from 'utils/clevertap';

function useClevertap() {
  const clevertapRef = useRef();

  const initClevertap = useCallback(async () => {
    const clevertap = (await import('clevertap-web-sdk')).default;
    clevertap.privacy.push({ optOut: false });
    clevertap.privacy.push({ useIP: false });
    clevertap.init(CLEVERTAP_ID, 'sg1');
    clevertap.setLogLevel(3);
    window.clevertap = clevertap;

    clevertapInitProfileQueue();
  }, []);

  useEffect(() => {
    if (!clevertapRef.current) {
      initClevertap();
    }
  }, [clevertapRef.current, initClevertap]);

  return null;
}

export default useClevertap;
