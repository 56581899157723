import requestConfig from 'config/requestExodus';
import { API_EXODUS_URL } from 'constants/api';
import {
  Gateway,
  OrderDetail,
  Product,
  Promos,
  Totals,
} from '../../../@types/shop';
import { APIResponse } from '../../../@types/api-response';

// type
type ProductResponse<DataToken> = Promise<APIResponse<DataToken>>;
type CartResponse = {
  products: Product[];
  promos: Promos;
  totals: Totals;
};

type CheckoutResponse = {
  gateway?: Gateway;
  url?: string;
  action?: {
    client_key: string;
    environment: string;
    order_id: string;
    snap_token: string;
  };
};

type HistoryProps = {
  last_id: number;
  limit: number;
};

// init request config
const request = requestConfig();

// constants
const SHOP_URL = `${API_EXODUS_URL}/pro-sub`;

// api wrappers
const getProducts = (): ProductResponse<Product[]> =>
  request.get(`${SHOP_URL}/products`);

const addItems = (id: string, quantity: number): ProductResponse<any> =>
  request.post(`${SHOP_URL}/cart/item`, { id, quantity });

const removeItem = (): ProductResponse<any> =>
  request.delete(`${SHOP_URL}/cart`);

const getCart = (): ProductResponse<CartResponse> =>
  request.get(`${SHOP_URL}/cart`);

const checkout = (gateway: Gateway): ProductResponse<CheckoutResponse> =>
  request.post(`${SHOP_URL}/cart/checkout`, { gateway });

const applyCoupon = (coupon) => request.post(`${SHOP_URL}/coupon`, { coupon });

const removeCoupon = () => request.delete(`${SHOP_URL}/coupon`);

const applyVoucher = (voucher) =>
  request.post(`${SHOP_URL}/voucher`, { voucher });

const removePromo = (type) => request.delete(`${SHOP_URL}/${type}`);

const getSubscriptions = () => request.get(`${SHOP_URL}/subscription`);

const activateFreeTrial = () => request.post(`${SHOP_URL}/free-trial/activate`);

const getPurchaseHistory = (
  params: HistoryProps,
): ProductResponse<OrderDetail> =>
  request.get(`${SHOP_URL}/order/history`, { params });

export default {
  getProducts,
  getCart,
  addItems,
  checkout,
  applyCoupon,
  removeCoupon,
  applyVoucher,
  removeItem,
  removePromo,
  getSubscriptions,
  getPurchaseHistory,
  activateFreeTrial,
};
