import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import searchAPI from 'lib/api/search';
import { schema } from 'lib/models/search';

// Action type
const CONTEXT = '@global/search';
const actionType = {
  GET_SEARCH_RESULT: `${CONTEXT}/GET_SEARCH_RESULT`,
  GET_SEARCH_RECENT_RESULT: `${CONTEXT}/GET_SEARCH_RECENT_RESULT`,
  POST_SEARCH_RECENT_RESULT: `${CONTEXT}/POST_SEARCH_RECENT_RESULT`,
};

// Initial state
export const initialState = {
  error: null,
  isLoading: false,
  keyword: '',
  result: schema,
  recent: { list: [] },
};

type StateType = typeof initialState;

// Selectors
export const selectors = createSelector(
  (state) => state.search,
  (state) => ({
    error: state.error,
    isLoading: state.isLoading,
    keyword: state.keyword,
    result: state.result,
    recent: state.recent,
  }),
);

// Effects
export const effects = {
  getSearchResult: createAsyncThunk(
    actionType.GET_SEARCH_RESULT,
    async (keyword: string) => {
      try {
        const response = await searchAPI.getSearch(keyword);
        return response.data;
      } catch (error) {
        return error.message;
      }
    },
  ),

  getSearchRecentResult: createAsyncThunk(
    actionType.GET_SEARCH_RECENT_RESULT,
    async () => {
      try {
        const response = await searchAPI.getSearchRecent();
        return response.data;
      } catch (error) {
        return error.message;
      }
    },
  ),

  postSearchRecentResult: createAsyncThunk(
    actionType.POST_SEARCH_RECENT_RESULT,
    async ({
      keyword,
      target,
      type,
    }: {
      keyword: string;
      target: number;
      type: string;
    }) => {
      try {
        const response = await searchAPI.postSearchRecent(
          keyword,
          target,
          type,
        );
        return response.data;
      } catch (error) {
        return error.message;
      }
    },
  ),
};

const extraReducers = (builder: ActionReducerMapBuilder<StateType>) => {
  builder
    .addCase(effects.getSearchResult.pending, (state) => ({
      ...state,
      isLoading: true,
    }))
    .addCase(effects.getSearchResult.fulfilled, (state, action) => ({
      ...state,
      isLoading: false,
      result: action.payload.data,
    }))
    .addCase(effects.getSearchResult.rejected, (state, action) => ({
      ...state,
      isLoading: false,
      error: action.payload,
    }))
    .addCase(effects.getSearchRecentResult.pending, (state) => ({
      ...state,
      isLoading: true,
    }))
    .addCase(effects.getSearchRecentResult.fulfilled, (state, action) => ({
      ...state,
      isLoading: false,
      recent: {
        list: action.payload.data,
      },
    }))
    .addCase(effects.getSearchRecentResult.rejected, (state, action) => ({
      ...state,
      isLoading: false,
      error: action.payload,
    }))
    .addCase(effects.postSearchRecentResult.pending, (state) => ({
      ...state,
      isLoading: true,
    }))
    .addCase(effects.postSearchRecentResult.fulfilled, (state) => ({
      ...state,
      isLoading: false,
    }))
    .addCase(effects.postSearchRecentResult.rejected, (state, action) => ({
      ...state,
      error: action.payload,
    }));
};

// Slice
const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {},
  extraReducers,
});

export default searchSlice;
