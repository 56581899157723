import { useState } from 'react';
import Script from 'next/script';
import { assetPrefix } from 'constants/app';
import useWebSocket from 'hooks/useWebSocket';

const Primus = () => {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  useWebSocket(scriptLoaded);
  return (
    <Script
      type="text/javascript"
      src={`${assetPrefix}/js/primus.min.js`}
      async
      onLoad={() => (setScriptLoaded(true))}
    />
  );
};

export default Primus;
