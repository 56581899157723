import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from '@reduxjs/toolkit';

// Auth Related Modules
import companyAPI from 'lib/api/company';

// Actions
const CONTEXT = '@side-widget/hotlist';

const actionType = {
  GET_HOTLIST: `${CONTEXT}/GET_HOTLIST`,
};

// hotlist type
export const HOTLIST_TYPE = {
  TOP_GAINER: 'topGainer',
  TOP_LOSER: 'topLoser',
  MOST_ACTIVE: 'mostActive',
};

// hotlist api
const HOTLIST_API = {
  [HOTLIST_TYPE.TOP_GAINER]: companyAPI.getTopGainer,
  [HOTLIST_TYPE.TOP_LOSER]: companyAPI.getTopLoser,
  [HOTLIST_TYPE.MOST_ACTIVE]: companyAPI.getMostActive,
};

// initial state
const initialState = {
  topGainer: {
    data: [],
    isLoading: false,
    error: null,
  },
  topLoser: {
    data: [],
    isLoading: false,
    error: null,
  },
  mostActive: {
    data: [],
    isLoading: false,
    error: null,
  },
};

// Selectors
export const selectors = createSelector(
  (state) => {
    const { topGainer, topLoser, mostActive } = state.mainLayout.hotlist;
    return {
      // all
      topGainer,
      topLoser,
      mostActive,
      // top gainer
      topGainerError: topGainer.error,
      topGainerData: topGainer.data,
      topGainerLoading: topGainer.isLoading,
      // top loser
      topLoserError: topLoser.error,
      topLoserData: topLoser.data,
      topLoserLoading: topLoser.isLoading,
      // most active
      mostActiveError: mostActive.error,
      mostActiveData: mostActive.data,
      mostActiveLoading: mostActive.isLoading,
    };
  },
  (state) => state,
);

// Reducer
export const reducers = {};

// effects
export const effects = {
  // login
  getHotlist: createAsyncThunk(
    actionType.GET_HOTLIST,
    async ({ type = HOTLIST_TYPE.TOP_GAINER }) => {
      try {
        const targetAPI =
          HOTLIST_API[type] || HOTLIST_API[HOTLIST_TYPE.TOP_GAINER];

        const response = await targetAPI();

        if (!response.data) {
          throw new Error('Error when changing password, no data recevied');
        }

        const { data, error, message } = response.data;

        if (error) {
          throw new Error(error);
        }

        const normalized = data.map((item) => {
          const { change } = item;
          const changeIndicator = change[0];

          let changeValue = change;
          let changeFlow = 'neutral';

          if (changeIndicator === '+') {
            changeFlow = 'up';
            changeValue = change.substring(1);
          } else if (changeIndicator === '-') {
            changeFlow = 'down';
          } else {
            changeFlow = 'neutral';
          }

          return {
            ...item,
            changeFlow,
            changeIndicator,
            changeValue,
          };
        });

        return { data: normalized, message };
      } catch (error) {
        return Promise.reject(new Error(error));
      }
    },
  ),
};

// Extra / Async Reducer
const extraReducers = {
  [effects.getHotlist.pending]: (state, action) => {
    const { type } = action.meta.arg;
    state[type].error = null;
    state[type].isLoading = true;
  },

  [effects.getHotlist.fulfilled]: (state, action) => {
    const { type } = action.meta.arg;
    state[type].data = action.payload.data;
    state[type].error = null;
    state[type].isLoading = true;
    state[type].message = action.payload.message;
  },

  [effects.getHotlist.rejected]: (state, action) => {
    const { type } = action.meta.arg;
    state[type].error = action.error;
    state[type].isLoading = true;
  },
};

// Slice
const hotlistSlice = createSlice({
  name: 'hotlist',
  initialState,
  reducers,
  extraReducers,
});

export default hotlistSlice;
