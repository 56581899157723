import { Reducer } from 'react';
import { AnyAction, combineReducers } from 'redux';

// slices
import companySuggestionsSlice from './SearchSymbol/slice';
import deleteCompanyModalSlice from './DeleteCompanyModal/slice';
import financialMetricSlice from './FinancialMetric/slice';
import tableSlice from './Table/slice';

// types
import { WatchlistTableState } from './types';

const watchlistTableReducer: Reducer<
  WatchlistTableState,
  AnyAction
> = combineReducers({
  companySuggestions: companySuggestionsSlice.reducer,
  deleteModal: deleteCompanyModalSlice.reducer,
  financialMetrics: financialMetricSlice.reducer,
  table: tableSlice.reducer,
});

export default watchlistTableReducer;
