import { combineReducers } from 'redux';
import discoverPeopleSlice from './DiscoverPeople/slice';
import discoverStockSlice from './DiscoverStock/slice';
import uploadAvatarSlice from './UploadProfilePicture/slice';

const onboardingReducer = combineReducers({
  discoverStock: discoverStockSlice.reducer,
  uploadImageAvatar: uploadAvatarSlice.reducer,
  discoverPeople: discoverPeopleSlice.reducer,
});

export default onboardingReducer;
