import { createSelector, createSlice } from '@reduxjs/toolkit';
import { effects as credentialsEffects } from 'lib/entities/credentials';
import rootSelector, {
  activeWlNameSelector,
  activeWlSelector,
} from './selectors';

// local types
import { RootSliceState, SliceReducer } from './types';
import { thunks as collectionThunks } from './Collection/slice';

// constants
const SLICE_NAME = 'root';

// initial state

const initialState: RootSliceState = {
  activeWatchlistId: null,
  activeWatchlistName: 'All Watchlist',
  connectionStatus: 'Normal',
};

// Reducer
type SliceReducers = {
  handleChangeActiveWatchlist: SliceReducer<
    RootSliceState,
    { selected: string; name: string }
  >;
};

const reducers: SliceReducers = {
  handleChangeActiveWatchlist: (draft, action) => {
    draft.activeWatchlistId = action.payload.selected;
    draft.activeWatchlistName = action.payload.name;
  },
};

// selectors
export const selectors = createSelector(rootSelector, (state) => ({
  ...state.root,
  isExpand: state.watchlistTable.table.columns?.length > 5,
  isComposed: state.watchlistTable.table.columns?.length < 2,
}));

export const activeWatchlistIdSelector = createSelector(
  activeWlSelector,
  (state) => state,
);
export const activeWatchlistNameSelector = createSelector(
  activeWlNameSelector,
  (state) => state,
);

const extraReducers = (builder) => {
  builder
    .addCase(
      collectionThunks.editCompanyWatchlist.fulfilled,
      (state: RootSliceState, action) => {
        const { data } = action.payload;
        if (data) {
          const { watchlist_id: id, name } = data;

          if (state.activeWatchlistId === id) {
            state.activeWatchlistName = name;
          }
        }
      },
    )
    .addCase(credentialsEffects.logout.fulfilled, (state: RootSliceState) => {
      // reset active watchlist
      state.activeWatchlistId = null;
      state.activeWatchlistName = 'All Watchlist';
    });
};

const rootSlice = createSlice({
  initialState,
  name: SLICE_NAME,
  reducers,
  extraReducers,
});

export default rootSlice;
