import { createContext } from 'react';

import watchlistAPI from 'lib/api/watchlist';
import friendsAPI from 'lib/api/friends';
import searchAPI from 'lib/api/search';

// app constants
import { AVATAR_URL } from 'constants/api';
import { QUERY_KEY_DATA, SUGGESTIONS_PERPAGE } from './constants';
import { TrendingTimeFrame, TypeFilter } from './types';

const CONTEXT = '@discover-people';

export const actionType = {
  FETCH_TRENDING: `${CONTEXT}/FETCH_TRENDING`,
  FETCH_SUGGESTIONS: `${CONTEXT}/FETCH_SUGGESTIONS`,
  FETCH_CONTACT: `${CONTEXT}/FETCH_CONTACT`,
  FOLLOW_ALL: `${CONTEXT}/FOLLOW_ALL`,
  SEARCH_USER: `${CONTEXT}/SEARCH_USER`,
  TOGGLE_FOLLOW_USER: `${CONTEXT}/TOGGLE_FOLLOW_USER`,
};

export const INDEX_DATA_FILTER = {
  SUGGEST: 'useSuggestionList',
  CONTACT: 'useContactList',
  SEARCH: 'useSearchUser',
  TRENDING: 'useTrendingList',
};

interface IfollowUnFollowAction {
  userid: number;
  index: number;
  isFollowed: boolean;
}
interface IDiscoverPeopleContext {
  searchValue: string;
  followUnfollowAction: ({
    userid,
    index,
    isFollowed,
  }: IfollowUnFollowAction) => void;
  filter: TypeFilter;
}

export interface IMockValueProvider {
  filter: TypeFilter;
  searchValue: string;
  followUnfollowAction: () => void;
}

export const DiscoverPeopleContext =
  createContext<IDiscoverPeopleContext>(null);

// normalize data
export const normalizeData = (data, actionName) => {
  if (actionName === actionType.FETCH_SUGGESTIONS) {
    return data.map(({ user, reason, type }) => ({
      avatar: `${AVATAR_URL}/${user.user_avatar}`,
      fullname: user.user_fullname,
      isFollowed: user.follow,
      isOfficial: user.is_verified,
      verifiedStatus: user?.verified_status,
      isLoading: false,
      reason,
      type,
      id: user.userid,
      username: user.username,
    }));
  }

  if (actionName === actionType.FETCH_CONTACT) {
    return data.map((user) => ({
      avatar: `${AVATAR_URL}/${user.user_avatar}`,
      fullname: user.user_fullname,
      isFollowed: user.follow,
      isOfficial: user.is_verified,
      verifiedStatus: user?.verified_status,
      isLoading: false,
      id: user.userid,
      username: user.username,
    }));
  }

  if (actionName === actionType.SEARCH_USER) {
    return data.people.map(
      ({ desc, is_following, id, img, name, is_verified, type, ...rest }) => ({
        avatar: `${AVATAR_URL}/${img}`,
        fullname: desc,
        id,
        isFollowed: is_following,
        isOfficial: is_verified,
        verifiedStatus: rest?.verified_status,
        isLoading: false,
        reason: '',
        type,
        username: name,
      }),
    );
  }

  if (actionName === actionType.FETCH_TRENDING) {
    return data.users.map((item) => ({
      avatar: item.avatar.thumb,
      id: item.id,
      username: item.username,
      fullname: item.fullname,
      isOfficial: item.status.is_verified,
      verifiedStatus: item.status?.verified_status,
    }));
  }
  return [];
};

export const getSuggestFriends = async (page: number) => {
  const config = {
    limit: SUGGESTIONS_PERPAGE,
    page,
  };
  const { data } = await friendsAPI.getSuggestFriends(config);
  if (!data || data.error) {
    throw new Error('failed to fetch friends suggestions');
  }
  return normalizeData(data?.data || [], actionType.FETCH_SUGGESTIONS);
};

export const getTrendingUserDiscovery = async (
  timeframe: TrendingTimeFrame = TrendingTimeFrame.ALL,
  limit = 100,
  cursor?: string,
) => {
  const response = await friendsAPI.getTrending(timeframe, limit, cursor);
  const { data } = response.data;
  if (!data) {
    throw new Error('failed to fetch trending user');
  }
  return data;
};

export const getSearchUser = async (page: number, keyword: string) => {
  const response = await searchAPI.getSearchPeople(keyword, page);
  if (!response.data) {
    throw new Error('Cannot perform request');
  }

  const { data } = response.data;
  return normalizeData(data, actionType.SEARCH_USER);
};

export const getFriendSuggestionsContact = async (page: number) => {
  const config = {
    limit: SUGGESTIONS_PERPAGE,
    page,
  };
  const { data } = await friendsAPI.getSuggestFriendsContacts(
    config.limit,
    config.page,
  );
  if (!data || data.error) {
    throw new Error('failed to fetch friends suggestions');
  }

  return normalizeData(data?.data?.found || [], actionType.FETCH_CONTACT);
};

export const followUser = async (body: IfollowUnFollowAction) => {
  const { userid: id, index, isFollowed } = body;
  if (typeof index !== 'number') {
    throw new Error('index must be a number');
  }

  if (!id) {
    throw new Error('userid is required');
  }

  let targetAPI = watchlistAPI.postFollowUser;
  if (isFollowed) {
    targetAPI = watchlistAPI.postUnfollowUser;
  }
  const response = await targetAPI(id);

  if (!response.data) {
    throw new Error('Cannot perform request');
  }

  return response.data;
};

export const getCurrentQueryKey = (filter: string) => QUERY_KEY_DATA[filter];
