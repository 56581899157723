import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

// APIs
import friendsApi from 'lib/api/friends';
import watchlistApi from 'lib/api/watchlist';
import searchApi from 'lib/api/search';

// Models
import { DISCOVER_PEOPLE_STATE } from './constants';
import * as SEARCH_PEOPLE from 'lib/models/search';

import {
  GetSuggestFriendsData,
  GetSuggestFriendsResponse,
  ToggleFollowData,
  ToggleFollowResponse,
  GetSearchPeopleData,
  GetSearchPeopleResponse,
} from './slice.types';

interface IDiscoverPeopleState {
  getSuggestFriends: {
    data: GetSuggestFriendsData;
    isLoading: boolean;
    error: string | null;
    message: string;
  };
  followUser: {
    data: ToggleFollowData | {};
    isLoading: boolean;
    error: string | null;
    message: string;
  };
  getSearchPeople: {
    data: GetSearchPeopleData;
    isLoading: boolean;
    error: string | null;
    message: string;
  };
}

// initial state
const initialState: IDiscoverPeopleState = {
  getSuggestFriends: {
    data: [],
    isLoading: true,
    error: null,
    message: '',
  },
  followUser: {
    data: {},
    isLoading: true,
    error: null,
    message: '',
  },
  getSearchPeople: {
    data: {
      company: [],
      sector: [],
      insider: [],
      chat: [],
      user_more: 0,
      company_more: 0,
      people: [],
    },
    isLoading: true,
    error: null,
    message: '',
  },
};

// selectors
const discoverPeopleState = (state) => state.onboarding.discoverPeople;
export const selectors = createSelector(
  discoverPeopleState,
  (discoverPeopleState: IDiscoverPeopleState) => ({
    getSuggestFriends: discoverPeopleState.getSuggestFriends,
    postFollowUser: discoverPeopleState.followUser,
    getSearchPeople: discoverPeopleState.getSearchPeople,
  }),
  (state) => state,
);

// action types
const CONTEXT = '@onboarding/discoverPeople';

const actionType = {
  GET_SUGGEST_FRIENDS: `${CONTEXT}/GET_SUGGEST_FRIENDS`,
  FOLLOW_USER: `${CONTEXT}/FOLLOW_USER`,
  GET_SEARCH_PEOPLE: `${CONTEXT}/GET_SEARCH_PEOPLE`,
};

type UserSuggestFilter =
  | 'FILTER_UNSPECIFIED'
  | 'FILTER_ALL'
  | 'FILTER_STREAM'
  | 'FILTER_ONBOARDING';

// side effects
export const effects = {
  // getSuggestFriends -------------------------------------------------------------------
  getSuggestFriends: createAsyncThunk<
    GetSuggestFriendsResponse,
    { limit: number; page: number; filter: UserSuggestFilter }
  >(actionType.GET_SUGGEST_FRIENDS, async (requestBody) => {
    try {
      const response = await friendsApi.getSuggestFriends(requestBody);

      if (response.data) {
        const { data, message, error } = response.data;
        if (error) {
          return { message, error };
        }

        return { data, message };
      }
      throw new Error('Attempt to get friends suggest Failed');
    } catch (error) {
      return { error };
    }
  }),
  // postFollowUser -------------------------------------------------------------------
  toggleFollowUser: createAsyncThunk<
    ToggleFollowResponse,
    {
      index: number;
      userid: string;
      stateKey: string;
      type: 'FOLLOW' | 'UNFOLLOW';
    }
  >(
    actionType.FOLLOW_USER,
    async ({ index, userid, stateKey, type = 'FOLLOW' }) => {
      try {
        if (!userid) {
          throw new Error('userid is required');
        }

        let targetAPI = watchlistApi.postFollowUser;
        if (type === 'UNFOLLOW') {
          targetAPI = watchlistApi.postUnfollowUser;
        }
        const response = await targetAPI(userid);
        const { data, message, error } = response.data;

        if (!response.data) {
          throw new Error('Cannot perform request');
        }

        const dataUserChanged = {
          userid,
          followed: type,
        };

        return {
          data,
          error,
          message,
          index,
          stateKey,
          dataUserChanged,
        };
      } catch (error) {
        return error.message;
      }
    },
  ),
  // getSearchPeople -------------------------------------------------------------------
  getSearchPeople: createAsyncThunk<
    GetSearchPeopleResponse,
    { keyword: string; page: number }
  >(actionType.GET_SEARCH_PEOPLE, async ({ keyword, page }) => {
    try {
      const response = await searchApi.getSearchPeople(keyword, page);

      if (response.data) {
        const { data, message, error } = response.data;

        if (error) {
          return { message, error };
        }

        return { data, message };
      }
      throw new Error('Attempt to search friends Failed');
    } catch (error) {
      return { error };
    }
  }),
};

// reducers
const reducers = {
  clearSearch: (state) => {
    state.getSearchPeople.data = {
      company: [],
      sector: [],
      insider: [],
      chat: [],
      user_more: 0,
      company_more: 0,
      people: [],
    };
  },
};

// extra reducers
const extraReducers = (builder) => {
  builder
    .addCase(
      effects.getSuggestFriends.pending,
      (state: IDiscoverPeopleState) => {
        state.getSuggestFriends.isLoading = true;
      },
    )
    .addCase(
      effects.getSuggestFriends.fulfilled,
      (state: IDiscoverPeopleState, action) => {
        const { data, message, error } = action.payload;
        if (error) {
          state.getSuggestFriends.error = error;
        } else {
          state.getSuggestFriends.data = data;
        }
        state.getSuggestFriends.message = message;
        state.getSuggestFriends.isLoading = false;
      },
    )
    .addCase(
      effects.getSuggestFriends.rejected,
      (state: IDiscoverPeopleState, action) => {
        state.getSuggestFriends.error = action.payload.error;
        state.getSuggestFriends.isLoading = false;
      },
    )

    .addCase(effects.toggleFollowUser.pending, (state) => {
      state.followUser.isLoading = true;
    })
    .addCase(
      effects.toggleFollowUser.fulfilled,
      (state: IDiscoverPeopleState, action) => {
        const { data, message, error, index, stateKey, dataUserChanged } =
          action.payload;

        if (error) {
          state.followUser.error = error;
        } else {
          const isFollowed = dataUserChanged.followed === 'FOLLOW';
          const selectedState = state[stateKey].data;

          if (stateKey === DISCOVER_PEOPLE_STATE.SUGGEST) {
            selectedState[index].user.follow = isFollowed;
          } else if (stateKey === DISCOVER_PEOPLE_STATE.SEARCH) {
            selectedState.people[index].is_following = isFollowed;
          }

          state.followUser.data = data;
        }

        state.followUser.message = message;
        state.followUser.isLoading = false;
      },
    )
    .addCase(
      effects.toggleFollowUser.rejected,
      (state: IDiscoverPeopleState, action) => {
        state.followUser.error = action.payload.error;
        state.followUser.isLoading = false;
      },
    )

    .addCase(effects.getSearchPeople.pending, (state) => {
      state.getSearchPeople.isLoading = true;
    })
    .addCase(
      effects.getSearchPeople.fulfilled,
      (state: IDiscoverPeopleState, action) => {
        const { data, message, error } = action.payload;
        if (error) {
          state.getSearchPeople.error = error;
        } else {
          state.getSearchPeople.data = data;
        }
        state.getSearchPeople.message = message;
        state.getSearchPeople.isLoading = false;
      },
    )
    .addCase(
      effects.getSearchPeople.rejected,
      (state: IDiscoverPeopleState, action) => {
        state.getSearchPeople.error = action.payload.error;
        state.getSearchPeople.isLoading = false;
      },
    );
};

// Create the slice
const discoverPeopleSlice = createSlice({
  name: 'discoverPeople',
  initialState,
  reducers,
  extraReducers,
});

export default discoverPeopleSlice;
