import create from 'zustand';
import searchApi from 'lib/api/search';
import { persist, devtools } from 'zustand/middleware';
import { search, filter, checkFilterAvailability } from './utils';
import {
  OrderSearchValue,
  OrderSearchOption,
  OrderFilterValue,
  iRecord as OrderItem,
} from './types';

interface OrderTableInterface {
  tab: string;
  isFilterActive: boolean;
  orderSearchValue: OrderSearchValue;
  orderSearchOptions: OrderSearchOption[];
  orderFilterValue: OrderFilterValue;
  orderSearchFilterResult: OrderItem[];
  setOrderTab: (tab: string) => void;
  setOrderSearchValue: (orderSearchValue: OrderSearchValue | null) => void;
  setOrderSearchOptions: (stock: string | null) => void;
  setOrderFilterValue: (orderFilterValue: OrderFilterValue | null) => void;
  searchAndFilter: (source: OrderItem[]) => void;
  resetOrderTableStore: () => void;
}

const initialState = {
  tab: 'order',
  isFilterActive: false,
  orderSearchValue: {
    action: '',
    stock: '',
    text: '',
  },
  orderSearchOptions: [],
  orderFilterValue: {
    status: [],
    orderType: [],
    expiry: [],
    accountType: [],
  },
  orderSearchFilterResult: [],
};

const orderTableStore = create<OrderTableInterface>()(
  devtools(
    persist(
      (set, get) => ({
        ...initialState,
        setOrderTab: (tab) => {
          set({ tab });
        },
        setOrderSearchValue: (orderSearchValue) => {
          if (orderSearchValue === null) {
            set({ orderSearchValue: initialState.orderSearchValue });
            return;
          }
          set({ orderSearchValue });
        },
        setOrderSearchOptions: async (stock) => {
          if (stock === null) {
            set({ orderSearchOptions: initialState.orderSearchOptions });
            return;
          }

          try {
            const { data } = await searchApi.getSearchEmiten(stock);
            if (data?.data?.company) {
              set({
                orderSearchOptions: data.data.company.map((d) => ({
                  name: d?.name,
                  desc: d?.desc,
                })),
              });
            }
          } catch (e) {
            set({ orderSearchOptions: initialState.orderSearchOptions });
          }
        },
        setOrderFilterValue: (orderFilterValue) => {
          if (orderFilterValue === null) {
            set({ orderFilterValue: initialState.orderFilterValue });
            return;
          }
          set({ orderFilterValue });
        },
        searchAndFilter: (source) => {
          if (source === null) {
            set({ orderSearchFilterResult: source });
            return;
          }

          const { orderSearchValue, orderFilterValue } = get();

          const searchResult = search(source, orderSearchValue);
          const searchFilterResult = filter(searchResult, orderFilterValue);

          set({
            orderSearchFilterResult: searchFilterResult,
            isFilterActive: checkFilterAvailability(orderFilterValue),
          });
        },
        resetOrderTableStore: () => {
          set(initialState);
        },
      }),
      {
        name: 'zustandOrderTableStore',
        getStorage: () => localStorage,
        partialize: (state) => ({
          isFilterActive: state.isFilterActive,
          orderSearchValue: state.orderSearchValue,
          orderFilterValue: state.orderFilterValue,
        }),
      },
    ),
  ),
);

export default orderTableStore;
