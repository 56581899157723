import { PROJECT_FOLDER } from 'constants/app';
import { useEffect } from 'react';
import { Platform } from 'typings/platform';

/** Shares non-confidential data to the window object for Nx module federation */
const useSharedWindowData = () => {
  useEffect(() => {
    window.webSharedData = {
      projectFolder: PROJECT_FOLDER,
      platform: Platform.Web,
    };
  }, []);
};

export default useSharedWindowData;
