import { useEffect } from 'react';
import auth from 'utils/auth';
import { onLoaded, onOpen, onReceiveMessage, setNewSession } from 'utils/crisp';
import { isLoggedInSelector } from 'lib/entities/credentials';
import { useSelector } from 'react-redux';
import { CRISP_ID, isClient } from 'constants/app';
import { decodeBase64ToJS } from 'utils/general';
import { profileSelector } from 'lib/entities/profile';
import { Crisp } from 'crisp-sdk-web';

function useCrisp() {
  const isLoggedIn = useSelector(isLoggedInSelector);

  const dataProfile = useSelector(profileSelector);

  // @ts-ignore
  const supportId = dataProfile?.support?.id;

  useEffect(() => {
    Crisp.configure(CRISP_ID, {
      safeMode: true,
    });

    const crispData = localStorage.getItem('crisp');

    if (crispData) {
      const crispDecoded = decodeBase64ToJS(crispData);

      // @ts-ignore
      if (crispDecoded?.id) {
        // @ts-ignore
        Crisp.setTokenId(crispDecoded.id);
      }
    }

    // configuration on crisp load
    if (isLoggedIn) {
      onLoaded();
    }
    // configuration on open
    onOpen();
    // configuration on receive message
    onReceiveMessage();
  }, []);

  useEffect(() => {
    try {
      if (isClient() && isLoggedIn) {
        const crispData = localStorage.getItem('crisp');
        // @ts-ignore
        const { email, avatar, username } = auth.getUserAccess();

        if (supportId) {
          Crisp.setTokenId(supportId);
        }

        let data: Record<string, any> = { email, avatar, username };
        if (crispData) {
          const crispDecoded = decodeBase64ToJS(crispData);
          // @ts-ignore
          const phone = crispDecoded?.phone || dataProfile?.phone;
          if (phone) {
            data = { ...data, phone };
          }
        }

        setNewSession(data);
        localStorage.removeItem('crisp_visitor');
      }
    } catch (err) {
      console.log('Crisp Disabled');
    }
  }, [isLoggedIn, supportId]);
}

export default useCrisp;
