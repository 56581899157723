import Script from 'next/script';

const Firebase = () => (
  <>
    <Script
      strategy="beforeInteractive"
      src="https://www.gstatic.com/firebasejs/7.6.2/firebase-app.js"
    />
    <Script
      strategy="beforeInteractive"
      src="https://www.gstatic.com/firebasejs/7.6.2/firebase-analytics.js"
    />
  </>
);

export default Firebase;
