import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from '@reduxjs/toolkit';

// api
import userApi from 'lib/api/user';

// module profile
import profile from './profile';

// initial state
const initialState = {
  data: {}, // TODO
  isLoading: false,
  error: null,
  message: '',
  userSkipOnboarding: {
    data: [],
    success: false, // to know response API is success or not
    isLoading: true,
    error: null,
    message: '',
  },
};

// Selectors -------------------------------------------------------------------
export const selectors = createSelector(
  (state: any) => ({
    data: state.entities.onboarding.data,
    isLoading: state.entities.onboarding.isLoading,
    error: state.entities.onboarding.error,
    message: state.entities.onboarding.message,
    userSkipOnboarding: state.entities.onboarding.userSkipOnboarding,
  }),
  (state) => state,
);

// Actions ---------------------------------------------------------------------
// types
const CONTEXT = '@redux/user';

const actionType = {
  ONBOARDING_SKIP: `${CONTEXT}/ONBOARDING_SKIP`,
  UPLOAD_IMAGE_AVATAR: `${CONTEXT}/UPLOAD_IMAGE_AVATAR`,
};

// effects
export const effects = {
  // userSkipOnboarding -------------------------------------------------------------------
  userSkipOnboarding: createAsyncThunk<any, any>(
    actionType.ONBOARDING_SKIP,
    async (step, { dispatch }) => {
      try {
        const response = await userApi.userSkipOnboarding(step);
        if (response.data) {
          const { data, message, error } = response.data;
          if (error) {
            return { message, error };
          }

          // to update state profile
          dispatch(
            // @ts-ignore
            profile.actions.updateOnboardingStep({
              step,
              isClickedPreviousButton: false,
            }),
          );

          return { data, message };
        }
        throw new Error('Attempt to skip step onboarding Failed');
      } catch (error) {
        return { error };
      }
    },
  ),
};

// Reducer ---------------------------------------------------------------------
const reducers = {
  /**
   * resetUserSkipOnBoarding
   * change resetUserSkipOnBoarding state to initialState
   */
  resetUserSkipOnBoarding: (draft) => {
    draft.userSkipOnboarding.data = [];
    draft.userSkipOnboarding.success = false;
    draft.userSkipOnboarding.isLoading = true;
    draft.userSkipOnboarding.error = null;
    draft.userSkipOnboarding.message = '';
  },
};

// Extra Reducer ---------------------------------------------------------------
const extraReducers = (builder) => {
  builder
    .addCase(effects.userSkipOnboarding.pending, (state, action) => {
      state.userSkipOnboarding.isLoading = true;
    })
    .addCase(effects.userSkipOnboarding.fulfilled, (state, action) => {
      const { data, message, error } = action.payload;

      if (error) {
        state.userSkipOnboarding.error = error;
      } else {
        // to know response API is success or not
        state.userSkipOnboarding.success = true;
        state.userSkipOnboarding.data = data;
      }
      state.userSkipOnboarding.message = message;
      state.userSkipOnboarding.isLoading = false;
    })
    .addCase(effects.userSkipOnboarding.rejected, (state, action) => {
      state.userSkipOnboarding.error = action.payload.error;
      state.userSkipOnboarding.isLoading = false;
    });
};

// Slice -----------------------------------------------------------------------
const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers,
  extraReducers,
});

export default onboardingSlice;
