import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from '@reduxjs/toolkit';

// APIs
import tippingApi from 'lib/api/tipping';

// Initial state
const initialState = {
  detail: {},
  data: {},
  isLoading: false,
  error: null,
  message: '',
};

// Selectors
export const selector = createSelector(
  (state) => state.entities.tipping,
  (tipping) => ({
    data: tipping.data,
    isLoading: tipping.isLoading,
    error: tipping.error,
    detail: tipping.detail,
  }),
);

// Action types
const CONTEXT = '@redux/entities';

const actionType = {
  GET_TIPPING_JAR: `${CONTEXT}/GET_TIPPING_JAR`,
  CLAIM_TIPPING_BALANCE: `${CONTEXT}/CLAIM_TIPPING_BALANCE`,
  SEND_TIPPING: `${CONTEXT}/SEND_TIPPING`,
  UPDATE_GOPAY_ACCOUNT: `${CONTEXT}/UPDATE_GOPAY_ACCOUNT`,
  GET_TIPPING_DETAIL: `${CONTEXT}/GET_TIPPING_DETAIL`,
  SEND_TIPPING_GENERAL: `${CONTEXT}/SEND_TIPPING_GENERAL`,
};

// Effects

export const effects = {
  sendTipping: createAsyncThunk(actionType.SEND_TIPPING, async (tipData) => {
    try {
      const response = await tippingApi.sendTippingStream(tipData);

      if (!response.data) {
        throw new Error('Attempt to send tip failed');
      }

      const { error, message, data } = response.data;

      if (error) {
        return { error, message };
      }

      return { data, message };
    } catch (error) {
      return { error };
    }
  }),
  sendTippingGeneral: createAsyncThunk(actionType.SEND_TIPPING_GENERAL, async (tipData) => {
    try {
      const response = await tippingApi.sendTipping(tipData);

      if (!response.data) {
        throw new Error('Attempt to send tip failed');
      }

      const { error, message, data } = response.data;

      if (error) {
        return { error, message };
      }

      return { data, message };
    } catch (error) {
      return { error };
    }
  }),
};

// Reducers
const reducers = {};

const extraReducers = {
  // send tipping, claim tipping, update tipping doesn't need to update reducer
};

const tipping = createSlice({
  name: 'tipping',
  initialState,
  reducers,
  extraReducers,
});

export default tipping;
