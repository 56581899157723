import { useEffect, useState } from 'react';
import initPrimus from 'global/WebSocket';
// WebSocket
import socketSlice, { effects as wsEffects } from 'global/WebSocket/slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectors } from 'lib/entities/credentials';

/**
 * @summary open websocket connection
 * @param {Object} primus - primus object
 * @returns {[*, () => void]}
 */

function useWebSocket(scriptLoaded: boolean): any {
  const [ws, setWs] = useState(null);
  const { isLoggedIn } = useSelector(selectors);
  const dispatch = useDispatch();

  const clearPriceAlert = () => {
    dispatch(socketSlice.actions.clearAlert());
    localStorage.removeItem('price-alert');
  };

  useEffect(() => {
    if (!scriptLoaded) {
      return;
    }

    if (isLoggedIn) {
      const primus = initPrimus();
      setWs(primus);
      // @ts-ignore
      dispatch(wsEffects.initWebsocket(primus));
      // @ts-ignore
      dispatch(wsEffects.initListenData(primus));
    } else {
      clearPriceAlert();
      ws?.end();
    }
  }, [isLoggedIn, scriptLoaded]);

  return ws;
}

export default useWebSocket;
