import color from '@deprecated-ui/_style/color';
import { css } from 'styled-components';

const tooltipStyles = css`
  .tooltip-overlay-green {
    cursor: pointer;
    .ant-tooltip-arrow-content {
      background: ${color.primary};
    }
    .ant-tooltip-inner { 
      border-radius: 4px;
    }
  }

  .tooltip-overlay-dark {
    cursor: pointer;
    .ant-tooltip-arrow-content {
      background: ${color.highempahis};
    }
    .ant-tooltip-inner { 
      background: ${color.highempahis};
      border-radius: 4px;
    }
  }

  .tooltip-overlay-red {
    cursor: pointer;
    .ant-tooltip-arrow-content {
      background: ${color.sunsetOrange};
    }
    .ant-tooltip-inner { 
      background: ${color.sunsetOrange};
      border-radius: 4px;
    }
  }

  .tooltip-overlay-transparent {
    cursor: pointer;
    padding-top: 0 !important;
    .ant-tooltip-arrow-content {
      display: none;
      background: transparent;
      box-shadow: unset;

      ::before {
        transparent;
      }
    }
    
    .ant-tooltip-inner { 
      background: transparent;
      border-radius: 4px;
    }
  }
`;

export default tooltipStyles;
