import axios from 'axios';
import {
  FLIPT_TOKEN,
  FLIPT_URL_HOST,
  FLIPT_CHARTBIT_TOKEN,
  FLIPT_FINANCIAL_DATA_NAMESPACE,
  FLIPT_FINANCIAL_DATA_TOKEN,
  FLIPT_DESKTOP_NAMESPACE,
  FLIPT_DESKTOP_TOKEN,
} from './constants/env';

export const fliptApi = axios.create({
  baseURL: FLIPT_URL_HOST,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

fliptApi.interceptors.request.use((config) => {
  if (FLIPT_TOKEN) {
    // eslint-disable-next-line no-param-reassign
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${FLIPT_TOKEN}`,
    };
  }
  return config;
});

export const fliptChartbitApi = axios.create({
  baseURL: FLIPT_URL_HOST,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

fliptChartbitApi.interceptors.request.use((config) => {
  if (FLIPT_CHARTBIT_TOKEN) {
    // eslint-disable-next-line no-param-reassign
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${FLIPT_CHARTBIT_TOKEN}`,
    };
  }
  return config;
});

export const fliptFinancialDataApi = axios.create({
  baseURL: FLIPT_URL_HOST,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

fliptFinancialDataApi.interceptors.request.use((config) => {
  if (FLIPT_FINANCIAL_DATA_TOKEN) {
    // eslint-disable-next-line no-param-reassign
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${FLIPT_FINANCIAL_DATA_TOKEN}`,
    };
  }
  return config;
});

export const fliptDesktopApi = axios.create({
  baseURL: FLIPT_URL_HOST,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

fliptDesktopApi.interceptors.request.use((config) => {
  if (FLIPT_DESKTOP_NAMESPACE) {
    // eslint-disable-next-line no-param-reassign
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${FLIPT_DESKTOP_TOKEN}`,
    };
  }
  return config;
});
