import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from '@reduxjs/toolkit';

// api
import { AVATAR_URL } from 'constants/api';
import userApi from 'lib/api/user';

// model
import trending from 'lib/models/user/trending';

// initial state
export const initialState = {
  selected: trending.schema,
  list: [],
  isLoading: false,
  error: null,
  message: '',
};

// Selectors -------------------------------------------------------------------
export const selectors = createSelector(
  (state) => ({
    selected: state.user.trending.selected,
    list: state.user.trending.list,
    isLoading: state.user.trending.isLoading,
    error: state.user.trending.error,
    message: state.user.trending.message,
  }),
  (state) => state,
);

// Actions ---------------------------------------------------------------------
// types
const CONTEXT = '@redux/user';

export const actionType = {
  FETCH_TRENDING: `${CONTEXT}/FETCH_TRENDING`,
};

// effects
export const effects = {
  // getTrendinguser -------------------------------------------------------------------
  getTrendingUser: createAsyncThunk(actionType.FETCH_TRENDING, async () => {
    try {
      const response = await userApi.getTrendingUser({ timeRange: '1W' });
      if (response.data) {
        const { data, message, error } = response.data;
        if (error) {
          return { message, error };
        }
        // sanitize trending user data
        const sanitizedData = data.map((user) => ({
          ...user,
          // Concat User avatar url with base avatar URL
          user_avatar: `${AVATAR_URL}/${user.user_avatar}`,
          user_avatar_medium: `${AVATAR_URL}/${user.user_avatar_medium}`,
          user_avatar_thumb: `${AVATAR_URL}/${user.user_avatar_thumb}`,
        }));
        return { data: sanitizedData, message };
      }
      throw new Error('Attempt to fetch Trending user Failed');
    } catch (error) {
      return { error };
    }
  }),
};

// Reducer ---------------------------------------------------------------------
const reducers = {};

// Extra Reducer ---------------------------------------------------------------
const extraReducers = {
  // Get Trending User ---------------------------------------------------------
  [effects.getTrendingUser.pending]: (state) => {
    state.isLoading = true;
  },

  [effects.getTrendingUser.fulfilled]: (state, action) => {
    const { data, message, error } = action.payload;
    if (error) {
      state.error = error;
    } else {
      state.list = data;
    }
    state.message = message;
    state.isLoading = false;
  },

  [effects.getTrendingUser.rejected]: (state, action) => {
    state.error = action.payload.error;
    state.message = action.payload.message;
    state.isLoading = false;
  },
};

// Slice -----------------------------------------------------------------------
const trendingSlice = createSlice({
  name: 'trending',
  initialState,
  reducers,
  extraReducers,
});

export default trendingSlice;
