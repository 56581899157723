import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

// API
import watchlist from 'lib/api/watchlist';

// Company reducer
import {
  effects as companyEffects,
  STATE_SUGGESTION,
} from 'lib/entities/company';

// Initial State
const initialState = {
  suggestions: {
    data: [],
    isLoading: false,
    error: null,
    message: '',
  },
};

export const selectors = createSelector(
  (state) => ({
    suggestions: state.onboarding.discoverStock.suggestions,
  }),
  (state) => state,
);

// Action Types
const CONTEXT = '@onboarding/discoverStock';

const actionType = {
  FETCH_SUGGESTION: `${CONTEXT}/FETCH_SUGGESTION`,
};

// Effects

export const effects = {
  getSuggestionCompany: createAsyncThunk(
    actionType.FETCH_SUGGESTION,
    async (watchlistId) => {
      try {
        const response = await watchlist.getCompanySuggestions(watchlistId);

        if (response.data) {
          const { data, message, error } = response.data;

          if (error) {
            return { message, error };
          }

          return { data, message };
        }

        throw new Error('Attempt to fetch Suggestion company Failed');
      } catch (error) {
        return { error };
      }
    },
  ),
};

// Reducers
const reducers = {};

// Extra reducers
const extraReducers = {
  [effects.getSuggestionCompany.pending]: (state) => {
    state.suggestions.isLoading = true;
  },

  [effects.getSuggestionCompany.fulfilled]: (state, action) => {
    const { data, error, message } = action.payload;

    if (error) {
      state.suggestions.error = error;
    } else {
      state.suggestions.data = data;
    }

    state.suggestions.isLoading = false;
    state.suggestions.message = message;
  },

  [effects.getSuggestionCompany.rejected]: (state, action) => {
    state.suggestions.error = action.payload.error;
    state.suggestions.isLoading = false;
  },

  // TODO: Listen only if given parameter to update
  // success follow company
  [companyEffects.postFollowCompany.fulfilled]: (state, action) => {
    // find index of company by id
    // update followed value to `1`
    const STATUS_FOLLOWING = 1;

    const { data, stateToUpdate } = action.payload;

    if (data && stateToUpdate === STATE_SUGGESTION) {
      const { companyid } = data;
      // const newSuggestions = state.suggestions.data.map((s) => ({ ...s }));
      const newSuggestions = state.suggestions.data;
      const companyIndex = newSuggestions.findIndex(
        ({ id }) => Number(id) === Number(companyid),
      );

      // there's company in suggestion list
      if (companyIndex > -1) {
        // update `companyData` following status to `1` alias followed
        state.suggestions.data[companyIndex].followed = STATUS_FOLLOWING;
      }
    }
  },

  // success unfollow company
  [companyEffects.postUnfollowCompany.fulfilled]: (state, action) => {
    // find index of company by id
    // update followed value to 0
    const STATUS_UNFOLLOWING = 0;

    const { data, stateToUpdate } = action.payload;

    if (data && stateToUpdate === STATE_SUGGESTION) {
      const { companyid } = data;
      // const newSuggestions = state.suggestions.data.map((s) => ({ ...s }));
      const newSuggestions = state.suggestions.data;
      const companyIndex = newSuggestions.findIndex(
        ({ id }) => Number(id) === Number(companyid),
      );

      // there's company in suggestion list
      if (companyIndex > -1) {
        // update `companyData` following status to `0` alias unfollowed
        state.suggestions.data[companyIndex].followed = STATUS_UNFOLLOWING;
      }
    }
  },
};

const discoverStockSlice = createSlice({
  name: 'discoverStock',
  initialState,
  reducers,
  extraReducers,
});

export default discoverStockSlice;
