import { Reducer } from 'react';
import { AnyAction, combineReducers } from 'redux';

// types
import { WatchlistState } from './types';
// slices
import collectionSlice from './Collection/slice';
import rootSlice from './slice';
import watchlistTableReducer from './WatchlistTable/reducer';

const watchlistFeatureReducer: Reducer<
  WatchlistState,
  AnyAction
> = combineReducers({
  root: rootSlice.reducer,
  collection: collectionSlice.reducer,
  // combined
  watchlistTable: watchlistTableReducer,
});

export default watchlistFeatureReducer;
