/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable camelcase */
import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import * as notificationAPI from 'lib/api/notification';
import schema from 'lib/models/notification';
import {
  disorganizeNormalizedNotification,
  normalizeNotification,
} from 'utils/notification';
import { CommonPayload } from '../../../../@types/action-payload';
import {
  GroupNotificationParams,
  MarkAsReadParams,
  NotificationPayload,
  PreviouseNotificationParams,
} from './slice.types';

// Action type
const CONTEXT = '@global/notification';
const actionType = {
  GET_UNREAD_NOTIFICATION_DATA: `${CONTEXT}/GET_UNREAD_NOTIFICATION_DATA`,
  GET_NOTIFICATION_DATA: `${CONTEXT}/GET_NOTIFICATION_DATA`,
  GET_PREVIOUS_NOTIFICATION_DATA: `${CONTEXT}/GET_PREVIOUS_NOTIFICATION_DATA`,
  GET_NOTIFICATION_DATA_GROUPED_BY_TYPE: `${CONTEXT}/GET_NOTIFICATION_DATA_GROUPED_BY_TYPE`,
  MARK_AS_READ_NOTIFICATION_DATA: `${CONTEXT}/MARK_AS_READ_NOTIFICATION_DATA`,
};

interface NotificationState {
  error?: unknown;
  isLoading: boolean;
  isLoadingMore: boolean;
  broadcast: typeof schema;
  unread: number;
  isReading: boolean;
  notificationStacks: any[];
  lastId: number | null;
  isActivityChanged: boolean;
  isMentionChanged: boolean;
  isReportChanged: boolean;
  isTippingChanged: boolean;
}

// Initial state
export const initialState: NotificationState = {
  error: null,
  isLoading: false,
  isLoadingMore: false,
  broadcast: schema,
  unread: 0,
  isReading: false,
  notificationStacks: [],
  lastId: null,
  isActivityChanged: false,
  isMentionChanged: false,
  isReportChanged: false,
  isTippingChanged: false,
};

// Selectors
const notificationSelector = (state) => state.notification;
export const selectors = createSelector(
  notificationSelector,
  (notificationState) => ({
    ...notificationState,
  }),
);

// Effects
export const effects = {
  getNotificationData: createAsyncThunk<CommonPayload<NotificationPayload>>(
    actionType.GET_NOTIFICATION_DATA,
    async () => {
      try {
        const response = await notificationAPI.getNotification();

        if (!response.data) {
          throw new Error('Attempt to fetch notification data is failed');
        }

        const { data, error } = response.data;

        if (error) {
          return { error };
        }

        return data;
      } catch (error) {
        return { error };
      }
    },
  ),

  getNotificationDataGroupedByType: createAsyncThunk<
    CommonPayload<NotificationPayload>,
    GroupNotificationParams
  >(actionType.GET_NOTIFICATION_DATA_GROUPED_BY_TYPE, async (params) => {
    try {
      const { group = [] } = params;

      const queues = group.map(async (type) => {
        const response = await notificationAPI.getNotification(type);
        if (!response.data) {
          throw new Error('Attempt to fetch notification data is failed');
        }

        const { data, error } = response.data;

        if (error) {
          return { error };
        }

        return data.result;
      });

      const results = await Promise.all(queues);
      const normalizedResults = results.flat();

      return {
        result: normalizedResults,
      };
    } catch (error) {
      return { error };
    }
  }),

  markAsRead: createAsyncThunk<CommonPayload, MarkAsReadParams>(
    actionType.MARK_AS_READ_NOTIFICATION_DATA,
    async (params) => {
      try {
        const { type = [] } = params;
        const response = await notificationAPI.markAsRead(type);
        return response.data ? response.data : null;
      } catch (error) {
        return { error };
      }
    },
  ),

  getUnreadNotificationNumber: createAsyncThunk<
    CommonPayload<{ unread: number }>
  >(
    actionType.GET_UNREAD_NOTIFICATION_DATA,
    // @ts-ignore
    async () => {
      try {
        const response = await notificationAPI.getUnreadNotificationNumber();
        // Mock unread notification
        // const response = await Promise.resolve({ data: { data: { unread: 11 } } });
        if (!response.data) {
          throw new Error('Attempt to fetch notification data is failed');
        }

        const { data, error } = response.data;

        if (error) {
          return { error };
        }

        return data;
      } catch (error) {
        return { error };
      }
    },
  ),

  getPreviousNotificationData: createAsyncThunk<
    CommonPayload<NotificationPayload>,
    PreviouseNotificationParams
  >(actionType.GET_PREVIOUS_NOTIFICATION_DATA, async (params) => {
    try {
      const { limit, types = [], lastId } = params;
      const response = await notificationAPI.getNotification(
        types,
        limit,
        lastId,
      );

      if (!response.data) {
        throw new Error('Attempt to fetch notification data is failed');
      }

      const { data, error } = response.data;

      if (error) {
        return { error };
      }

      return data;
    } catch (error) {
      return { error };
    }
  }),
};

// Reducers
const reducers = {
  setUnreadNotificationNumber(draft, action) {
    draft.unread = action.payload;
  },

  // @deprecated | Not used in the project
  appendNotificationDataFromSocket(draft, action) {
    const { notificationType, index, data } = action.payload;

    if (index) {
      draft.broadcast.content[notificationType][index] = data;
    } else {
      draft.broadcast.content[notificationType].push(data);
    }

    draft.unread += 1;

    switch (notificationType) {
      case 'other':
        draft.isActivityChanged = true;
        break;
      case 'stream':
        draft.isMentionChanged = true;
        break;
      case 'company':
        draft.isReportChanged = true;
        break;
      case 'tipping':
        draft.isTippingChanged = true;
        break;
      default:
        break;
    }
  },
  // @deprecated | Not used in the project
  removeNotificationMarkers(draft, action) {
    switch (action.payload) {
      case 'notification':
        draft.isActivityChanged = false;
        draft.isMentionChanged = false;
        draft.isReportChanged = false;
        draft.isTippingChanged = false;
        break;
      case 'activity':
        draft.isActivityChanged = false;
        break;
      case 'mention':
        draft.isMentionChanged = false;
        break;
      case 'report':
        draft.isReportChanged = false;
        break;
      case 'tipping':
        draft.isTippingChanged = false;
        break;
      default:
        break;
    }
  },
  // @deprecated | Not used in the project
  emptyUnreadNotificationData(draft) {
    draft.unread = 0;
  },
  // @deprecated | Not used in the project
  markAsReadNotificationItem(draft, action) {
    const indexCompany = draft.broadcast.content.company.findIndex(
      (item) => item.id === action.payload,
    );

    const indexActivity = draft.broadcast.content.other.findIndex(
      (item) => item.id === action.payload,
    );

    const indexStream = draft.broadcast.content.stream.findIndex(
      (item) => item.id === action.payload,
    );

    const indexTipping = draft.broadcast.content.tipping.findIndex(
      (item) => item.id === action.payload,
    );

    // Notifiction ID is inside company (report) section
    if (indexCompany > -1) {
      draft.broadcast.content.company[indexCompany].status = 1;
    }

    // Notifiction ID is inside activity (other) section
    if (indexActivity > -1) {
      draft.broadcast.content.other[indexActivity].status = 1;
    }

    // Notifiction ID is inside stream (mention) section
    if (indexStream > -1) {
      draft.broadcast.content.stream[indexStream].status = 1;
    }

    // Notification ID is inside tipping section
    if (indexTipping > -1) {
      draft.broadcast.content.tipping[indexTipping].status = 1;
    }
  },
  // @deprecated | Not used in the project
  pushStackNotificationData(draft, action) {
    draft.notificationStacks = [action.payload];
  },
};

// Extra reducers
const extraReducers = (builder) => {
  // Get notification data
  builder
    .addCase(
      effects.getNotificationData.pending,
      (state: NotificationState) => {
        state.isLoading = true;
      },
    )
    .addCase(
      effects.getNotificationData.fulfilled,
      (
        state: NotificationState,
        action: PayloadAction<NotificationPayload>,
      ) => {
        if (action.payload.error) {
          state.error = action.payload.error;
        } else {
          state.broadcast = normalizeNotification(action.payload.result);
          state.unread = action.payload.unread;
          state.isLoading = false;
          // handle last id
          const { result } = action.payload;
          if (result && result.length > 0) {
            state.lastId = result[result.length - 1].id;
          }
        }
      },
    )
    .addCase(
      effects.getNotificationData.rejected,
      (state: NotificationState, action: PayloadAction<CommonPayload>) => {
        state.isLoading = false;
        state.error = action.payload;
      },
    )

    // Get previous notification data
    .addCase(
      effects.getPreviousNotificationData.pending,
      (state: NotificationState) => {
        state.isLoadingMore = true;
      },
    )
    .addCase(
      effects.getPreviousNotificationData.fulfilled,
      (
        state: NotificationState,
        action: PayloadAction<NotificationPayload>,
      ) => {
        if (action.payload.error) {
          state.error = action.payload.error;
        } else {
          const disorganizedContent = disorganizeNormalizedNotification(
            state.broadcast.content,
          );

          const results = [...disorganizedContent, ...action.payload.result];
          state.isLoadingMore = false;
          state.broadcast = normalizeNotification(results);
        }
      },
    )
    .addCase(
      effects.getPreviousNotificationData.rejected,
      (state: NotificationState, action: PayloadAction<CommonPayload>) => {
        const { error } = action.payload;
        state.isLoadingMore = false;
        state.error = error;
      },
    )

    // Get notification data grouped by type
    .addCase(
      effects.getNotificationDataGroupedByType.pending,
      (state: NotificationState) => {
        state.isLoading = true;
      },
    )
    .addCase(
      effects.getNotificationDataGroupedByType.fulfilled,
      (
        state: NotificationState,
        action: PayloadAction<NotificationPayload>,
      ) => {
        if (action.payload.error) {
          state.error = action.payload.error;
        } else {
          const disorganizedContent = disorganizeNormalizedNotification(
            state.broadcast.content,
          );
          const results = [...disorganizedContent, ...action.payload.result];
          // handle last id
          const { result } = action.payload;
          if (result && result.length > 0) {
            state.lastId = result[result.length - 1].id;
          }
          state.isLoading = false;
          state.broadcast = normalizeNotification(results);
        }
      },
    )
    .addCase(
      effects.getNotificationDataGroupedByType.rejected,
      (state: NotificationState, action: PayloadAction<CommonPayload>) => {
        state.isLoading = false;
        state.error = action.payload.error;
      },
    )

    // Mark as read
    .addCase(effects.markAsRead.pending, (state: NotificationState) => {
      state.isReading = true;
    })
    .addCase(
      effects.markAsRead.fulfilled,
      (state: NotificationState, action: PayloadAction<CommonPayload>) => {
        state.isReading = !action.payload.data;
        state.unread = 0;
      },
    )
    .addCase(
      effects.markAsRead.rejected,
      (state: NotificationState, action: PayloadAction<CommonPayload>) => {
        state.isReading = false;
        state.error = action.payload.error;
      },
    )
    // Get unread notification number
    .addCase(
      effects.getUnreadNotificationNumber.fulfilled,
      (
        state: NotificationState,
        action: PayloadAction<NotificationPayload>,
      ) => {
        state.unread = action.payload.unread;
      },
    )
    .addCase(
      effects.getUnreadNotificationNumber.rejected,
      (state: NotificationState, action: PayloadAction<CommonPayload>) => {
        state.error = action.payload;
      },
    );
};

// Slice
const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers,
  extraReducers,
});

export default notificationSlice;
