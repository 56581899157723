import { useRouter } from 'next/router';
import useSecuritiesCredentialStore from 'global/Securities/stores/useSecuritiesCredentialStore';
import orderTableStore from 'features/trade/components/OrderTable/store';
import { LoginType } from 'typings/auth';
import useUIConfigStore from 'stores/useUIConfigStore';
import { ERROR_CODE } from '../constants';

interface UseUnauthorizedResult {
  checkUnauthorized: (URL: string, token: string) => Promise<boolean>;
}

export default function useUnauthorized(): UseUnauthorizedResult {
  const router = useRouter();
  const resetUIValue = useUIConfigStore((s) => s.resetUIValue);

  const handleLogoutSecurities = async () => {
    await useSecuritiesCredentialStore.getState().loadLogout();
    const isSecuritiesPage = /securities\/.+/i.test(router.asPath);

    if (isSecuritiesPage) {
      orderTableStore.getState().resetOrderTableStore();
      resetUIValue(LoginType.Securities);
      await router.push('/stream');
    }
  };

  const checkUnauthorized = async (
    URL: string,
    token: string,
  ): Promise<boolean> => {
    const httpsUrl = URL.replace('wss://', 'https://');

    try {
      const response = await fetch(httpsUrl, {
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` },
      });

      if (!response.ok) {
        if (response.status === ERROR_CODE.UNAUTHORIZED) {
          handleLogoutSecurities();
          return true;
        }
        throw new Error(response.statusText);
      }

      return false;
    } catch (err) {
      return false;
    }
  };

  return { checkUnauthorized };
}
