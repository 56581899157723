import requestConfig from 'config/requestExodus';
import { APIResponse } from '../../../@types/api-response';
import { FinancialMetrics } from '../../../@types/metrics';

type MetricResponse<DataToken> = Promise<APIResponse<DataToken>>;

const request = requestConfig();

const getFinancialMetric = (): MetricResponse<FinancialMetrics[]> =>
  request.get('/watchlist/metric');

export default { getFinancialMetric };
