import moment from 'moment';
import type { MonthlyStatementDict, Order } from '../../../@types/shop';
import { OA_REGISTER_STATUS } from './constants';

export const createMonthlyStatements = (
  orders: Order[],
): MonthlyStatementDict => {
  const dict: MonthlyStatementDict = {};

  orders.forEach((order) => {
    const month = moment(order.date).format('MMMM YYYY');

    if (Array.isArray(dict[month])) {
      dict[month].push(order);
    } else {
      dict[month] = [order];
    }
  });

  return dict;
};

export const translatePackageOption = (packageItem = '', dateOnly = true) => {
  const [packageDuration = '', packageType = ''] = packageItem?.split(' ');

  switch (packageType.toLowerCase()) {
    case 'month': {
      if (Number(packageDuration) >= 12) {
        return `1 Tahun ${dateOnly && 'Stockbit Pro'}`;
      }
      return `${packageDuration} Bulan ${dateOnly ? 'Stockbit Pro' : ''}`;
    }

    case 'day': {
      return `${packageDuration} Hari ${dateOnly ? 'Stockbit Pro' : ''}`;
    }

    default: {
      return `${packageDuration} Tahun ${dateOnly ? 'Stockbit Pro' : ''}`;
    }
  }
};

export const checkIsWaitingAccount = (status: number) => {
  const waitingStatuses = [
    OA_REGISTER_STATUS.CS_PROGRESS,
    OA_REGISTER_STATUS.KSEI_PROGRESS,
    OA_REGISTER_STATUS.RDN_PROGRESS,
    OA_REGISTER_STATUS.SUBMIT_REGISTRATION,
    OA_REGISTER_STATUS.DOCUMENT_VERIFICATION,
  ];

  return waitingStatuses.includes(status);
};
