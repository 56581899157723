import {
  createAsyncThunk,
  createSlice,
  createSelector,
  PayloadAction,
} from '@reduxjs/toolkit';

// alert
// import handleErrorMessageAPI from 'global/AlertErrorMessage';
// import SUCCESS_MESSAGE from 'constants/successMessage';

// API Endpoints Related Modules
import friendsAPI from 'lib/api/friends';
import searchAPI from 'lib/api/search';
import watchlistAPI from 'lib/api/watchlist';
import { CommonPayload } from '../../../@types/action-payload';

// feature constants
import { SUGGESTIONS_PERPAGE, DISCOVER_FILTERS } from './constants';

import { ToggleFollowerUserTypes } from './types';
import { actionType, normalizeData } from './helper';

// initial state
const initialState = {
  error: null,
  isLoading: true,
  isLastPage: false,
  keyword: '',
  list: [],
  filter: DISCOVER_FILTERS[0].value,
  page: 1,
};

type DiscoverPeopleState = typeof initialState;

const discoverPeopleSelector = (state) => state.discoverPeople;

// Selectors
export const selectors = createSelector(
  discoverPeopleSelector,
  (discoverPeople: DiscoverPeopleState) => ({
    ...discoverPeople,
    // select methods
    getAllIds: () => discoverPeople.list.map((user) => user.id),
    getUserByIndex: (index: number) => discoverPeople.list[index] || [],
  }),
);

// Reducer
export const reducers = {
  changeType: (state, action) => {
    state.filter = action.payload;
  },
  resetData: (state) => {
    state.keyword = '';
    state.list = [];
    state.filter = state.filter ? state.filter : DISCOVER_FILTERS[0].value;
  },
};

// effects

export const effects = {
  // Search users
  searchUser: createAsyncThunk<
    CommonPayload,
    { keyword: string; page?: number }
  >(actionType.SEARCH_USER, async ({ keyword, page = 1 }) => {
    try {
      const response = await searchAPI.getSearchPeople(keyword, page);

      if (!response.data) {
        throw new Error('Cannot perform request');
      }

      const { data, message } = response.data;

      // normalize data
      const composedData = normalizeData(data, actionType.SEARCH_USER);

      return { data: composedData, message };
    } catch (error) {
      return error.message;
    }
  }),

  // toggle follower
  toggleFollowUser: createAsyncThunk<CommonPayload, ToggleFollowerUserTypes>(
    actionType.TOGGLE_FOLLOW_USER,
    async ({ userid, isFollowed = false, index }) => {
      try {
        if (typeof index !== 'number') {
          throw new Error('index must be a number');
        }

        if (!userid) {
          throw new Error('userid is required');
        }

        let targetAPI = watchlistAPI.postFollowUser;
        if (isFollowed) {
          targetAPI = watchlistAPI.postUnfollowUser;
        }
        const response = await targetAPI(userid);

        if (!response.data) {
          throw new Error('Cannot perform request');
        }

        const { message } = response.data;
        return { message };
      } catch (error) {
        return error.message;
      }
    },
  ),

  // Friends suggestions
  getFriendSuggestions: createAsyncThunk<
    CommonPayload,
    { page: number; limit?: number }
  >(
    actionType.FETCH_SUGGESTIONS,
    async ({ page = 1, limit = SUGGESTIONS_PERPAGE }) => {
      try {
        const config = {
          limit,
          page,
        };
        const response = await friendsAPI.getSuggestFriends(config);
        const { data, error, message } = response.data;

        if (!response.data || error) {
          throw new Error('failed to fetch friends suggestions');
        }

        // normalize data
        const normalized = normalizeData(data, actionType.FETCH_SUGGESTIONS);
        const more = normalized.length >= 30;

        return { data: normalized, message, more };
      } catch (error) {
        return error.message;
      }
    },
  ),

  // Contact
  getFriendSuggestionsContact: createAsyncThunk<
    CommonPayload,
    { page: number; limit?: number }
  >(
    actionType.FETCH_CONTACT,
    async ({ page = 1, limit = SUGGESTIONS_PERPAGE }) => {
      try {
        const response = await friendsAPI.getSuggestFriendsContacts(
          limit,
          page,
        );

        const {
          data: { found },
          error,
          message,
        } = response.data;

        if (!response.data || error) {
          throw new Error('failed to fetch friends suggestions');
        }

        // normalize data
        const normalized = normalizeData(found, actionType.FETCH_CONTACT);
        const more = normalized.length >= 30;

        return { data: normalized, message, more };
      } catch (error) {
        return error.message;
      }
    },
  ),
};

// Extra Reducer
const extraReducers = (builder) => {
  // Search by keyword
  builder
    .addCase(
      effects.searchUser.pending,
      (
        state: DiscoverPeopleState,
        action: PayloadAction<CommonPayload, any, any>,
      ) => {
        state.isLoading = true;
        state.keyword = action.meta.arg.keyword;
      },
    )
    .addCase(
      effects.searchUser.fulfilled,
      (state: DiscoverPeopleState, action: PayloadAction<CommonPayload>) => {
        const { data, error } = action.payload;
        if (error) {
          state.error = error;
        } else {
          state.list = data;
        }
        state.isLoading = false;
      },
    )
    .addCase(
      effects.searchUser.rejected,
      (
        state: DiscoverPeopleState,
        action: PayloadAction<CommonPayload, string, any, any>,
      ) => {
        state.isLoading = false;
        state.error = action.error;
      },
    )
    // Discover Friends Suggestions
    .addCase(
      effects.toggleFollowUser.pending,
      (
        state: DiscoverPeopleState,
        action: PayloadAction<CommonPayload, any, any>,
      ) => {
        const { index } = action.meta.arg;
        state.list[index].isLoading = true;
      },
    )
    .addCase(
      effects.toggleFollowUser.fulfilled,
      (
        state: DiscoverPeopleState,
        action: PayloadAction<CommonPayload, any, any>,
      ) => {
        const { error } = action.payload;
        if (error) {
          state.error = error;
        } else {
          const { index, isFollowed } = action.meta.arg;
          state.list[index].isFollowed = !isFollowed;
          state.list[index].isLoading = false;
        }
        state.isLoading = false;
      },
    )
    .addCase(
      effects.toggleFollowUser.rejected,
      (
        state: DiscoverPeopleState,
        action: PayloadAction<CommonPayload, string, any, any>,
      ) => {
        const { index } = action.meta.arg;
        state.list[index].isLoading = false;
        state.error = action.error;
      },
    )
    // Discover Friends Suggestions
    .addCase(
      effects.getFriendSuggestions.pending,
      (
        state: DiscoverPeopleState,
        action: PayloadAction<CommonPayload, any, any>,
      ) => {
        state.isLoading = true;
        state.page = action.meta.arg.page;
        // reset
        if (action.meta.arg.page === 1) {
          state.list = initialState.list;
          state.isLastPage = false;
        }
      },
    )
    .addCase(
      effects.getFriendSuggestions.fulfilled,
      (
        state: DiscoverPeopleState,
        action: PayloadAction<CommonPayload, any, any>,
      ) => {
        const { error } = action.payload;
        if (error) {
          state.error = error;
        } else if (action.payload.data.length > 0) {
          state.list.push(...action.payload.data);
        } else {
          state.isLastPage = true;
        }
        state.isLoading = false;
      },
    )
    .addCase(
      effects.getFriendSuggestions.rejected,
      (
        state: DiscoverPeopleState,
        action: PayloadAction<CommonPayload, string, any, any>,
      ) => {
        state.isLoading = false;
        state.error = action.error;
      },
    )
    // Discover contact Suggestions
    .addCase(
      effects.getFriendSuggestionsContact.pending,
      (
        state: DiscoverPeopleState,
        action: PayloadAction<CommonPayload, any, any>,
      ) => {
        state.isLoading = true;
        state.page = action.meta.arg.page;
        // reset
        if (action.meta.arg.page === 1) {
          state.list = initialState.list;
          state.isLastPage = false;
        }
      },
    )
    .addCase(
      effects.getFriendSuggestionsContact.fulfilled,
      (
        state: DiscoverPeopleState,
        action: PayloadAction<CommonPayload, any, any>,
      ) => {
        const { error } = action.payload;
        if (error) {
          state.error = error;
        } else if (action.payload.data.length > 0) {
          state.list.push(...action.payload.data);
        } else {
          state.isLastPage = true;
        }
        state.isLoading = false;
      },
    )
    .addCase(
      effects.getFriendSuggestionsContact.rejected,
      (
        state: DiscoverPeopleState,
        action: PayloadAction<CommonPayload, string, any, any>,
      ) => {
        state.isLoading = false;
        state.error = action.error;
      },
    );
};

// Slice
const discoverPeopleSlice = createSlice({
  name: 'discoverPeople',
  initialState,
  reducers,
  extraReducers,
});

export default discoverPeopleSlice;
