// Environment variables
export const FLIPT_URL_HOST = process.env.NEXT_PUBLIC_FLIPT_URL_HOST ?? '';
export const FLIPT_NAMESPACE = process.env.NEXT_PUBLIC_FLIPT_NAMESPACE ?? '';
export const FLIPT_TOKEN = process.env.NEXT_PUBLIC_FLIPT_TOKEN ?? undefined;

export const FLIPT_CHARTBIT_NAMESPACE =
  process.env.NEXT_PUBLIC_FLIPT_CHARTBIT_NAMESPACE ?? '';
export const FLIPT_CHARTBIT_TOKEN =
  process.env.NEXT_PUBLIC_FLIPT_CHARTBIT_TOKEN ?? undefined;
export const FLIPT_FINANCIAL_DATA_TOKEN =
  process.env.NEXT_PUBLIC_FLIPT_FINANCIAL_DATA_TOKEN ?? undefined;
export const FLIPT_FINANCIAL_DATA_NAMESPACE =
  process.env.NEXT_PUBLIC_FLIPT_FINANCIAL_DATA_NAMESPACE ?? '';

export const FLIPT_DESKTOP_TOKEN =
  process.env.NEXT_PUBLIC_FLIPT_DESKTOP_TOKEN ?? undefined;
export const FLIPT_DESKTOP_NAMESPACE =
  process.env.NEXT_PUBLIC_FLIPT_DESKTOP_NAMESPACE ?? '';
