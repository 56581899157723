import {
  ActionReducerMapBuilder,
  CaseReducer,
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
  SerializedError,
} from '@reduxjs/toolkit';
import numeral from 'numeral';

// libs
import orderbook from 'lib/api/orderbook';
import searchApi from 'lib/api/search';

// types
import { transformNewOrderbookListToExisting } from 'features/orderbook/utils';
import {
  CompanyOrderbook,
  OfferList,
  OHLCData,
  OrderbookPriceData,
  OrderbookPriceSummary,
} from '../../../../../../@types/orderbook';
import { Company } from '../../../../../../@types/company';
import { setValue } from './store';

// enums
type requestStatus = 'idle' | 'loaded' | 'pending' | 'rejected';
type offerSectionStatus = 'idle' | 'available' | 'not-available';

// states
interface SliceState {
  requestStatus: requestStatus;
  isSearchbarActive: boolean;
  offerSectionStatus: offerSectionStatus;

  symbol: string;
  lastprice: string;
  change: string;
  percentageChange: string;
  name: string;

  keyword: string;
  searchResult: Company[];

  ohlc?: OHLCData;
  offer?: OfferList;
  isBidOfferExists: boolean;

  priceData?: Array<OrderbookPriceData>;
  orderbookPriceData?: Partial<Pick<CompanyOrderbook, 'bid' | 'offer'>>;

  summary?: OrderbookPriceSummary;

  error?: SerializedError;
  previous?: number;
  orderbook: any;
}

const initialState: SliceState = {
  requestStatus: 'idle',
  isSearchbarActive: false,
  offerSectionStatus: 'idle',

  symbol: 'IHSG',
  name: 'Indeks Harga Saham Gabungan',
  lastprice: null,
  change: null,
  percentageChange: null,

  keyword: '',
  searchResult: [],

  ohlc: null,
  offer: null,
  isBidOfferExists: false,

  summary: null,

  priceData: null,
  orderbookPriceData: {},
  error: null,
  previous: 0,
  orderbook: null,
};

// reducers
type SliceReducers = {
  setisSearchbarActive: CaseReducer<
    SliceState,
    PayloadAction<{ isOpen: boolean }>
  >;
  setSymbol: CaseReducer<SliceState, PayloadAction<{ symbol: string }>>;
};

const reducers: SliceReducers = {
  setisSearchbarActive: (draft, action) => {
    draft.isSearchbarActive = action.payload.isOpen;
  },
  setSymbol: (draft, action) => {
    draft.symbol = action.payload.symbol;
  },
};

// selectors
const selfSelector = (state: any): SliceState =>
  state.mainLayout.watchlist.orderbook;
export const selectors = createSelector(selfSelector, (state) => state);

export const symbolSelector = createSelector(
  (state) => state.mainLayout.watchlist.orderbook.symbol,
  (state) => state,
);

// thunk actions
const CONTEXT = 'ORDERBOOK_WIDGET';

const fetchOrderbook = createAsyncThunk<
  {
    ohlc: any;
    priceData: Array<OrderbookPriceData>;
    orderbookPriceData?: Partial<Pick<CompanyOrderbook, 'bid' | 'offer'>>;
    isBidOfferExists: boolean;
    lastprice: string;
    change: string;
    percentageChange: string;
    name: string;
    summary?: OrderbookPriceSummary;
    previous?: number;
    orderbook: any;
  }, // success type
  { symbol: string }, // action payload
  {} // thunk api
>(`${CONTEXT}/FETCH_ORDERBOOK`, async ({ symbol }) => {
  const response = await orderbook.getOrderbookPreviewBySymbol(symbol);
  const companyResponse = await orderbook.getCompanyInfo(symbol);

  const { data: responseData, error } = response.data;
  const { data: compData, error: compError } = companyResponse.data;

  const data = transformNewOrderbookListToExisting(responseData);
  const { previous } = data;

  if (error || compError) {
    throw new Error(error || compError);
  }
  // normalize ohlc data
  const ohlc = (({
    previous,
    change,
    percentage_change,
    frequency,
    open,
    high,
    low,
    fbuy,
    volume,
    value,
    average,
    fsell,
    close,
    lastprice,
    symbol,
    exchange,
    ara,
    arb,
  }) => ({
    previous,
    change,
    percentage_change,
    frequency,
    open,
    high,
    low,
    fbuy,
    volume,
    value,
    average,
    fsell,
    close,
    lastprice,
    symbol,
    exchange,
    ara,
    arb,
  }))(data);

  // normalize offer data
  const isOfferExists = Boolean(
    !!data.offer &&
      typeof data.offer === 'object' &&
      Object.keys(data.offer).length > 0,
  );
  const isBidExists = Boolean(
    !!data.bid &&
      typeof data.bid === 'object' &&
      Object.keys(data.bid).length > 0,
  );

  const priceData: Array<OrderbookPriceData> = null;
  let orderbookPriceData: Partial<Pick<CompanyOrderbook, 'bid' | 'offer'>> = {};
  const summary: OrderbookPriceSummary = null;
  const isBidOfferExists = isBidExists || isOfferExists;

  if (isBidOfferExists) {
    const bid = data?.bid || {};
    const offer = data?.offer || {};

    orderbookPriceData = { bid, offer };
  }

  const lastprice = numeral(data.lastprice).format('0,0[.]00');
  const change = numeral(data.change).format('0');
  const percentageChange = `${numeral(data.percentage_change).format('0.00')}%`;

  setValue({ key: 'liveprice', symbol: data.symbol, type: 'update_general_pricedata', value: { ...data } });

  return {
    isBidOfferExists,
    ohlc,
    lastprice,
    change,
    percentageChange,
    name: compData.name,
    priceData,
    summary,
    previous,
    orderbookPriceData,
    orderbook: data,
  };
});

const fetchSymbolSuggestions = createAsyncThunk<
  { company: Company[] }, // success type
  { keyword: string }, // action payload
  {} // thunk api
>(`${CONTEXT}/FETCH_SUGGESTIONS`, async ({ keyword }) => {
  const response = await searchApi.getSearchCompany(keyword);

  const { data, error } = response.data;

  if (error) {
    throw new Error(error);
  }

  return {
    company: data.company,
  };
});

export const effects = {
  fetchOrderbook,
  fetchSymbolSuggestions,
};

// thunks reducers
const extraReducers = (builder: ActionReducerMapBuilder<SliceState>) => {
  // fetch orderbook details
  builder
    .addCase(fetchOrderbook.pending, (draft) => {
      draft.requestStatus = 'pending';
      draft.error = null;
    })
    .addCase(fetchOrderbook.fulfilled, (draft, action) => {
      draft.requestStatus = 'loaded';
      draft.isBidOfferExists = action.payload.isBidOfferExists;
      draft.ohlc = action.payload.ohlc;
      draft.lastprice = action.payload.lastprice;
      draft.change = action.payload.change;
      draft.percentageChange = action.payload.percentageChange;
      draft.name = action.payload.name;
      draft.priceData = action.payload.priceData;
      draft.summary = action.payload.summary;
      draft.previous = action.payload.previous;
      draft.orderbookPriceData = action.payload.orderbookPriceData;
      draft.orderbook = action.payload.orderbook;
    })
    .addCase(fetchOrderbook.rejected, (draft, action) => {
      draft.error = action.error;
    })
    // fetch company suggestions
    .addCase(fetchSymbolSuggestions.pending, (draft) => {
      draft.error = null;
    })
    .addCase(fetchSymbolSuggestions.fulfilled, (draft, action) => {
      draft.searchResult = action.payload.company;
    })
    .addCase(fetchSymbolSuggestions.rejected, (draft, action) => {
      draft.error = action.error;
    });
};

const orderbookSlice = createSlice({
  name: 'orderbook',
  initialState,
  reducers,
  extraReducers,
});

export default orderbookSlice;
