import {
  createAsyncThunk,
  createSlice,
  createSelector,
  PayloadAction,
} from '@reduxjs/toolkit';
import { effects as streamEffects } from 'lib/entities/stream';
import streamApi from 'lib/api/stream';
import { transformV3Response, transformConversationV3 } from 'utils/stream';
import { CommonPayload } from '../../../@types/action-payload';

// APIs

// External reducer
import { effects as streamWidgetEffects } from '../StreamWidget/slice';
import {
  LoadPreviousConversationPayload,
  StreamWithPostIdPayload,
  GetConversationProps,
} from './types';

const STATUS_FOLLOWING = 1;
const STATUS_UNFOLLOWING = 0;

const STATUS_SAVED = 1;
const STATUS_UNSAVED = 0;

const STATUS_LIKED = 1;
const STATUS_UNLIKED = 0;

const initialState = {
  data: {},
  selected: {},
  isLoading: true,
  isLoadingMore: false,
  error: null,
  message: '',
};

type StreamConvoState = typeof initialState;

// Selectors -----------------------------------------------------------
const convoSelector = (state) => state.streamConversation;
export const selectors = createSelector(
  convoSelector,
  (convo: StreamConvoState) => ({
    ...convo,
  }),
);

// Action type -----------------------------------------------------------
const CONTEXT = '@redux/streamConversation';

const actionType = {
  GET_CONVERSATION: `${CONTEXT}/GET_CONVERSATION`,
  LOAD_PREV_CONVERSATION: `${CONTEXT}/LOAD_PREV_CONVERSATION`,
};

// Helper -----------------------------------------------------------
enum IActionStatus {
  STATUS_FOLLOWING,
  STATUS_UNFOLLOWING,
  STATUS_SAVED,
  STATUS_UNSAVED,
  STATUS_LIKED,
  STATUS_UNLIKED,
}

/**
 * Do Count of Stream Post Likes
 * @param state state
 * @param status status
 */
const countStreamPostLike = (state, status: IActionStatus) => {
  if (status === STATUS_LIKED) {
    state.likes += 1;
  } else if (status === STATUS_UNLIKED) {
    state.likes -= 1;
  }
};

/**
 * Change Status of Action
 * @param state state object
 * @param postid post id
 * @param action property that want to change
 * @param status value that you want to apply to the property
 * @description
 * this for change follow, saved, liked, likes value
 * from state.selected
 */
const changeActionStatus = (
  state,
  postid: string | number,
  action: 'follow' | 'saved' | 'liked',
  status: IActionStatus,
) => {
  // check index of replies item of selected convo
  const replyIdx = state.selected?.replies?.findIndex(
    (reply) => reply.postid === postid,
  );
  // change 'action' value of replies item with status
  if (replyIdx > -1) {
    state.selected.replies[replyIdx][action] = status;
    if (action === 'liked') {
      countStreamPostLike(state.selected.replies[replyIdx], status);
    }
  } else if (state.selected.parent?.postid === postid) {
    state.selected.parent[action] = status;
    if (action === 'liked') {
      countStreamPostLike(state.selected.parent, status);
    }
  }
};

// Effects -----------------------------------------------------------
export const effects = {
  getStreamConversation: createAsyncThunk<CommonPayload, GetConversationProps>(
    actionType.GET_CONVERSATION,
    async ({ postId, parentId }) => {
      try {
        const response = await streamApi.getStreamConversation({
          postId,
          parentId,
        });

        if (!response.data) {
          throw new Error('Attempt to fetch stream conversation failed');
        }

        const { data: posts, error, message } = response.data;

        if (error) {
          return { error, message };
        }

        const data = transformConversationV3(posts.conversation);

        return {
          postid: postId,
          data,
          message,
        };
      } catch (error) {
        return { error };
      }
    },
  ),
  loadPreviousConversation: createAsyncThunk<CommonPayload, LoadPreviousConversationPayload>(
    actionType.LOAD_PREV_CONVERSATION,
    async (params) => {
    try {
      const { lastReplyPostId, postid } = params;
      const response = await streamApi.getPrevStreamConversation(lastReplyPostId);

      if (!response.data) {
        throw new Error('Attempt to fetch stream conversation failed');
      }

      const { data: posts, error, message } = response.data;

      if (error) {
        return { error, message };
      }

      const data = {
        more: !posts.pagination?.is_last_page,
        replies: transformV3Response(posts.stream),
      };

      return { postid, data, message };
    } catch (error) {
      return { error };
    }
  }),
};

// Reducers -----------------------------------------------------------
const reducers = {
  closeStreamConversation: (state) => {
    state.isLoading = initialState.isLoading;
    state.isLoadingMore = initialState.isLoadingMore;
  },
};

// Extra reducers -----------------------------------------------------------
const extraReducers = (builder) => {
  builder
    .addCase(
      effects.getStreamConversation.pending,
      (state: StreamConvoState) => {
        state.isLoading = true;
        state.error = null;
      },
    )
    .addCase(
      effects.getStreamConversation.fulfilled,
      (
        state: StreamConvoState,
        action: PayloadAction<StreamWithPostIdPayload>,
      ) => {
        const { error, data, message } = action.payload;

        if (error) {
          state.error = error;
        } else {
          state.selected = data;
          state.error = null;
          state.isLoading = false;
        }
        state.message = message;
      },
    )
    .addCase(
      effects.getStreamConversation.rejected,
      (state: StreamConvoState, action: PayloadAction<CommonPayload>) => {
        state.error = action.payload.error;
      },
    )
    .addCase(
      effects.loadPreviousConversation.pending,
      (state: StreamConvoState) => {
        state.isLoadingMore = true;
        state.error = null;
      },
    )
    .addCase(
      effects.loadPreviousConversation.fulfilled,
      (
        state: StreamConvoState,
        action: PayloadAction<StreamWithPostIdPayload>,
      ) => {
        const { error, data, message } = action.payload;

        if (error) {
          state.error = error;
        } else {
          state.selected = {
            ...state.selected,
            // @ts-ignore
            replies: [...data.replies, ...state.selected.replies],
            more: data.more,
          };
          state.error = null;
        }

        state.isLoadingMore = false;
        state.message = message;
      },
    )
    .addCase(
      effects.loadPreviousConversation.rejected,
      (state: StreamConvoState, action: PayloadAction<CommonPayload>) => {
        state.error = action.payload.error;
        state.isLoadingMore = false;
      },
    )

    // Follow/unfollow stream convo data
    .addCase(
      streamWidgetEffects.followPost.fulfilled,
      (
        state: StreamConvoState,
        action: PayloadAction<StreamWithPostIdPayload>,
      ) => {
        const { postid } = action.payload;

        changeActionStatus(state, postid, 'follow', STATUS_FOLLOWING);
      },
    )
    .addCase(
      streamWidgetEffects.unfollowPost.fulfilled,
      (
        state: StreamConvoState,
        action: PayloadAction<StreamWithPostIdPayload>,
      ) => {
        const { postid } = action.payload;

        changeActionStatus(state, postid, 'follow', STATUS_UNFOLLOWING);
      },
    )

    // Save/unsave stream convo data
    .addCase(
      streamWidgetEffects.savePost.fulfilled,
      (
        state: StreamConvoState,
        action: PayloadAction<StreamWithPostIdPayload>,
      ) => {
        const { postid } = action.payload;
        changeActionStatus(state, postid, 'saved', STATUS_SAVED);
      },
    )
    .addCase(
      streamWidgetEffects.unsavePost.fulfilled,
      (
        state: StreamConvoState,
        action: PayloadAction<StreamWithPostIdPayload>,
      ) => {
        const { postid } = action.payload;
        changeActionStatus(state, postid, 'saved', STATUS_UNSAVED);
      },
    )

    // Like/unline stream convo data
    .addCase(
      streamWidgetEffects.likePost.fulfilled,
      (
        state: StreamConvoState,
        action: PayloadAction<StreamWithPostIdPayload>,
      ) => {
        const { postid, error } = action.payload;

        if (!error) {
          changeActionStatus(state, postid, 'liked', STATUS_LIKED);
        }
      },
    )
    .addCase(
      streamWidgetEffects.unlikePost.fulfilled,
      (
        state: StreamConvoState,
        action: PayloadAction<StreamWithPostIdPayload>,
      ) => {
        const { postid, error } = action.payload;

        if (!error) {
          changeActionStatus(state, postid, 'liked', STATUS_UNLIKED);
        }
      },
    )

    // When conversation success replied, the response will be added
    // as new reply to the state.data
    .addCase(
      streamEffects.postConversationStream.fulfilled,
      (state: StreamConvoState, action) => {
        const { data } = action.payload;
        if (data) {
          // @ts-ignore
          const { replies } = state.selected;
          const newReplies = [...replies, data];

          // @ts-ignore
          state.selected.replies = newReplies;
        }
      },
    )

    // When conversation reply success replied, the reply item state
    // will be updated with api response
    .addCase(
      streamEffects.updatePostStream.fulfilled,
      (state: StreamConvoState, action) => {
        const { data } = action.payload;

        const isReply = data?.postid !== data?.parent_postid;

        if (data) {
          // @ts-ignore
          if (isReply && state.selected?.replies) {
            const { commenter_type: commenterType } = action.meta.arg;

            // @ts-ignore
            const { replies } = state.selected;
            const idxReplies = replies.findIndex((reply) => reply?.postid === data?.postid);

            if (idxReplies > -1) {
              // Update reply item
              // update created using old and updated as created we receive from server
              const newReplies = replies;
              const currentComment = newReplies[idxReplies];
              newReplies[idxReplies] = {
                ...data,
                commenter_type: commenterType,
                created: currentComment.created,
                created_display: currentComment.created_display,
                updated: data?.created,
              };

              // @ts-ignore
              state.selected.replies = newReplies;
            }
          }
        }
      },
    )

    // When stream post detail/conversation change the comment limitation
    // it will update the commenter_type of parent stream post
    .addCase(
      streamWidgetEffects.changeCommenter.fulfilled,
      (
        state: StreamConvoState,
        action: PayloadAction<CommonPayload, any, any>,
      ) => {
        const { error } = action.payload;
        const { type } = action.meta.arg;

        // @ts-ignore
        if (!error && state.selected?.parent) {
          // @ts-ignore
          state.selected.parent.commenter_type = type;
        }
      },
    )

    // Handle sideeffect when user post polling vote on convo
    .addCase(
      streamWidgetEffects.postVotePolling.fulfilled,
      (state: StreamConvoState, action: PayloadAction<CommonPayload, any, any>) => {
        const { data, error } = action.payload;
        if (!error) {
          // @ts-ignore
          const selectedParent = state.selected?.parent?.polling;
          if (selectedParent) {
            if (selectedParent.length > 0 && selectedParent[0]?.id === data.id) {
            // @ts-ignore
              state.selected.parent.polling[0] = data;
            }
          }
        }
      },
    )

    // Handle sideeffect when user click price target action on convo
    .addCase(
      streamWidgetEffects.postVoteTargetPrice.fulfilled,
      (state: StreamConvoState, action: PayloadAction<CommonPayload, any, any>) => {
        const { data, error } = action.payload;
        if (!error) {
          // @ts-ignore
          if (state.selected?.parent?.postid === data.postid) {
            // @ts-ignore
            state.selected.parent = data;
          }
        }
      },
    );
};

const streamConversation = createSlice({
  name: 'streamConversation',
  initialState,
  reducers,
  extraReducers,
});

export default streamConversation;
