/* eslint-disable react/require-default-props */
import Iconmoon from '@deprecated-ui/common/Iconmoon';
import color from '@deprecated-ui/_style/color';
import styled from 'styled-components';
import { handleClickSupport } from 'utils/crisp';

const ButtonSupport = styled.button`
  align-items: center;
  background: #404040;
  border: none;
  border-radius: 50%;
  bottom: 50px;
  cursor: pointer;
  display: flex;
  height: 60px;
  justify-content: center;
  position: fixed;
  right: 30px;
  width: 60px;
  z-index: 9999;
`;

export const buttonSupportId = 'buttonSupport';

interface SupportButtonProps {
  isLoggedIn?: boolean;
}

function SupportButton({ isLoggedIn }: SupportButtonProps) {
  if (isLoggedIn) {
    return null;
  }

  return (
    <ButtonSupport onClick={handleClickSupport} id={buttonSupportId}>
      <Iconmoon
        className="icon-widget_support"
        color={color.light}
        hoverColor={color.light}
        size="40px"
      />
    </ButtonSupport>
  );
}

export default SupportButton;
