import requestConfig from 'config/requestExodus';
import { API_EXODUS_URL } from 'constants/api';

const request = requestConfig();

const GET_EARNINGS = `${API_EXODUS_URL}/earnings`;

const getEarnings = ({
  page,
  order,
  sortcol,
  year,
  quarter,
  companySymbol,
  filter,
}: {
  page: string;
  order: string;
  sortcol: string;
  year?: string;
  quarter?: string;
  companySymbol?: string;
  filter?: string;
}) =>
  request.get(GET_EARNINGS, {
    params: {
      filter,
      search: companySymbol,
      quarter,
      year,
      sort_column: sortcol,
      order,
      page,
    },
  });

export default getEarnings;
