import { string, number, arrayOf, shape, object } from 'prop-types';

export const schema = {
  company: [],
  sector: [],
  insider: [],
  chat: [],
  user_more: 0,
  company_more: 0,
  people: [],
};

export const propTypesPeople = shape({
  id: number,
  img: string,
  followed: number,
  official: number,
  name: string,
  desc: string,
  url: string,
  type: string,
  other: string,
  total_followers: number,
});

export const propTypes = {
  company: arrayOf(object),
  sector: arrayOf(object),
  insider: arrayOf(object),
  chat: arrayOf(object),
  user_more: number,
  company_more: number,
  people: arrayOf(propTypesPeople),
};

export default { schema, propTypes, propTypesPeople };
