import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { GetResearchParams } from 'global/StreamWidget/types';

import { getCategories, getResearchData } from 'lib/api/research';
import { CommonPayload } from '../../../@types/action-payload';

// action types
const CONTEXT = '@feature/research';

const actionType = {
  GET_SNIPS: `${CONTEXT}/GET_SNIPS`,
  GET_CATEGORIES: `${CONTEXT}/GET_CATEGORIES`,
};

interface iResearch {
  research: {
    isLoading: boolean;
    message: string;
    data: any;
    error: string;
    hasMore: boolean;
  };
  categories: {
    isLoading: boolean;
    message: string;
    data: any;
    error: string;
  };
}

const initialState: iResearch = {
  research: {
    isLoading: true,
    message: null,
    data: [],
    error: null,
    hasMore: true,
  },
  categories: {
    isLoading: true,
    message: null,
    data: [],
    error: null,
  },
};

const researchState = (state) => state.research;
export const selectors = createSelector(
  researchState,
  (research: iResearch) => ({
    research: {
      isLoading: research.research.isLoading,
      message: research.research.message,
      data: research.research.data,
      error: research.research.error,
      hasMore: research.research.hasMore,
    },
    categories: {
      isLoading: research.categories.isLoading,
      message: research.categories.message,
      data: research.categories.data,
      error: research.categories.error,
    },
  }),
);

export const effects = {
  getData: createAsyncThunk<CommonPayload, GetResearchParams>(
    actionType.GET_SNIPS,
    async (params, { getState }) => {
      try {
        const categories = await getResearchData(params);
        if (categories.status !== 200) throw new Error('Error');

        const { error, error_type, data, message } = categories?.data;

        if (error || error_type) throw new Error(error || error_type);

        const { research } = getState() as any;
        const {
          research: { data: oldData },
        } = research as iResearch;

        return { data, message, oldData };
      } catch (error) {
        return { error };
      }
    },
  ),
  getCategories: createAsyncThunk(
    actionType.GET_CATEGORIES,
    // @ts-ignore
    async () => {
      try {
        const categories = await getCategories();
        if (categories.status !== 200) throw new Error('Error');

        const { error, error_type, data, message } = categories?.data;

        if (error || error_type) throw new Error(error || error_type);

        return { data, message };
      } catch (error) {
        return { error };
      }
    },
  ),
};

const extraReducer = (builder) => {
  builder
    .addCase(effects.getData.pending, (state: iResearch) => {
      state.research.isLoading = true;
    })
    .addCase(effects.getData.fulfilled, (state: iResearch, action) => {
      const { message, data, oldData } = action.payload;

      state.research.isLoading = false;
      if (data?.length) {
        state.research.data = [...oldData, ...data];
      } else {
        state.research.hasMore = false;
      }
      state.research.message = message;
    })
    .addCase(effects.getData.rejected, (state: iResearch, action) => {
      state.research.isLoading = false;
      state.research.error = action.payload.error;
    })
    .addCase(effects.getCategories.pending, (state: iResearch) => {
      state.categories.isLoading = true;
    })
    .addCase(effects.getCategories.fulfilled, (state: iResearch, action) => {
      const { message, data } = action.payload;

      state.categories.isLoading = false;
      state.categories.data = data?.category || [];
      state.categories.message = message;
    })
    .addCase(effects.getCategories.rejected, (state: iResearch, action) => {
      state.categories.isLoading = false;
      state.categories.error = action.payload.error;
    });
};

const researchSlice = createSlice({
  name: 'research',
  initialState,
  reducers: {},
  extraReducers: extraReducer,
});

export default researchSlice;
