import produce from 'immer';
import create from 'zustand';
import { CompanyOrderbook } from '../../../../../../@types/orderbook';

export interface State {
  liveprice: Partial<CompanyOrderbook>;
}

export const OrderbookState: State = {
  liveprice: {},
};

export type OrderbookStore = State;

export const useOrderbookStore = create<OrderbookStore>(
  (_set, _get, _store, _storeMutation) => ({
    ...OrderbookState,
  }),
);

const handleUpdateGeneralPriceData = (draft, payload, isInit) => {
    return {
      ...draft,
      ...(isInit ? payload : {}),
      average: payload.average,
      change: payload.change,
      close:payload.close,
      frequency:payload.frequency,
      fsell:payload.fsell,
      high:payload.high,
      id:payload.id,
      lastprice:payload.lastprice,
      low:payload.low,
      open:payload.open,
      percentage_change:payload.percentage_change,
      previous:payload.previous,
      symbol:payload.symbol,
      value:payload.value,
      volume:payload.volume,
      time: payload?.time
    }
}

const handleUpdateOrderbookPriceData = (draft, payload) => {
  if (payload.bid) {
    draft.bid = payload.bid
  }
  if (payload.offer) {
    draft.offer = payload.offer
  }

  return draft
}

const handleUpdateIepIev = (
  draft: Partial<CompanyOrderbook>,
  payload: null | {
    iep: number,
    iev: number,
  },
) => {
  draft.iepiev = payload
    ? {
        ...draft?.iepiev,
        iep: {
          ...draft?.iepiev?.iep,
          raw: payload.iep,
        },
        iev: {
          ...draft?.iepiev?.iev,
          raw: payload.iev,
        },
      }
    : null;
  return draft;
};

const TYPE_SET = {
  'update_general_pricedata_liveprice': (draft, payload) => handleUpdateGeneralPriceData(draft, payload, false),
  'update_orderbook_pricedata_liveprice': (draft, payload) => handleUpdateOrderbookPriceData(draft, payload),
  'update_general_pricedata': (draft, payload) => handleUpdateGeneralPriceData(draft, payload, true),
  'update_iepiev': handleUpdateIepIev,
}

export const setValue = ({ key, value, symbol, type = 'update_general_pricedata' }) => useOrderbookStore.setState((state) =>
  produce(state, (draft) => {
    const draftSymbol = draft[key]?.[symbol] || {};
    draft[key][symbol] = TYPE_SET[type](draftSymbol, value)
    return
}))

export const setValueBatch = (payload) => useOrderbookStore.setState((state) =>
  produce(state, (draft) => {
    payload.map((item) => {
      const { payload: { data: value, index }, name: type } = item;
      const draftSymbol = draft['liveprice']?.[index] || {};
      draft['liveprice'][index] = TYPE_SET[type](draftSymbol, value)
    })
    return draft;
}))