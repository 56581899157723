/* eslint-disable camelcase */
import {
  createSlice,
  createSelector,
  createAsyncThunk,
  ActionReducerMapBuilder,
  SerializedError,
  CaseReducer,
  PayloadAction,
} from '@reduxjs/toolkit';
import numeral from 'numeral';

import watchlist from 'lib/api/watchlist';
import auth from 'utils/auth';
import {
  WatchlistItem,
  WatchlistTableRow,
} from '../../../../../../@types/watchlist';

// constants
const CONTEXT = '@sidewidget/companyWatchlist';

const actionType = {
  GET_WATCHLIST: `${CONTEXT}/GET_WATCHLIST`,
  UNFOLLOW_COMPANY: `${CONTEXT}/UNFOLLOW_COMPANY`,
};

type SliceState = {
  showList: boolean;
  list: Array<WatchlistTableRow>;
  isLoading: boolean;
  error: SerializedError;
};

// initial state
export const initialState: SliceState = {
  showList: false,
  list: [],
  isLoading: false,
  error: null,
};

// Selectors
const selfSelector = (state: any): SliceState =>
  state.mainLayout.watchlist.company;
export const selectors = createSelector(selfSelector, (state) => state);

// Async Actions / Side Effects
export const effects = {
  getCompanyWatchlist: createAsyncThunk<
    Array<WatchlistTableRow>,
    { watchlistId: string },
    {}
  >(actionType.GET_WATCHLIST, async ({ watchlistId }) => {
    // @ts-ignore
    const { watchlist_id: watchlistIdCookie } = auth.getUserAccess();
    const targetWatchlistId = watchlistId || watchlistIdCookie;

    if (!targetWatchlistId) {
      throw new Error('watchListId is required');
    }

    const response = await watchlist.getDetailWatchlist(targetWatchlistId);

    if (!response.data) {
      throw new Error('Attempt to fetch stream failed');
    }

    const { data, error_type: error } = response.data;

    if (error) {
      throw new Error(error);
    }

    return data.result.map(
      ({
        change,
        id,
        last,
        name,
        percent,
        previous,
        symbol,
        type,
        notation,
        uma,
        corp_action,
      }) => {
        let direction: 'neutral' | 'up' | 'down' = 'neutral';

        if (change > 0) {
          direction = 'up';
        } else if (change < 0) {
          direction = 'down';
        }

        return {
          change,
          id,
          last,
          name,
          percent,
          previous,
          symbol,
          type,
          direction,
          notation,
          uma,
          corp_action,
        };
      },
    );
  }),

  // Unfollow Company
  unfollowCompany: createAsyncThunk<
    { data: WatchlistItem; message: string },
    { companyId: number; watchlistId: string }, // payload
    {}
  >(
    actionType.UNFOLLOW_COMPANY,
    async ({ companyId, watchlistId }, { dispatch }) => {
      // @ts-ignore
      const { watchlist_id: watchlistIdCookie } = auth.getUserAccess();
      const targetWatchlistId = watchlistId || watchlistIdCookie;
      if (!targetWatchlistId) {
        throw new Error('watchListId is required');
      }

      const response = await watchlist.postUnfollowCompany(
        targetWatchlistId,
        companyId,
      );

      if (!response.data) {
        throw new Error('Attempt to fetch stream failed');
      }

      const { data, error, message } = response.data;

      if (error) {
        throw new Error(error);
      }

      dispatch(effects.getCompanyWatchlist({ watchlistId }));

      return {
        message,
        data,
      };
    },
  ),
};

// Reducer
type SliceReducer = {
  toggleViewType: CaseReducer<SliceState, PayloadAction>;
};

const reducers: SliceReducer = {
  toggleViewType: (draft) => {
    draft.showList = !draft.showList;
  },
};

// Extra Reducer for async actions
const extraReducers = (builder: ActionReducerMapBuilder<SliceState>) => {
  builder
    // get company watchlist
    .addCase(effects.getCompanyWatchlist.pending, (draft) => {
      draft.isLoading = true;
    })

    .addCase(effects.getCompanyWatchlist.rejected, (draft, action) => {
      draft.isLoading = false;
      draft.error = action.error;
    })
    .addCase(effects.getCompanyWatchlist.fulfilled, (draft, action) => {
      draft.list = action.payload;
      draft.isLoading = false;
    })

    // unfollow
    .addCase(effects.unfollowCompany.pending, (draft) => {
      draft.isLoading = true;
    })
    .addCase(effects.unfollowCompany.rejected, (draft, action) => {
      draft.isLoading = false;
      draft.error = action.error;
    })
    .addCase(effects.unfollowCompany.fulfilled, (draft) => {
      draft.isLoading = false;
    });
};

// Slice
const companyWatchlistSlice = createSlice({
  name: 'companyWatchlist',
  initialState,
  reducers,
  extraReducers,
});

export default companyWatchlistSlice;
