/* eslint-disable react/no-danger */
import { GTM_ID, STAGE } from 'constants/app';
import If from 'global/ErrorHandlers/If';
import Head from 'next/head';
import Script from 'next/script';

const Google = () => (
  <If condition={STAGE !== 'development'}>
    <Head>
      {GTM_ID ? (
        // eslint-disable-next-line @next/next/next-script-for-ga
        <Script id="google-tag-manager" strategy="afterInteractive">
          {`
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl+'&ra='+Date.now();f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${GTM_ID}');
              `}
        </Script>
      ) : null}
    </Head>
  </If>
);

export default Google;
