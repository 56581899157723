import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

// External slice
import { effects as watchlistEffects } from 'lib/entities/watchlist';

// APIs
import friendsApi from 'lib/api/friends';

// Initial state
const initialState = {
  list: [],
  isLoading: true,
  message: '',
  error: null,
  toggleFollow: {
    data: {},
    isLoading: false,
    error: null,
    message: '',
  },
};

// Selector
export const selectors = createSelector(
  (state) => state.suggestedPeople,
  (suggestedPeople) => ({
    list: suggestedPeople.list,
    isLoading: suggestedPeople.isLoading,
    error: suggestedPeople.error,
    toggleFollow: suggestedPeople.toggleFollow,
  }),
);

// Action type
const CONTEXT = '@global/suggestedPeople';

const actionType = {
  GET_SUGGESTED_PEOPLE: `${CONTEXT}/GET_SUGGESTED_PEOPLE`,
  FOLLOW_USER: `${CONTEXT}/FOLLOW_USER`,
};

export const effects = {
  getSuggestedPeople: createAsyncThunk(
    actionType.GET_SUGGESTED_PEOPLE,
    async ({ page, limit, ...rest }) => {
      try {
        const response = await friendsApi.getSuggestFriends({
          page,
          limit,
          ...rest,
        });

        if (!response.data) {
          throw new Error('Attempt to get suggested people failed!');
        }

        const { error, data, message } = response.data;

        if (error) {
          return { error, message };
        }

        return { data, message };
      } catch (error) {
        return { error };
      }
    },
  ),
};

const reducers = {
  removeSugggestion: (state, action) => {
    const index = action.payload;

    state.list.splice(index, 1);
  },
};

const extraReducers = {
  [effects.getSuggestedPeople.pending]: (state) => {
    state.isLoading = true;
    state.error = null;
  },
  [effects.getSuggestedPeople.fulfilled]: (state, action) => {
    const { error, message, data } = action.payload;

    if (error) {
      state.error = error;
    } else {
      state.list = data;
      state.error = null;
    }

    state.isLoading = false;
    state.message = message;
  },
  [effects.getSuggestedPeople.rejected]: (state, action) => {
    state.error = action.payload.error;
    state.isLoading = false;
  },

  // handle follow/unfollow user from watchlist slice
  [watchlistEffects.followUser.pending]: (state) => {
    state.toggleFollow.isLoading = true;
    state.toggleFollow.error = null;
  },
  [watchlistEffects.followUser.fulfilled]: (state, action) => {
    const { error, data, message, userid, type } = action.payload;

    if (error) {
      state.toggleFollow.error = error;
    } else {
      state.toggleFollow.data = data;

      const userDataIndex = state.list.findIndex(
        // eslint-disable-next-line eqeqeq
        (user) => user.user.userid == userid,
      );

      if (userDataIndex > -1) {
        // set to true if 'FOLLOW', set false if otherwise
        state.list[userDataIndex].user.follow = type === 'FOLLOW';
      }
    }

    state.toggleFollow.isLoading = false;
    state.toggleFollow.message = message;
  },
  [watchlistEffects.followUser.rejected]: (state, action) => {
    state.toggleFollow.error = action.payload.error;
    state.toggleFollow.isLoading = false;
  },
};

const suggestedPeopleSlice = createSlice({
  name: 'suggestedPeople',
  reducers,
  extraReducers,
  initialState,
});

export default suggestedPeopleSlice;
