import { combineReducers, Reducer } from 'redux';
import { CombinedState } from '@reduxjs/toolkit';

import googleOauthSlice from './GoogleOauth/slice';
import emailRegisterSlice from './EmailRegister/slice';
import facebookOauthSlice from './FacebookOauth/slice';
import rootSlice from './slice';

// Define the state shape of the register reducer
type RegisterState = {
  emailRegister: ReturnType<typeof emailRegisterSlice.reducer>;
  googleOauth: ReturnType<typeof googleOauthSlice.reducer>;
  facebookOauth: ReturnType<typeof facebookOauthSlice.reducer>;
  root: ReturnType<typeof rootSlice.reducer>;
};

// Create the register reducer
const registerReducer: Reducer<CombinedState<RegisterState>> = combineReducers({
  emailRegister: emailRegisterSlice.reducer,
  googleOauth: googleOauthSlice.reducer,
  facebookOauth: facebookOauthSlice.reducer,
  root: rootSlice.reducer,
});

export default registerReducer;
