import { createGlobalStyle, css } from 'styled-components';
import hexOpacity from '@deprecated-ui/utils/hexOpacity';
import color from '@deprecated-ui/_style/color';
import device from '@deprecated-ui/_style/device';
import alertStyles from './alert';
import tooltipStyles from './tooltip';
import layoutPageContainerStyles from './layout';

const overideAntdCSS = css`
  /* Wave Color */
  html {
    --antd-wave-shadow-color: ${color.primary};
  }

  body {
    background-color: #f5f5f5;
    font-family: var(--regular-font);
    font-size: 14px;
    height: auto;
    font-variant: normal;
    -webkit-font-feature-settings: normal;
    font-feature-settings: normal;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  b,
  strong {
    font-family: var(--bold-font);
    font-weight: normal;
  }

  /* ANTD Modal */
  /* .ant-modal-mask {
    z-index: 1031;
  }

  .ant-modal-wrap {
    z-index: 1040;
  } */

  /* Antd Popover */
  .ant-popover-inner-content {
    padding: 0;
  }
  .ant-popover-inner {
    box-shadow: none;
    border: none;
  }
  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-placement-bottomRight {
    position: fixed;
  }

  /* Antd DatePicker */
  .ant-picker-panel-container {
    border: 1px solid ${color.gainsboro};
  }

  .ant-picker-decade-panel,
  .ant-picker-year-panel,
  .ant-picker-quarter-panel,
  .ant-picker-month-panel,
  .ant-picker-week-panel,
  .ant-picker-date-panel,
  .ant-picker-time-panel {
    width: 230px;
  }

  .ant-picker-content {
    table-layout: auto;
    width: 100% !important;
  }

  .ant-picker-header-view {
    font-size: 12px;
    font-family: var(--font-bold);

    button {
      &:hover {
        color: ${color.gainsboro};
      }
    }
  }
  .ant-picker-cell-in-view {
    font-family: var(--font-bold);
  }
  .ant-picker-footer {
    display: block !important;
  }
  .ant-picker-today-btn {
    font-size: 12px;
    color: ${color.primary};
    font-family: var(--font-bold);
    display: block !important;

    &:hover {
      color: ${color.primary};
    }
  }
  .ant-picker-dropdown {
    font-size: 10px !important;
  }
  .ant-picker-cell {
    padding: 2px 0;
  }

  .ant-picker-cell-in-view.ant-picker-cell-today
    .ant-picker-cell-inner::before {
    border: none !important;
  }
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    background: #00ab6b !important;
    color: white !important;
  }
  .ant-picker-content th {
    font-weight: bold;
  }
  .ant-picker-cell-in-view.ant-picker-cell-today
    .ant-picker-cell-inner::before {
    border-color: ${color.primary};
  }
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    color: unset;
    background: ${color.light};
    border: 1px solid ${color.primary};
    background: #00ab6b !important;
    color: white !important;
  }
  .ant-picker-cell:hover:not(.ant-picker-cell-selected) .ant-picker-cell-inner {
    background: ${color.gainsboro} !important;
    margin: -1px;
  }

  /* Antd Dropdown */
  .ant-dropdown {
    li {
      height: 28px !important;
      line-height: 28px !important;
      padding: 0 10px !important;
    }
  }
  .ant-dropdown-menu {
    border: 1px solid #dadada;
    border-radius: 0;
    box-shadow: 0 0 10px ${`${color.downgray}${hexOpacity(35)}`};
    padding: 0px;
  }

  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    padding: 5px;

    p {
      color: ${color.black + hexOpacity(60)};
    }

    &:hover,
    &.ant-dropdown-menu-item-active {
      p {
        color: ${color.primary};
      }
    }

    &:first-child {
      border-radius: 3px 3px 0 0;
    }

    &:last-child {
      border-radius: 0 0 3px 3px;
    }
  }

  /* Antd Select */
  .ant-select-item {
    font-family: 'ProximaNovaRg';
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background: unset;
  }
  .ant-select-dropdown {
    box-shadow: none;
    border: 1px solid ${color.gainsboro};
    border-radius: 3px;

    div[aria-selected='true'] {
      font-family: var(--bold-font);
    }
  }

  /* Antd tag a */
  a:hover {
    color: ${color.primary};
  }

  /* Antd autocomplete */
  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-selector:hover,
  .ant-select-open .ant-select-selector {
    border-color: ${color.gainsboro} !important;
    box-shadow: none !important;
    outline: none !important;
  }

  /* Antd modal */
  .ant-modal-mask {
    background-color: rgba(18, 18, 18, 0.5);
  }

  /* Antd Message */
  .ant-message {
    z-index: 999999; // TOP OF LAYER
  }

  .ant-btn-primary[disabled] {
    background: #616161 !important;
    color: #cbcbcb;
    border: none;

    &:hover > p {
      color: #cbcbcb;
    }
  }

  body.disabled-popover-animation .ant-popover {
    animation-duration: 0s !important;
  }

  body.no-scroll {
    overflow: hidden;
    width: 100% !important;
  }
`;

const variables = css`
  :root {
    --font-regular: 'ProximaNovaRg', sans-serif;
    --font-bold: 'ProximaNovaBd', sans-serif;
  }

  @keyframes placeHolderShimmer {
    0% {
      background-position: -468px 0;
    }
    100% {
      background-position: 468px 0;
    }
  }
`;

const GlobalStyles = createGlobalStyle`
    ${overideAntdCSS}
    ${variables}

    :root {
      --regular-font: "ProximaNovaRg", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      --bold-font: "ProximaNovaBd", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      --light-font: "ProximaNovaLt", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }

    ::-webkit-scrollbar {
      width: 4px;
      height: 5px;
    }
    ::-webkit-scrollbar-button {
      width: 0px;
      height: 0px;
    }
    ::-webkit-scrollbar-thumb {
      background: ${color.gainsboro};
      border: 0px none ${color.aliceBlue};
      border-radius: 0;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: ${color.dimgray};
    }
    ::-webkit-scrollbar-thumb:active {
      background: ${color.dimgray};
    }
    ::-webkit-scrollbar-track {
      background: white;
      border: 0px none ${color.light};
      border-radius: 0;
    }
    ::-webkit-scrollbar-track:hover {
      background: ${color.gainsboro};
    }
    ::-webkit-scrollbar-track:active {
      background: ${color.gainsboro};
    }
    ::-webkit-scrollbar-corner {
      background: transparent;
    }

    .hide {
      display: none;
    }

    .stop-scrolling {
      height: 100%;
      overflow: hidden;
    }

    /* Hide crisp chat */
    #crisp-chatbox > div > a {
      display: none !important;

      &[data-visible="true"] {
        display: block !important;
      }
    }

    /* Hide google recaptcha */
    .grecaptcha-badge {
      opacity: 0;
      visibility: hidden;
    }

    select {
      appearance: none;
      cursor: pointer;
      padding-right : 20px;
      min-width : 48px;
      :focus {
        outline: 0;
      }
      background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjUxMnB4IiBoZWlnaHQ9IjUxMnB4IiB2aWV3Qm94PSIwIDAgMjU1IDI1NSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjU1IDI1NTsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8Zz4KCTxnIGlkPSJhcnJvdy1kcm9wLWRvd24iPgoJCTxwb2x5Z29uIHBvaW50cz0iMCw2My43NSAxMjcuNSwxOTEuMjUgMjU1LDYzLjc1ICAgIiBmaWxsPSIjNmI2YjZiIi8+Cgk8L2c+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==")
        calc(100% - 8px) center/8px auto no-repeat #fff !important;
    }

    .sysmsg-content {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0;

      & > div {
        background: transparent;
        padding: 0;
      }

      & > div > div > span {
        & > .alert-green,
        & > .alert-red,
        & > .alert-blue,
        & > .alert-orange {
          border-radius: 5px;
          padding: 10px 15px;
        }
      }

      & > div > div > span > .alert-green {
        background: #e1f6e5;
        color: ${color.black};
      }
      
      & > div > div > span > .alert-red {
        background: ${color.alertRed};
        color: ${color.black};
      }
      
      & > div > div > span > .alert-orange {
        background: ${color.alertOrange};
        color: ${color.black};
      }
      
      & > div > div > span > .alert-blue {
        background: ${color.alertBlue};
        color: ${color.black};
      }

      & > div > div > span > .snackbar-green {
        font-size: 12px;
        border-radius: 2px;
        padding: 13px 16px;
        font-family: var(--font-bold);
        background: #e1f6e5;

        span {
          margin: 0 12px;
        }
      }

      & > div > div > span > .plain-green {
        font-size: 14px;
        border-radius: 2px;
        padding: 8px 10px;
        font-family: var(--font-regular);
        background: ${color.primary};
        color: ${color.light};
        line-height: 16.1px;
      }
    }

    .modal-conversation {
      z-index: 50 !important;
      @media ${device.tablet} {
        z-index: 1000 !important;
      }
    }
    
    input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px #fff inset!important;
      box-shadow: 0 0 0 1000px #fff inset!important;
    }

    .panel-avatar {
      width: 30px;
      height: 30px;
      border: solid 1px #c0c0c0;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  
    // FLOATING CHAT NOTIFICATION
    .float-chat-notification {
      min-height: 76px;
      cursor: pointer;
      padding: 15px !important;
      font-family: ProximaNovaRg;
      
      :last-child {
        margin-bottom: 0;
      }
      
      & div > div > div {
        margin-left: 60px !important;
      }

      & .ant-notification-close-icon {
        font-size: 10px;
      }

      & .ant-notification-notice {
        margin-bottom: unset;
      }

      & .ant-notification-notice-message {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      & .ant-notification-notice-description {
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      & .ant-notification-notice-close {
        top: 10px;
      }
    }

    // FLOATIN ALERT NOTIFICATION
    .float-alert-notification {
      padding: 0;
      border: 1px solid #dadada;

      :last-child {
        margin-bottom: 0;
      }

      .ant-notification-notice-icon {
        left: 0;
        top: 0;
        margin-left: 0;
        height: 100%;
        
        img {
          width: 100%;
          height: 100%
        }
      }

      & .ant-notification-notice-message {
        font-size: 14px;
        font-weight: 700;
        font-family: var(--font-bold);
        margin-bottom: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-top: 15px;
        margin-left: 85px;
      }

      & .ant-notification-notice-description {
        padding-bottom: 15px;
        margin-left: 85px;
        line-height: normal;
      }

      & .ant-notification-notice-close {
        top: 16px;
        right: 16px;
        font-size: 12px;
      }
    }

    // FLOATIN GENERAL NOTIFICATION
    .float-general-notification {
      cursor: pointer;
      padding: 15px !important;
      font-family: ProximaNovaRg;
      
      :last-child {
        margin-bottom: 0;
      }
      
      & div > div > div {
        margin-left: 60px !important;
      }

      & .ant-notification-close-icon {
        font-size: 10px;
      }

      & .ant-notification-notice {
        margin-bottom: unset;
      }

      & .ant-notification-notice-message {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      & .ant-notification-notice-description {
        font-size: 14px;
        overflow: hidden;
      }

      & .ant-notification-notice-close {
        top: 10px;
      }
    }

    .reverse {
      transform: rotate(180deg);
    }

    .button-unstyled {
      background: transparent;
      border: none;
      cursor: pointer;

      &.full-button {
        display: block;
        width: 100%;
      }
    }

    /* FUNDACHART */
    .fundachart-custom-tooltip {
      height: 16px;
      border: 1px solid #dadada;
      position: absolute;
      font-size: 11px;
      display: flex;
      align-items: center;
      padding: 1px 5px 0 5px;
      background-color: #FFF;
    }

    .fundachart-custom-tooltip:before {
      content: ' ';
      right: 100%;
      top: 50%;
      height: 0;
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border: solid transparent;
      border-color: rgba(218, 218, 218, 0);
      border-right-color: #dadada;
      border-width: 9px;
      margin-top: -9px;
    }

    .fundachart-custom-tooltip:after {
      content: ' ';
      right: 100%;
      top: 50%;
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border: solid transparent;
      border-color: rgba(255, 255, 255, 0);
      border-right-color: #fff;
      border-width: 8px;
      margin-top: -8px;
    }

    .orderbook-text-cell {
      color: #333333;
      font-family: ProximaNovaRg;
      font-size: 12px;
      font-variant: normal;
      -webkit-font-feature-settings: normal;
      font-feature-settings: normal;
      line-height: 16px;
      text-align: right;
      padding: 8px 0px;

      &.up {
        color: #00ab6b;
      }

      &.down {
        color: #ee4a49;
      }

      &.notchange {
        color: #979134;
      }

      &.frequency {
        color: #8277F8;
      }

      &.lot {
        font-size: 11px;
      }

      &.title {
        font-family: ProximaNovaBd;
        -webkit-font-smoothing: antialiased;
        font-smoothing: antialiased;
        line-height: 16px;
        padding: 8px 0px;
      }
    }

    .orderbook-text-summary {
      color: #333333;
      font-family: ProximaNovaBd;
      font-size: 12px;
      font-variant: normal;
      -webkit-font-feature-settings: normal;
      font-feature-settings: normal;
      -webkit-font-smoothing: antialiased;
      font-smoothing: antialiased;
      line-height: 16px;
      text-align: center;
      padding: 8px 0px;

      &.center {
        text-align: center;
      }

      &.lot {
        font-size: 11px;
      }
    }

    .orderbook-que-bid,
    .orderbook-que-off {
      position: relative;
    }

    .table-live-price {
      font-size: 12px;
      font-family: ProximaNovaRg;
      font-variant: normal;
      -webkit-font-feature-settings: normal;
      font-feature-settings: normal;
      line-height: 16px;
      margin: 12px 8px;
      table-layout: fixed;
      width: calc(100% - calc(2 * 8px));

      td {
        padding: 4px 0px;
        text-align: right;
        white-space: nowrap;
        color: #333333;

        &.up {
          color: #00ab6b;
        }
  
        &.down {
          color: #ee4a49;
        }
  
        &.notchange {
          color: #979134;
        }
      }

      td:first-child {
        padding-left: 0px;
        text-align: left;
      }

      td:nth-child(3) {
        text-align: left;
      }

      td:nth-child(5) {
        text-align: left;
      }

      td:last-child {
        padding-right: 0px;
      }

      tr:first-child {
        td {
          padding-top: 0px;
        }
      }

      tr:last-child {
        td {
          padding-bottom: 0px;
        }
      }
    }

    .orderbook-last-price {
      font-size: 12.5px;
      font-family: ProximaNovaRg;
      font-variant: normal;
      -webkit-font-feature-settings: normal;
      font-feature-settings: normal;
      line-height: 21px;

      &.up {
        color: #00ab6b;
      }

      &.down {
        color: #ee4a49;
      }

      &.notchange {
        color: #979134;
      }
    }

    ${alertStyles}

    ${tooltipStyles}

    ${layoutPageContainerStyles}

    .intercom-unread-count {
      backface-visibility: hidden;
      background: #fc576b;
      border-radius: 10px;
      box-shadow: none;
      box-sizing: border-box;
      color: #fff;
      font-size: 12px;
      height: 20px;
      line-height: 20px;
      min-width: 20px;
      opacity: 0;
      padding: 0 4px;
      position: absolute;
      left: -5px;
      text-align: center;
      top: 5px;
      transform: scale(0.5);
      transform-origin: center;
      transition: opacity 0.15s ease, transform 0.15s ease;
      font-family: sans-serif;

      @media screen and (max-width: 720px) {
          left: 3px;
      }
      
      &.active {
        opacity: 1;
        transform: scale(1);
      }
    }

    .price-cell {
      width: max-content;
      
      &.min {
        min-width: 135px;
      }

      &.mid {
        min-width: 156px;
      }

      &.full {
        min-width: max-content;
      }
    }

    /* Modal Multi Portfolio */
    .modal-multi-porto {
      max-width: 410px;

      &.withdraw {
        max-width: 450px;
      }
    }

    .modal-multi-porto.no-padding .ant-modal-body {
      padding: 0;
    }

    .modal-multi-porto .ant-modal-body > h4 {
      text-align: left;
      padding-left: 20px;
      font-size: 16px;
    }

    .modal-multi-porto .ant-btn-primary[disabled] {
      background-color: #cceee1 !important;
      color: #ffffff !important;
      border: none;

      &:hover > p {
        color: #ffffff;
      }
    }

    /* Override Antd Select Modal Multi Porto */
    .modal-multi-porto-open {
      .ant-select-dropdown {
        padding: 0;

        .ant-select-item-option {
          min-height: 50px;
          padding: 0 12px;
        }

        .ant-select-item-option:not(:last-child) {
          // border-bottom: 1px solid red;
        }

        .ant-select-item-option:hover {
          background-color: #F7F7F7;
        }
      }

      .ant-select-dropdown div[aria-selected='true'] {
        img.transfer-check-icon {
          display: block;
        }

        .option-text span.main-text {
          font-weight: 400 !important;
          font-family: ProximaNovaRg !important;
        }
      }

      .ant-select-item-option {
        div.option-text .main-porto {
          display: flex;
        }
      }

      .ant-select-item-option-content {
        border-top: 1px solid #EDEDED;
      }

      .ant-select-item-option:first-child .ant-select-item-option-content {
        border-top: none;
      }

      .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
        background-color: #ffffff;
      }

      .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background-color: #ffffff;
        cursor: default;
      }

      .ant-select-disabled .ant-select-arrow {
        display: none;
      }
    }
    /* End Modal Multi Portfolio */
  `;

export default GlobalStyles;
