import { combineReducers } from 'redux';

// import reducers -------------------------------------------------------------
// feature specific reducers
import discoverPeopleReducer from 'features/discover-people/reducer';
import editProfileReducer from 'features/edit-profile/reducer';
import onboardingReducer from 'features/onboarding/reducer';
import registerReducer from 'features/register/reducer';
import resetPasswordReducer from 'features/reset-password/reducer';
import userProfileReducer from 'features/user/reducer';
import watchlistReducer from 'features/watchlist/reducer';
import calendarFeatureSlice from 'features/calendar/slice';
import streamFeatureSlice from 'features/stream/slice';
import companyProfileReducer from 'features/company/reducer';
import catalogReducer from 'features/catalog/reducer';
import postDetailReducer from 'features/post-detail/reducer';
import chartbitReducer from 'features/chartbit/reducer';
import screenerFeatureSlice from 'features/screener/slice';
import researchSlice from 'features/research/slice';
import earningsFeaturesSlice from 'features/earnings/slice';
import orderbookModalSlice from 'global/TradingModal/slice';

// globally used component reducers
import moderationReducer from 'global/Moderation/reducer';
import mainLayoutReducer from 'global/MainLayout/reducer';
import streamWidgetSlice from 'global/StreamWidget/slice';
import streamConversation from 'global/StreamConversation/slice';
import suggestedPeopleReducer from 'global/SuggestedPeopleWidget/reducer';
import trendingPeopleReducer from 'global/TrendingPeopleWidget/reducer';
import websocketReducer from 'global/WebSocket/reducer';
import websocketV2Reducer from 'global/WebSocketV2/reducer';
import searchSlice from 'global/Header/Search/slice';
import notificationSlice from 'lib/entities/notification/slice';
import valuationSlice from 'features/valuation/slice';
import searchCompanySlice from 'global/TradingModal/components/SearchCompany/slice';
import liveStreamSlice from './entities/livestream';

// entities reducers
import entitiesReducer from './entities';

// combile each reducer
const rootReducer = combineReducers({
  // features
  discoverPeople: discoverPeopleReducer,
  editProfile: editProfileReducer,
  onboarding: onboardingReducer,
  register: registerReducer,
  resetPassword: resetPasswordReducer,
  userProfile: userProfileReducer,
  watchlist: watchlistReducer,
  companyProfile: companyProfileReducer,
  catalog: catalogReducer,
  postDetail: postDetailReducer,
  mainLayout: mainLayoutReducer,
  moderation: moderationReducer,
  trendingPeople: trendingPeopleReducer,
  suggestedPeople: suggestedPeopleReducer,
  research: researchSlice.reducer,

  calendar: calendarFeatureSlice.reducer,
  earnings: earningsFeaturesSlice.reducer,
  websocket: websocketReducer,
  websocketV2: websocketV2Reducer,
  chartbit: chartbitReducer,
  orderbookModal: orderbookModalSlice.reducer,
  valuation: valuationSlice.reducer,
  screener: screenerFeatureSlice.reducer,
  liveStream: liveStreamSlice.reducer,
  searchCompany: searchCompanySlice.reducer,

  // globally used components
  stream: streamFeatureSlice.reducer,
  search: searchSlice.reducer,
  notification: notificationSlice.reducer,
  streamConversation: streamConversation.reducer,
  streamWidget: streamWidgetSlice.reducer,

  // entities
  entities: entitiesReducer,
});

export default rootReducer;

export type RootReducerState = ReturnType<typeof rootReducer>;
