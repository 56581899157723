/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable camelcase */
import {
  ActionReducerMapBuilder,
  AsyncThunk,
  CaseReducer,
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import SUCCESS_MESSAGE from 'constants/successMessage';
import watchlist from 'lib/api/watchlist';
import { emptyObject } from 'utils/general';
import number from 'utils/number';
import alertMessage from 'global/AlertMessage';
import { CustomDataSource } from '../../../../../@types/watchlist';

// utils
import rootSelector, { tableSelector } from '../../selectors';

// local types
import {
  CompanyDataSource,
  CustomColumn,
  CustomHeader,
  TableState,
} from '../types';

// Utils
import utils from './utils';

// constants
const SLICE_NAME = 'table';

// initial state
const initialState: TableState = {
  error: null,
  isLoading: false,
  columns: [],
  fitems: {},
  headers: [],
  symbols: {},
};

// Reducer
type SliceReducers = {
  addColumn: CaseReducer<
    TableState,
    PayloadAction<{ column: CustomColumn; results: any }>
  >;
  updateColumns: CaseReducer<
    TableState,
    PayloadAction<{ columns: CustomColumn[] }>
  >;
};

const reducers: SliceReducers = {
  addColumn: (draft, action) => {
    draft.columns.push(action.payload.column);
  },
  updateColumns: (draft, action) => {
    draft.columns = action.payload.columns;
  },
};

// thunk actions
type Thunks = {
  fetchCompanyWatchlist: AsyncThunk<
    {
      symbols: any;
      customColumn: CustomColumn[];
    }, // return types
    { watchlistid: string }, // payload
    {} // thunk api
  >;
  fetchFinancialItemValues: AsyncThunk<
    { datasources: CustomDataSource[] },
    { watchlistid: string; fitemids: string[] },
    {}
  >;
  removeColumn: AsyncThunk<
    { fitemid: string },
    { watchlistid: string; fitemid: string },
    {}
  >;
  reorderColumn: AsyncThunk<
    { column: Array<CustomColumn> },
    { column: Array<CustomColumn>; watchlistid: string },
    {}
  >;
};

export const thunks: Thunks = {
  fetchCompanyWatchlist: createAsyncThunk(
    `${SLICE_NAME}/FETCH_COMPANY_WATCHLIST`,
    async ({ watchlistid }) => {
      const params = {
        setfincol: 1, // params to show custom header
      };

      const response = await watchlist.getDetailWatchlist(watchlistid, params);

      const { data: body } = response;
      if (body.error) {
        throw new Error(body.error);
      }

      const { header_custom: headerCustom, result } = body.data;

      const customHeader: CustomHeader[] = utils.mapCustomHeader(headerCustom);

      const customColumn: CustomColumn[] = customHeader.map(
        utils.mapCustomColumnFromHeader,
      );

      const companyDataSource: CompanyDataSource[] = result.map(
        utils.mapCompanyDataSource,
      );

      const customHeaderObject = customHeader.reduce(
        utils.customHeaderObjectReducer,
        {},
      );

      const symbols = utils.generateSymbols(
        companyDataSource,
        customHeaderObject,
      );

      const datasource = Object.keys(symbols).map((key) => symbols[key]);

      return {
        datasource,
        symbols,
        customColumn,
      };
    },
  ),

  fetchFinancialItemValues: createAsyncThunk(
    `${SLICE_NAME}/FETCH_FINANCIAL_ITEM_VALUES`,
    async ({ watchlistid, fitemids }) => {
      const datasourceResponse = await Promise.all(
        fitemids.map((id) => watchlist.getFinancialValues(watchlistid, id)),
      );

      const datasources = datasourceResponse.map((response) => {
        const { data, error } = response.data;
        if (error) {
          throw new Error(error);
        }
        return data;
      });

      return {
        datasources,
      };
    },
  ),

  removeColumn: createAsyncThunk(
    `${SLICE_NAME}/REMOVE_FINANCIAL_COLUMN`,
    async ({ watchlistid, fitemid }) => {
      const response = await watchlist.removeFinancialMetrics(
        watchlistid,
        fitemid,
      );

      const { error } = response.data;

      if (error) {
        throw new Error(error);
      }

      alertMessage({
        content: SUCCESS_MESSAGE.FINANCIAL_COLUMN_DELETED,
        alertType: 'plain',
        messageType: 'success',
      });

      return { fitemid };
    },
  ),

  reorderColumn: createAsyncThunk(
    `${SLICE_NAME}/REORDER_FINANCIAL_COLUMN`,
    async ({ column, watchlistid }) => {
      const columnIndex = column.map((col) => col.dataIndex).join();

      const response = await watchlist.reorderFinancialValues(
        watchlistid,
        columnIndex,
      );

      if (!response.data) {
        throw new Error('Attempt to reorder financial column failed');
      }

      const { error_type: error } = response.data;

      if (error) {
        throw new Error(error);
      }

      return { column };
    },
  ),
};

// Thunk reducers
const extraReducers = (builder: ActionReducerMapBuilder<TableState>) => {
  builder
    // fetch company watchlist
    .addCase(thunks.fetchCompanyWatchlist.pending, (draft) => {
      draft.isLoading = true;
      // Reset symbols state
      // draft.symbols = {};
    })
    .addCase(thunks.fetchCompanyWatchlist.fulfilled, (draft, action) => {
      const { customColumn, symbols } = action.payload;
      draft.isLoading = false;
      draft.symbols = symbols;
      draft.columns = customColumn;
    })
    .addCase(thunks.fetchCompanyWatchlist.rejected, (draft, action) => {
      draft.isLoading = false;
      draft.error = action.error;
    })
    // fetch company watchlist
    .addCase(thunks.fetchFinancialItemValues.pending, (draft) => {
      draft.isLoading = true;
    })
    .addCase(thunks.fetchFinancialItemValues.fulfilled, (draft, action) => {
      const { datasources } = action.payload;

      datasources.forEach(({ item_id, results }) => {
        results.forEach((result) => {
          const { symbol, value } = result;
          draft.symbols[symbol] = {
            ...draft.symbols[symbol],
            [item_id]: number.numberFormatter({
              num: value,
              fixed: 2,
            }),
          };
        });
      });

      draft.isLoading = false;
    })
    .addCase(thunks.fetchFinancialItemValues.rejected, (draft, action) => {
      draft.isLoading = false;
      draft.error = action.error;
    })
    .addCase(thunks.removeColumn.fulfilled, (draft, action) => {
      draft.columns = draft.columns.filter(
        ({ key }) => key !== action.payload.fitemid,
      );
    })
    .addCase(thunks.reorderColumn.pending, (draft) => {
      draft.isLoading = true;
    })
    .addCase(thunks.reorderColumn.fulfilled, (draft, action) => {
      const { column } = action.payload;
      draft.columns = column;
    })
    .addCase(thunks.reorderColumn.rejected, (draft, action) => {
      // do something if error
      draft.isLoading = false;
      draft.error = action.error;
    });
};

// selectors
export const selectors = createSelector(rootSelector, (state) => {
  const { table } = state.watchlistTable;

  const datasource = Object.keys(table.symbols).map(
    (key) => table.symbols[key],
  );

  return {
    ...table,
    datasource,
    activeWatchlistid: state.root.activeWatchlistId,
  };
});

export const loadingTableSelector = createSelector(
  (state): TableState['isLoading'] =>
    state.watchlist.watchlistTable.table.isLoading,
  (state) => state,
);

export const columnSelector = createSelector(
  (state): TableState['columns'] =>
    state.watchlist.watchlistTable.table.columns,
  (state) => state,
);

export const dataSourceSelector = createSelector(tableSelector, (state) => {
  const datasource = Object.keys(state.symbols).map(
    (key) => state.symbols[key],
  );

  return datasource;
});

const tableSlice = createSlice({
  initialState,
  name: SLICE_NAME,
  reducers,
  extraReducers,
});

export default tableSlice;
