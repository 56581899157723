import {
  ActionReducerMapBuilder,
  AsyncThunk,
  CaseReducer,
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import watchlist from 'lib/api/watchlist';

// other slice
import alertMessage from 'global/AlertMessage';
import { thunks as tableThunks } from '../Table/slice';

// utils
import rootSelector from '../../selectors';

// local types
import { DeleteCompanyModalState } from '../types';

// constants
const SLICE_NAME = 'deleteCompanyModal';

// initial state
const initialState: DeleteCompanyModalState = {
  error: null,
  isLoading: false,
  isModalVisible: false,
  selected: undefined,
};

// Reducer
type SliceReducers = {
  handleDeleteCompanyClick: CaseReducer<
    DeleteCompanyModalState,
    PayloadAction<{ id: string; symbol: string; name: string }>
  >;
  handleModalCancel: CaseReducer<DeleteCompanyModalState>;
};

const reducers: SliceReducers = {
  handleDeleteCompanyClick: (draft, action) => {
    draft.isModalVisible = true;
    draft.selected = action.payload;
  },
  handleModalCancel: (draft) => {
    draft.isModalVisible = false;
    draft.selected = null;
  },
};

// thunk actions
type Thunks = {
  handleConfirmRemoveCompany: AsyncThunk<
    void,
    { companyid: string; watchlistid: string },
    // eslint-disable-next-line @typescript-eslint/ban-types
    {}
  >;
};

export const thunks: Thunks = {
  handleConfirmRemoveCompany: createAsyncThunk(
    `${SLICE_NAME}/CONFIRM_REMOVE_COMPANY`,
    async ({ companyid, watchlistid }, { dispatch }) => {
      const response = await watchlist.postUnfollowCompany(
        watchlistid,
        companyid,
      );

      const { error } = response.data;
      if (error) {
        throw new Error(error);
      }

      alertMessage({
        content: 'Stock is removed',
        messageType: 'success',
        alertType: 'plain',
      });

      dispatch(tableThunks.fetchCompanyWatchlist({ watchlistid }));
    },
  ),
};

// Thunk reducers
const extraReducers = (
  builder: ActionReducerMapBuilder<DeleteCompanyModalState>,
) => {
  builder
    // delete
    .addCase(thunks.handleConfirmRemoveCompany.pending, (draft) => {
      draft.isLoading = true;
    })
    .addCase(thunks.handleConfirmRemoveCompany.fulfilled, (draft) => {
      draft.isLoading = false;
      draft.isModalVisible = false;
      draft.selected = null;
    })
    .addCase(thunks.handleConfirmRemoveCompany.rejected, (draft, action) => {
      draft.isLoading = false;
      draft.isModalVisible = false;
      draft.selected = null;
      draft.error = action.error;
    });
};

// selectors
export const selectors = createSelector(rootSelector, (state) => ({
  ...state.watchlistTable.deleteModal,
  activeWatchlistid: state.root.activeWatchlistId,
}));

const deleteCompanyModalSlice = createSlice({
  initialState,
  name: SLICE_NAME,
  reducers,
  extraReducers,
});

export default deleteCompanyModalSlice;
