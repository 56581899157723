import produce from 'immer';
import create, { StateCreator } from 'zustand';

import type { State, Actions } from './securities.store.types';

export const securitiesState: State = {
  channel: {},
  isReconnectionFailed: false,
  isSocketOnline: false,
};

export const securitiesActions: StateCreator<State & Actions, [], [], Actions> =
  (set) => ({
    setChannel: (key, value) => {
      set((state) =>
        produce(state, (draft) => {
          draft.channel = {
            ...draft.channel,
            [key]: value,
          };
        }),
      );
    },
    setIsReconnectionFailed: (value) => {
      set((state) =>
        produce(state, (draft) => {
          draft.isReconnectionFailed = value;
        }),
      );
    },
    setIsSocketOnline: (isSocketOnline) => {
      set({ isSocketOnline });
    },
    resetChannel: () => {
      set((state) =>
        produce(state, (draft) => {
          draft.channel = {};
        }),
      );
    },
  });

export type SecuritiesStore = Actions & State;

export const useSecuritiesStore = create<SecuritiesStore>(
  (set, get, store, storeMutation) => ({
    ...securitiesState,
    ...securitiesActions(set, get, store, storeMutation),
  }),
);
