import type { SUBSCRIPTION } from '../../../@types/shop';

export enum PAYMENT_METHOD {
  PAYMENT_METHOD_GOPAY = 'PAYMENT_METHOD_GOPAY',
  PAYMENT_METHOD_BCA_VA = 'PAYMENT_METHOD_BCA_VA',
  PAYMENT_METHOD_CREDIT_CARD = 'PAYMENT_METHOD_CREDIT_CARD',
  PAYMENT_METHOD_BANK_TRANSFER = 'PAYMENT_METHOD_BANK_TRANSFER',
  PAYMENT_METHOD_CIMB_CLICKS = 'PAYMENT_METHOD_CIMB_CLICKS',
  PAYMENT_METHOD_BRI_EPAY = 'PAYMENT_METHOD_BRI_EPAY',
  PAYMENT_METHOD_MIDTRANS = 'PAYMENT_METHOD_MIDTRANS',
}

export const PAYMENT_OPTION = [
  {
    id: 0,
    name: 'Gopay',
    code: PAYMENT_METHOD.PAYMENT_METHOD_GOPAY,
    description: 'gopay',
    icon: 'gopay_round',
  },
  {
    id: 1,
    name: 'BCA Virtual Account',
    code: PAYMENT_METHOD.PAYMENT_METHOD_BCA_VA,
    description: 'bca_va',
    icon: 'bca_va',
  },
  {
    id: 2,
    name: 'Credit Card / Transfer ATM / Mandiri Clickpay',
    code: PAYMENT_METHOD.PAYMENT_METHOD_MIDTRANS,
    description: 'veritrans',
    icon: 'veritrans',
  },
];

export type SUBSCRIPTION_ALIASES_TYPE = {
  [Property in keyof typeof SUBSCRIPTION]: string;
};

export const SUBSCRIPTION_ALIAS: SUBSCRIPTION_ALIASES_TYPE = {
  SUBSCRIPTION_ONE_YEAR: '1 Tahun Berlangganan',
  SUBSCRIPTION_SIX_MONTHS: '6 Bulan Berlangganan',
  SUBSCRIPTION_ONE_MONTH: '1 Bulan Berlangganan',
  SUBSCRIPTION_ONE_DAY: '1 Hari Berlangganan',
};

export const SUBSCRIPTION_PACKAGE: SUBSCRIPTION_ALIASES_TYPE = {
  SUBSCRIPTION_ONE_YEAR: '1 Tahun Stockbit Pro',
  SUBSCRIPTION_SIX_MONTHS: '6 Bulan Stockbit Pro',
  SUBSCRIPTION_ONE_MONTH: '1 Bulan Stockbit Pro',
  SUBSCRIPTION_ONE_DAY: '1 Hari Stockbit Pro',
};

export enum OA_REGISTER_STATUS {
  HAS_NOT_STARTED = 0,
  INCOMPLETE = 1,
  CS_PROGRESS = 2,
  KSEI_PROGRESS = 3,
  RDN_PROGRESS = 4,
  SUBMIT_REGISTRATION = 5,
  DOCUMENT_VERIFICATION = 88,
  REJECTED = 99,
  OTHERS = -1,
}
