/* eslint-disable import/named */
/* eslint-disable no-undef */
import requestExodusConfig from 'config/requestExodus';
import exodusInstance from 'lib/api/instances/exodus';
import { API_EXODUS_URL } from 'constants/api';
import {
  IOrderbookListEdit,
  IOrderbookListDelete,
  IOrderbookListItemUpdate,
  IDataOrderbookList,
  IDataOrderbookListItemV2,
  IItemOrderbookListItemV2,
} from 'features/orderbook/types';
import { APIResponse } from '../../../@types/api-response';

type OrderbookResponse<DataToken> = Promise<APIResponse<DataToken>>;

const requestExodus = exodusInstance.defaultInstance;

// APIs
const ORDERBOOK_API = `${API_EXODUS_URL}/orderbook`;

// EXODUS APIs
const EXODUS_ORDERBOOK_API = `${API_EXODUS_URL}/orderbook`;

// Should be replaced with get company orderbook data but the exodus endpoint
// doesn't support special symbols e.g. IHSG
const getOrderbookPreviewBySymbol = (
  symbol: string,
): OrderbookResponse<IItemOrderbookListItemV2> =>
  requestExodus.get(`${API_EXODUS_URL}/company-price-feed/v2/orderbook/companies/${symbol}`);

const getOrderbookList = (): OrderbookResponse<IDataOrderbookList[]> =>
  requestExodus.get(EXODUS_ORDERBOOK_API);

const createOrderbookList = ({
  orderbookName,
}: {
  orderbookName: string;
}): OrderbookResponse<IDataOrderbookList> =>
  requestExodus.post(EXODUS_ORDERBOOK_API, {
    name: orderbookName,
  });

const editOrderbookList = ({
  id,
  orderbookName,
  symbols,
}: {
  id: string;
  orderbookName: string;
  symbols?: string;
}): OrderbookResponse<IOrderbookListEdit | IOrderbookListItemUpdate> => {
  const params: Record<string, any> = {
    name: orderbookName,
  };

  if (symbols) params.symbols = symbols;
  return requestExodus.put(`${EXODUS_ORDERBOOK_API}/${id}`, params);
};

const deleteOrderbookList = ({
  id,
}: {
  id: string;
}): OrderbookResponse<IOrderbookListDelete> =>
  requestExodus.delete(`${EXODUS_ORDERBOOK_API}/${id}`);

const getOrderbookListItem = (
  id?: string,
): OrderbookResponse<IDataOrderbookListItemV2> => {
  if (id) {
    return requestExodus.get(`/company-price-feed/v2/orderbook/template/${id}`);
  }
  // Get default orderbook if ID is not passed
  // Harusnya nantinya ada lebih lanjut terkait ini sih, gimana kalo enggak ada ID nya
  return requestExodus.get(ORDERBOOK_API);
};

const getCompanyInfo = (symbol) => {
  const reqDetail = requestExodusConfig(false);
  return reqDetail.get(`${API_EXODUS_URL}/emitten/${symbol}/info`);
};

export default {
  getOrderbookPreviewBySymbol,
  getOrderbookList,
  createOrderbookList,
  editOrderbookList,
  deleteOrderbookList,
  getOrderbookListItem,
  getCompanyInfo,
};
